import { Box,  Grid, Button, Paper, InputBase, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import DummyTable from "components/Tables/DummyTable";
import Chip from "@mui/material/Chip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FormDialog from "components/modal";
import moment from "moment";
import PaymentTypesForm from "../forms/paymentTypesForm";
import requester from "api/pesabackend";
import { setRefreshCount } from "state";

import { useGetPaymentTypesList } from "api/hooks/billing";


export default function PaymentTypesTable () {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const business_branch_id = useSelector((state) => state.business_branch_id);

  const {paymentTypesList, isLoadingPaymentTypesList, errorPaymentTypesList} = useGetPaymentTypesList(1, business_branch_id)

  const categories = [
    {
        "name": "CASH",
        "id": 1
    },
    {
        "name": "MOBILE MONEY",
        "id": 2
    }
  ]

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [category, setCategory] = useState("")
  const [isSubmittingPaymentType, setIsSubmittingPaymentType] = useState(false)
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const[searchContent, setSearchContent] = useState("")

  const handleChangeName = (e) => {
    setName(e.target.value)
  }

  const handleChangeDescription = (e) => {
    setDescription(e.target.value)
  }

  const handleChangeCategory = (e) => {
    setCategory(e.target.value)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setModal("");
    setOpen(false);
  };

  const savePaymentType = async () => {
    setIsSubmittingPaymentType(true)
    const submitData = {
      "related_business": 1,
      "related_business_branch": business_branch_id,
      "payment_type_name": name,
      "payment_type_category": category,
      "description": description,
    }
    try {
      const response = await requester.post("/billing/payment_types", submitData)
      setIsSubmittingPaymentType(false)
      dispatch(setRefreshCount())
      setOpen(false)
    }catch (err){
        setIsSubmittingPaymentType(false)
    }
  }

  const renderStatusPills = (status) => {
		switch (status) {
			case false:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="INACTIVE" size="medium" color="warning" />;
			case true:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="ACTIVE" size="medium" color="primary" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffeded", fontSize:"10px", fontWeight:700 }} label="CANCELLED" size="medium" color="danger" />;
		}
	};

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };


  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
    <Box>
      <h3>Payment Types</h3>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <InputBase
            placeholder="Search by Account Name"
            value={searchContent}
            onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
          />
          {/* <IconButton onClick={handleClickSearch}>
            <SearchIcon />
          </IconButton> */}

        <Button
          variant="contained"
          onClick={handleClickOpen}
        >
          CREATE PAYMENT TYPE
        </Button>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
        {isLoadingPaymentTypesList ? (
            <DummyTable />
        ) : (
          <>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 100 }}>Payment Type Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 100 }}>Description</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Created By</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Status</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {paymentTypesList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </TableCell>
                  </TableRow>
                ) : (
                    paymentTypesList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{row.payment_type_name}</StyledTableCell>
                          <StyledTableCell>{row.description}</StyledTableCell>
                          <StyledTableCell>{`${row.created_by.first_name} ${row.created_by.last_name}`}</StyledTableCell>
                          <StyledTableCell>{renderStatusPills(row.is_active)}</StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 100 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => navigate("/ledgerTransactions", { state: { id: row.id, name: row.payment_type_name }})}
                            >
                              View Details
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={paymentTypesList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </>
        )}

        <FormDialog
            open={open}
            handleClose={handleClose}
            enableActions={true}
            title={"Add New Payment Type"}
            buttonText="Create Payment Type" 
            handleSubmit={savePaymentType}
            isSubmitting={isSubmittingPaymentType}
            isSubmittingText={"Creating Payment Type, Please wait..."}
            textStatus={true}
            text={`Please note that this action is going to create a new ledger called ${name} under Cash and Bank`}
            content={
              <PaymentTypesForm
                handleChangeName={handleChangeName}
                handleChangeDescription={handleChangeDescription}
                handleChangeCategory={handleChangeCategory}
                category={category}
                categories={categories}
              />
            }
          />
        </Paper>
      </Box>
    </Box>
  );
};