import React, {useEffect, useState} from "react";
import requester from "api/pesabackend"; 

const resource_tag = 'accounting';

const useChartOfAccountsList = (pageNumber, business_branch_id) => {
    const [chartList, setChartList] = useState([]);
	const [isLoadingChartList, setLoadingChartList] = useState(true);
	const [errorChartList, setErrorChartList] = useState(false);

	const url = `/${resource_tag}/chart_of_accounts?business_branch=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setChartList(response.data.data);
				setLoadingChartList(false);
				setErrorChartList(false);
			} catch (e) {
				setErrorChartList(true);
				setLoadingChartList(false);
			}
		};
		fetchData();
	}, [pageNumber]);
	return { chartList, isLoadingChartList, errorChartList };
};

const useChartOfAccountsNextAccountsList = (pageNumber, parent_id) => {
    const [chartNextList, setChartNextList] = useState([]);
	const [isLoadingChartNextList, setLoadingChartNextList] = useState(true);
	const [errorChartNextList, setErrorChartNextList] = useState(false);

	const url = `/${resource_tag}/chart_of_accounts/next_level?parent_id=${parent_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setChartNextList(response.data.data);
				setLoadingChartNextList(false);
				setErrorChartNextList(false);
			} catch (e) {
				setErrorChartNextList(true);
				setLoadingChartNextList(false);
			}
		};
		fetchData();
	}, [pageNumber]);
	return { chartNextList, isLoadingChartNextList, errorChartNextList };
};

const useGetProfitAndLoss = (business_branch_id) => {
    const [profitReport, setProfitReport] = useState(false);
	const [isLoadingProfitReport, setLoadingProfitReport] = useState(true);
	const [errorProfitReport, setErrorProfitReport] = useState(false);

	const url = `/${resource_tag}/chart/pl?business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setProfitReport(response.data.data);
				setLoadingProfitReport(false);
				setErrorProfitReport(false);
			} catch (e) {
				setErrorProfitReport(true);
				setLoadingProfitReport(false);
			}
		};
		fetchData();
	}, []);
	return { profitReport, isLoadingProfitReport, errorProfitReport };
};

const useGetBalanceMonths = () => {
    const [balanceMonths, setBalanceMonths] = useState([]);
	const [isLoadingBalanceMonths, setLoadingBalanceMonths] = useState(true);
	const [errorBalanceMonths, setErrorBalanceMonths] = useState(false);

	const url = `/${resource_tag}/chart/bs/months`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setBalanceMonths(response.data.data);
				setLoadingBalanceMonths(false);
				setErrorBalanceMonths(false);
			} catch (e) {
				setErrorBalanceMonths(true);
				setLoadingBalanceMonths(false);
			}
		};
		fetchData();
	}, []);
	return { balanceMonths, isLoadingBalanceMonths, errorBalanceMonths };
};

const useGetBalanceSheet = (business_branch_id) => {
    const [balanceSheet, setBalanceSheet] = useState(false);
	const [isLoadingBalanceSheet, setLoadingBalanceSheet] = useState(true);
	const [errorBalanceSheet, setErrorBalanceSheet] = useState(false);

	const url = `/${resource_tag}/chart/bs?business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setBalanceSheet(response.data.data);
				setLoadingBalanceSheet(false);
				setErrorBalanceSheet(false);
			} catch (e) {
				setErrorBalanceSheet(true);
				setLoadingBalanceSheet(false);
			}
		};
		fetchData();
	}, []);
	return { balanceSheet, isLoadingBalanceSheet, errorBalanceSheet };
};


const useGetLedgerTransactions = (business_branch_id, ledger_id) => {
    const [ledgerTransactions, setLedgerTransactions] = useState(false);
	const [isLoadingLedgerTransactions, setLoadingLedgerTransactions] = useState(true);
	const [errorLedgerTransactions, setErrorLedgerTransactions] = useState(false);

	const url = `/${resource_tag}/chart/ledgers/transactions?business_branch_id=${business_branch_id}&ledger_id=${ledger_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setLedgerTransactions(response.data.data);
				setLoadingLedgerTransactions(false);
				setErrorLedgerTransactions(false);
			} catch (e) {
				setErrorLedgerTransactions(true);
				setLoadingLedgerTransactions(false);
			}
		};
		fetchData();
	}, []);
	return { ledgerTransactions, isLoadingLedgerTransactions, errorLedgerTransactions };
};


const useGetExpenseTypesList = (pageNumber, business_branch_id) => {
    const [expenseTypesList, setExpenseTypesList] = useState([]);
	const [isLoadingExpenseTypesList, setLoadingExpenseTypesList] = useState(true);
	const [errorExpenseTypesList, setErrorExpenseTypesList] = useState(false);
	const [newExpenseType, setNewExpenseType] = useState(true);

	const url = `/${resource_tag}/expense_types?business_branch=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setExpenseTypesList(response.data.data);
				setLoadingExpenseTypesList(false);
				setErrorExpenseTypesList(false);
				setNewExpenseType(false);
			} catch (e) {
				setErrorExpenseTypesList(true);
				setLoadingExpenseTypesList(false);
			}
		};
		fetchData();
	}, [newExpenseType]);
	return { expenseTypesList, isLoadingExpenseTypesList, errorExpenseTypesList, setNewExpenseType };
};

const useGetSupplierBalances = (pageNumber, business_branch_id) => {
    const [supplierBalances, setSupplierBalances] = useState([]);
	const [isLoadingSupplierBalances, setLoadingSupplierBalances] = useState(true);
	const [errorSupplierBalances, setErrorSupplierBalances] = useState(false);
	const [newBalanceData, setNewBalanceData] = useState(true);

	const url = `/${resource_tag}/supplier/balances?business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setSupplierBalances(response.data.data);
				setLoadingSupplierBalances(false);
				setErrorSupplierBalances(false);
				setNewBalanceData(false);
			} catch (e) {
				setErrorSupplierBalances(true);
				setLoadingSupplierBalances(false);
			}
		};
		fetchData();
	}, [newBalanceData]);
	return { supplierBalances, isLoadingSupplierBalances, errorSupplierBalances, setNewBalanceData };
};

const useGetCustomerBalances = (pageNumber, business_branch_id) => {
    const [customerBalances, setCustomerBalances] = useState([]);
	const [isLoadingCustomerBalances, setLoadingCustomerBalances] = useState(true);
	const [errorCustomerBalances, setErrorCustomerBalances] = useState(false);
	const [newBalanceData, setNewBalanceData] = useState(true);

	const url = `/${resource_tag}/customer/balances?business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setCustomerBalances(response.data.data);
				setLoadingCustomerBalances(false);
				setErrorCustomerBalances(false);
				setNewBalanceData(false);
			} catch (e) {
				setErrorCustomerBalances(true);
				setLoadingCustomerBalances(false);
			}
		};
		fetchData();
	}, [newBalanceData]);
	return { customerBalances, isLoadingCustomerBalances, errorCustomerBalances, setNewBalanceData };
};


const useGetCashAndBankBalances = (pageNumber, business_branch_id) => {
    const [cashAndBankBalances, setCashAndBankBalances] = useState([]);
	const [isLoadingCashAndBankBalances, setLoadingCashAndBankBalances] = useState(true);
	const [errorCashAndBankBalances, setErrorCashAndBankBalances] = useState(false);
	const [newBalanceData, setNewBalanceData] = useState(true);

	const url = `/${resource_tag}/payment_types/balances?business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setCashAndBankBalances(response.data.data);
				setLoadingCashAndBankBalances(false);
				setErrorCashAndBankBalances(false);
				setNewBalanceData(false);
			} catch (e) {
				setErrorCashAndBankBalances(true);
				setLoadingCashAndBankBalances(false);
			}
		};
		fetchData();
	}, [newBalanceData]);
	return { cashAndBankBalances, isLoadingCashAndBankBalances, errorCashAndBankBalances, setNewBalanceData };
};

const useGetPayrollBalances = (pageNumber, business_branch_id) => {
    const [payrollBalances, setPayrollBalances] = useState([]);
	const [isLoadingPayrollBalances, setLoadingPayrollBalances] = useState(true);
	const [errorPayrollBalances, setErrorPayrollBalances] = useState(false);
	const [newBalanceData, setNewBalanceData] = useState(true);

	const url = `/${resource_tag}/payroll/balances?business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setPayrollBalances(response.data.data);
				setLoadingPayrollBalances(false);
				setErrorPayrollBalances(false);
				setNewBalanceData(false);
			} catch (e) {
				setErrorPayrollBalances(true);
				setLoadingPayrollBalances(false);
			}
		};
		fetchData();
	}, [newBalanceData]);
	return { payrollBalances, isLoadingPayrollBalances, errorPayrollBalances, setNewBalanceData };
};



export {useChartOfAccountsList, useChartOfAccountsNextAccountsList, useGetProfitAndLoss, useGetBalanceMonths, useGetBalanceSheet, useGetLedgerTransactions, useGetExpenseTypesList, useGetSupplierBalances, useGetPayrollBalances, useGetCustomerBalances, useGetCashAndBankBalances}