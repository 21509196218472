import React, {useEffect} from "react";
import {Box, Alert} from "@mui/material";
import EmployeeTable from "../tables/employeesTable";
import DepartmentTable from "../tables/departmentTable";
import ShiftCalendar from "../forms/shiftForms/shiftCalendar";
import EmployeeShiftAssignmentTable from "../tables/employeeShiftAssignmentTable";
import PayslipTable from "../tables/payslipTable";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import CustomTab from "components/Tabs/Tab";

const HumanResourceTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['humanResourceTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "humanResourceTab", tab: newValue })); 
  };
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);

  const tabComponents = [
    {Component: <DepartmentTable setIsSuccess={setIsSuccess}/>, label: "DEPARTMENTS", "index": 0},
    {Component: <EmployeeTable setIsSuccess={setIsSuccess}/>, label: "EMPLOYEES", "index": 1},
    {Component: <PayslipTable setIsSuccess={setIsSuccess}/>, label: "PAYSLIPS", "index": 2},
    {Component: <EmployeeShiftAssignmentTable setIsSuccess={setIsSuccess}/>, label: "SHIFT ASSIGNMENT", "index": 3},
    {Component: <ShiftCalendar setIsSuccess={setIsSuccess}/>, label: "SHIFT CALENDAR", "index": 4},
  ] 

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  return (
    <Box>
      {isSuccess ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="success">
          Operation was successful!
        </Alert>
      ) : isFailed ? (
        <Alert sx={{ m: "15px" }} severity="error">
          Operation was not successful!
        </Alert>
      ) : null}

      <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/>
    </Box>
  );
};

export default HumanResourceTab;
