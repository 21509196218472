import React, {useEffect} from "react";
import {Box, Alert} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import MainDashboard from "../forms/mainDashboard";
import PurchaseDashboard from "../forms/purchaseDashboard";
import SalesDashboard from "../forms/salesDashboard";
import ProductDashboard from "../forms/productDashboard";
import CustomTab from "components/Tabs/Tab";

const DashboardTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['dashboardTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "dashboardTab", tab: newValue })); 
  };
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);

  const tabComponents = [
    {Component: <SalesDashboard />, label: "SALES DASHBOARD", "index": 0},
    {Component: <PurchaseDashboard />, label: "PURCHASE DASHBOARD", "index": 1},
    {Component: <ProductDashboard />, label: "ITEM DASHBOARD", "index": 2},
  ] 

 useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  return (
    <Box m="20px">
      {isSuccess ? (
        <Alert severity="success">Operation was successful!</Alert>
      ) : isFailed ? (
        <Alert severity="error">Operation was not successful!</Alert>
      ) : null}

      <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/>
    </Box>
  );
};

export default DashboardTab;
