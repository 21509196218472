

import { Document, Text, StyleSheet, View, Page, Font, } from "@react-pdf/renderer";
import {  PdfRegular,  PdfPageNumber, PdfSmall, PdfSubSubHeading, PdfSubSubSubHeading } from "components/pdf/typographyComponents";
import { PdfHeaderView, PdfSubHeaderView, PdfView, PdfBorderView, PdfFooterView, } from "components/pdf/wrappedViewsComponents";
import moment from "moment";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

Font.register({
  family: "arimaRegular",
  src: "../../../../components/pdf/Arima/static/Arima-Regular.ttf",
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingBottom: "80pt",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    borderColor: "#000",
    borderWidth: 1,
    marginHorizontal: 20,
    flexFlow: 1,
  },
  tableRow: {
    flexDirection: "row",
  },
  headerBg: {
    backgroundColor: "#3AB9EA",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
  },
  tableCellHeader: {
    margin: 2,
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    margin: 2,
    fontSize: 12,
  },
  textCenter: {
    textAlign: "center",
  },
});



const RefundPdf = ({data, viewData, printedBy}) => {


  const return_number = viewData.id;
  const description = viewData.description;
  const created_by = viewData.created_by;
  const approvedBy = viewData.approved_by;
  const requisitionStatus = viewData.status;
  const grand_total = viewData.refunded_amount;
  const return_date = viewData.date_created;
  const customer = viewData.related_receipt.related_invoice.related_customer;
  const refund_products = viewData.refund_products;
  const receipt_number = viewData.related_receipt.receipt_number;

  const fields = [
    { title: "#", width: "3" },
    { title: "Description", width: "40" },
    { title: "UOM", width: "20" },
    { title: "Quantity", width: "17" },
    { title: "Refund Amount", width: "20" },
  ];

  const PdfTable = ({ headerFixed = false, fields, style }) => (
  <View style={styles.table}>
    <View style={[styles.tableRow, styles.headerBg]}>
      {fields.map((item, index) => (
        <View key={index} style={[{ width: item.width + "%" }]}>
          <Text style={[styles.tableCellHeader]}>{item?.title}</Text>
        </View>
      ))}
    </View>
    {refund_products?.map((row, index) => (
      <View key={index} style={styles.tableRow}>
        
          <PdfBorderView key={0} style={{   width: fields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{index + 1}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.related_invoice_product.related_product.product_name}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.related_invoice_product.related_product_unit.related_business_product_unit.name}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[3]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.related_invoice_product.quantity}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[4]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{withoutSymbolFormatter(parseFloat(row.related_invoice_product.total))}</PdfSmall>
          </PdfBorderView>
      </View>
    ))}
    <View style={styles.tableRow}>
        
          <PdfBorderView key={0} style={{   width: fields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[3]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <Text style={[styles.tableCellHeader]}>TOTAL</Text>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[4]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <Text style={[styles.tableCellHeader]} >{currencyFormatter(parseFloat(grand_total))}</Text>
          </PdfBorderView>
          
        
      </View>
  </View>
); 



  return (
    <Document title={`Goods Return #${receipt_number}`}>
      <Page size="A4" style={styles.page}>
        {/* HEADER */}
        <PdfHeaderView fixed>
          {/* <PdfHeading>JOLILABS PHARMACY</PdfHeading> */}
        </PdfHeaderView>
        <PdfSubHeaderView fixed>
          <PdfSmall>+256 761 780 800/ +256 704 912 888</PdfSmall>
          <PdfSmall>jolilabspharmacy@gmail.com</PdfSmall>
          <PdfSmall>www.jolilabspharmacy.com</PdfSmall>
          <PdfSmall>Located in Mukono Town Next to Bank of Baroda.</PdfSmall>
        </PdfSubHeaderView>

        {/* DOCUMENT TITLE */}
        <PdfView flexDirection={"row"} fixed>
          <View style={{ flexGrow: 3 }}>
            <PdfSubSubHeading>REFUND RECEIPT</PdfSubSubHeading>
          </View>
          <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
            <PdfSubSubSubHeading>#{return_number}</PdfSubSubSubHeading>
          </View>
        </PdfView>

        {/* ADDRESS */}
        <PdfView flexDirection={"row"} style={{ margin: 5 }}>
          <View style={{ flexGrow: 3 }}>
            <PdfSubSubSubHeading>Customer Information</PdfSubSubSubHeading>
            <PdfRegular>Name: {customer ? customer.related_user.first_name +' '+customer.related_user.last_name  : 'WALK-IN CUSTOMER'}</PdfRegular>
            <PdfRegular>Phone: {customer ? customer.related_user.phone_number:""}</PdfRegular>
          </View>
          <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
            <PdfSubSubSubHeading>Refunded on Receipt:</PdfSubSubSubHeading>
            <PdfRegular>{receipt_number}</PdfRegular>
          </View>
        </PdfView>

        {/* DATE */}
        <PdfView flexDirection={"row"} style={{ margin: 5 }}>
          <View style={{ flexGrow: 1 }}>
            <PdfSubSubSubHeading>Refund Date</PdfSubSubSubHeading>
            <PdfRegular>{moment(return_date).format("LL")}</PdfRegular>
          </View>
        </PdfView>

        {/* DOCUMENT DETAILS */}
        <PdfBorderView mv={10}>
          <PdfSubSubSubHeading>Refund Details</PdfSubSubSubHeading>
          <PdfRegular>{description}</PdfRegular>
        </PdfBorderView>

        {/* TABLE */}
        <PdfTable headerFixed fields={fields} />

        <PdfView flexDirection={"row"} style={{ margin: 5 }}>
          <View style={{ flexGrow: 1 }}>
            <PdfRegular>
              CREATED BY: {created_by.first_name} {created_by.last_name}
            </PdfRegular>
          </View>
        </PdfView>

        {/* PAGE NUMBER */}
        <PdfPageNumber />

        {/* FOOTER */}
        <PdfFooterView fixed>
          <PdfSmall style={{ color: "#F00B6B" }}>
            Here to make you feel better
          </PdfSmall>
          <PdfSmall style={{ color: "#F00B6B" }}>
            Printed By: {printedBy} {moment().format("LLL")}
          </PdfSmall>
        </PdfFooterView>
      </Page>
    </Document>
  );};


export default RefundPdf;