import React, {useEffect} from "react";
import { Box, Alert } from "@mui/material";
import Purchases from "../tables/purchases";
import PurchaseDrafts from "../tables/purchaseDrafts";
import PurchaseOrders from "../tables/purchaseOrders";
import PurchaseRequisition from "../tables/purchaseRequisition";
import SupplierReturnTable from "../tables/supplierReturnTable";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import CustomTab from "components/Tabs/Tab";

const PurchaseTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['purchaseTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "purchaseTab", tab: newValue })); 
  };
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);

  const tabComponents = [
    {Component: <PurchaseDrafts setIsSuccess={setIsSuccess}/>, label: "DRAFT PURCHASE INVOICES", "index": 0},
    {Component: <Purchases setIsSuccess={setIsSuccess}/>, label: "PURCHASE INVOICES", "index": 1},
    {Component: <PurchaseOrders setIsSuccess={setIsSuccess}/>, label: "PURCHASE ORDERS", "index": 2},
    {Component: <PurchaseRequisition setIsSuccess={setIsSuccess}/>, label: "PURCHASE REQUISITIONS", "index": 3},
    {Component: <SupplierReturnTable setIsSuccess={setIsSuccess}/>, label: "RETURNS", "index": 4},
  ] 

  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);


  return (
    <Box>
      {isSuccess ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="success">
          Operation was successful!
        </Alert>
      ) : isFailed ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="error">
          Operation was not successful!
        </Alert>
      ) : null}     
      <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/>
    </Box>
  );
};

export default PurchaseTab;