import React, {useState, useEffect} from "react";
import {Box, Button, Breadcrumbs, Link, Typography, Paper, Divider, Card, CardContent, Stack} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Chip from "@mui/material/Chip";
import { FlexCenter, FlexStart, FlexBetween } from "components/FlexBox";
import SendIcon from '@mui/icons-material/Send';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import InvoiceDetailsTable from "../tables/invoiceDetailsTable";
import requester from "api/pesabackend";
import NewDialog from "components/newDialog";
import ReceiptConfirmView from "./receiptConfirmView";
import InvoiceCreateView from "./invoiceCreateView";
import {Cut, Line, Printer, Text, Br, Row, Image, render} from "react-thermal-printer";
import JolilabsLogo from "../../../components/Logo/jolilabs_logo.png";
import moment from "moment/moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SalesInvoicePdf from "../forms/invoicePdf";
import SalesReceiptPdf from "../forms/recieptPdf";
import SalesBillPdf from "../forms/billPdf";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";
import { FormProviderCreateReceipt } from "../forms/formSetups/receiptCreateForm";

const detailsText = {
	fontSize: "16px",
	fontWeight: 600,
	padding: "5px"
}

const descriptionText = {
	fontSize: "16px",
	fontWeight: 400,
}

const InvoiceDetailsView = (props) => {
  const location = useLocation();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({})
  const [products, setProducts] = useState([]) 
  const [amountPaid, setAmountPaid] = useState(0)
  const [paymentMode, setPaymentMode] = useState({})
  const [customer, setCustomer] = useState("");
  const [transactionId, setTransactionId] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [isValidated, setIsValidated] = useState(false)
  const [isValidatedInvoice, setIsValidatedInvoice] = useState(false)
  const [paymentTypes, setPaymentTypes] = useState([])
  const [loadingPaymentTypes, setLoadingPaymentTypes] = useState(false)
  const [customers, setCustomers] = useState([])
  const [loadingCustomers, setLoadingCustomers] = useState(false)
  const [hasCustomer, setHasCustomer] = useState(false)
  const [loadingInvoiceDetails, setLoadingInvoiceDetails] = useState(true)
  const [isSubmittingReceipt, setIsSubmittingReceipt] = useState(false)
  const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false)
  const [returnedResponse, setReturnedResponse] = useState({})
  const [paymentsTotal, setPaymentsTotal] = useState([])
  const [paymentModesUsed, setPaymentModesUsed] = useState("")
  const { id } = location.state;

  const [multiplePayments, setMultiplePayments] = useState(true);
  const [receiptDate, setReceiptDate] = useState(moment().format("YYYY-MM-DD HH:mm"));

  const handleChangeMultiplePymentModes = () => {
    setMultiplePayments(!multiplePayments);
  };

  const handleChangePaymentDate = (event) => {
    setReceiptDate(event.target.value);
  };
  

  useEffect(() =>{
    fetchInvoiceDetails()
      
  }, []);

  const fetchInvoiceDetails = async () => {
		try {
			const response = await requester.get(`/billing/invoices/details?id=${id}`);
			setInvoiceDetails(response.data.data);
      setProducts(response.data.data.products);
      setCustomer(response.data.data.related_customer ? response.data.data.related_customer.id : "");
      setIsValidatedInvoice(response.data.data.related_customer? true : false);
			setLoadingInvoiceDetails(false);
		} catch (e) {
			setLoadingInvoiceDetails(false);
		}
	} 

  const fetchPaymentTypes = async () => {
		try {
			const response = await requester.get(`/billing/payment_types?search=False&business_branch=${business_branch_id}`);
			setPaymentTypes(response.data.data);
			setLoadingPaymentTypes(false);
		} catch (e) {
			setLoadingPaymentTypes(false);
		}
	} 

  const fetchCustomers = async () => {
		try {
			const response = await requester.get(`/customers/list?search=False&business_branch=${business_branch_id}`);
			setCustomers(response.data.data);
			setLoadingCustomers(false);
		} catch (e) {
			setLoadingCustomers(false);
		}
	} 

  const handleClickOpen = () => {
    fetchPaymentTypes()
		setOpen(true);
	};

  const handleClose = () => {
    setIsValidated(false)
		setOpen(false);
	};

  const handleClickOpenInvoice = () => {
    fetchCustomers();
		setOpenInvoice(true);
	};

  const handleCloseInvoice = () => {
    setIsValidatedInvoice(false)
		setOpenInvoice(false);
	};

  const handleChangeAmount = (value) => {
    const total = value.reduce((a, {amount_paid}) => a + parseFloat(amount_paid), 0)
    setPaymentsTotal(total);
    if(parseFloat(total) === parseFloat(invoiceDetails.total_amount)) {
      setIsValidated(true)
      setPaymentModesUsed(value)
    }else{
      setIsValidated(false)
    }
  }

  const handleChangeCustomer = (e) => {
    setCustomer(e.target.value)
    setIsValidatedInvoice(true)
  }

  const renderStatusPills = (status) => {
		switch (status) {
			case false:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="UNPAID" size="medium" color="warning" />;
			case true:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="PAID" size="medium" color="primary" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffeded", fontSize:"10px", fontWeight:700 }} label="CANCELLED" size="medium" color="danger" />;
		}
	};


const receipt = () => {
  const items = products 
  const amount = invoiceDetails.total_amount
  const change = invoiceDetails.receipt.balance
  const cash_tenderred = invoiceDetails.receipt.amount_tendered
  const receipt_number = invoiceDetails.receipt.receipt_number
  const created_by = invoiceDetails.created_by.first_name+' '+invoiceDetails.created_by.last_name
  const customer = invoiceDetails.related_customer? invoiceDetails.related_customer.related_user.first_name+' '+invoiceDetails.related_customer.related_user.last_name : ''


  return (
  <Printer type="epson" width={48} characterSet="pc437_usa">

    {/* ADDRESS DETAILS */}
    <Image align="center" src={JolilabsLogo} />
    <Br />
    <Text align="center" bold={true}>JOLILABS PHARMACY</Text>
    <Text align="center">+256 761 780 800/ +256 704 912 888</Text>
    <Text align="center">jolilabspharmacy@gmail.com</Text>
    <Text align="center">www.jolilabspharmacy.com</Text>
    <Text align="center">Located in Mukono Town Next to Bank of Baroda.</Text>
    <Br />
    <Text align="center" bold={true}>"We deliver to your doorstep."</Text>
    <Br />

    {/* TITLE */}
    <Line />
    <Text align="center" bold={true}>SALES RECEIPT</Text>

    {/* RECEIPT META */}
    <Line/> 
    <Text align="center" bold={true}>Receipt Number: {receipt_number} </Text>
    <Text>Date: {moment().format("LLL")} </Text>
    <Text>Customer Name: {customer} </Text>
    <Br />

    {/* RECEIPT ITEM DETAILS TITLE */}
    <Line/>
     <Row left="Item" right=" Qty    Unit   Price  Amount" />
    <Line/>

    {/* RECEIPT ITEM DETAILS*/}
    {items.map((item, index) => {

      // THIS FUNCTION IS VALID FOR THE FOLOWING CHARATER LENGTHS QTY(3), UNIT(7), PRICE(7), AMOUNT(7)
      let priceAmountSpace = 1
      let unitPriceSpace = 1
      let qtyUnitSpace = 1
      if (withoutSymbolFormatter(parseFloat(item.total)).toString().length<=7) { priceAmountSpace = 1 + (7-withoutSymbolFormatter(parseFloat(item.total)).toString().length)}
      if (withoutSymbolFormatter(parseFloat(item.sale_price)).toString().length<=7) { unitPriceSpace = 1 + (7-withoutSymbolFormatter(parseFloat(item.sale_price)).toString().length)}
      if (item.related_product_unit.related_business_product_unit.name.length<=7) { qtyUnitSpace = 1 + (7-item.related_product_unit.related_business_product_unit.name.length)}

      return (
        <Row key={index} gap={1} left={<Text>{item.related_product.product_name}</Text>} right={<Text bold={true}> {Number(item.quantity).toFixed(1)}{' '.repeat(qtyUnitSpace)}{item.related_product_unit.related_business_product_unit.name}{' '.repeat(unitPriceSpace)}{withoutSymbolFormatter(parseFloat(item.sale_price))}{' '.repeat(priceAmountSpace)}{withoutSymbolFormatter(parseFloat(item.total))}</Text>} />
      )
    })}
    
    <Line />
    {/* RECEIPT TOTAL AND CASH DETAILS*/}
    <Row left={<Text bold={true}>TOTAL</Text>} right={currencyFormatter(parseFloat(amount))} />
    <Row left="CASH" right={currencyFormatter(parseFloat(cash_tenderred))} />
    <Row left={<Text bold={true}>BALANCE</Text>} right={currencyFormatter(parseFloat(change))} />
    {/* <Row left={<Text bold={true}>Total Items</Text>} right={items.length} /> */}
    <Br />

    {/*TAX INFORMATION*/}
    <Line />
    
    <Line />
    <Br />

    {/* FOOTER NOTES CONTAINING SLOGAN AND THE PERSON WHO CREATED THE RECEIPT RECORD */}
    <Text align="center">**DELIVERY IS FREE**</Text>
    <Br/>
    <Text align="center">Here to make you feel better</Text>
    <Text align="center">You were served by {created_by} </Text>
    <Cut />
  </Printer>
  )
};


  const exceutePrint = async () => {
    const receiptData = receipt()
    const data = await render(receiptData);

    // let _port = port;
    // if (_port == null || _port == undefined || _port == {}) {
    //   try{
    //     const _port = await navigator.serial.requestPort();
    //     await _port.open({ baudRate: 9600 });
    //     dispatch(setPort(_port));
    //   }catch {
    //     console.log("error")
    //   }
    // }

    const vendorId = 0x1fc9
    const filters = [
      {usbVendorId: 0x1fc9}
    ]
    try{
      const _port = await navigator.serial.requestPort({filters}, false);
      await _port.open({ baudRate: 9600 });

      const writer = _port.writable?.getWriter();
      if (writer != null) {
        const writedData = await writer.write(data);
        writer.releaseLock();
        console.log(writedData);
      }
      await _port.close()
    }  catch {
      console.log("error")
    }
    navigate("/billing")
  }

  const saveReceipt = async () => {
    setIsSubmittingReceipt(true);
    invoiceDetails.receipt.amount_tendered = paymentsTotal;
    invoiceDetails.receipt.balance = parseFloat(paymentsTotal) - parseFloat(invoiceDetails.total_amount);
    const submitData = {
      "invoice_number": invoiceDetails.invoice_number,
      "amount_paid": invoiceDetails.total_amount,
      "amount_tendered": paymentsTotal,
      "date_created": receiptDate,
      "balance":  parseFloat(paymentsTotal) - parseFloat(invoiceDetails.total_amount),
      "payment_modes": paymentModesUsed, 
    }

    if(phoneNumber.length > 0){
      submitData["phone_number_used"] = phoneNumber
    }

    if(transactionId.length > 0){
      submitData["transaction_id"] = transactionId
    }


    try {
      const response = await requester.post("/billing/receipts", submitData)

      exceutePrint()
      setIsSubmittingReceipt(false)
      // navigate("/billing")
    }catch (err){
      setIsSubmittingReceipt(false)
    }
  }

  const saveInvoice = async () => {
    setIsSubmittingInvoice(true);
    const submitData = {
      "invoice_number": invoiceDetails.invoice_number,
      "amount_paid": 0,
      "related_customer": customer,
      "date_created": receiptDate,
      "amount_tendered": 0,
      "balance":  0,
      "payment_modes": [], 
    }

    try {
      const response = await requester.post("/billing/receipts", submitData)

      // replace the printing after testing
      // exceutePrint()
      //replace the printing after testing
      setIsSubmittingInvoice(false)
      navigate("/billing")
    }catch (err){
      setIsSubmittingInvoice(false)
    }
  }
  
  return (
    <Box>

        <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => navigate("/billing")}
          >
            <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Sales
          </Link>
          <Typography sx={{ display: "flex", alignItems: "center" }} color="text.primary">
            <b>Sales Invoice Details</b>
          </Typography>
        </Breadcrumbs>
        <Paper sx={{ p: "10px" }}>
        <Grid container spacing={4}>
          <Grid xs={12} sm={8}>
            <Stack spacing={3} sx={{ marginBottom: "20px" }}>
              <FlexBetween>
                <Typography variant="h4">Invoice Details - {invoiceDetails.invoice_number}</Typography>
              </FlexBetween>
              <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
                <Grid container spacing={2} marginBottom={"15px"}>
                  <Grid xs={12} sm={6}>

                    <Stack spacing={2}>
                      <FlexCenter>
                        {/* <ChairOutlinedIcon sx={facilityIcons} /> */}
                        <Typography sx={descriptionText}>Invoice Number: {invoiceDetails.invoice_number}</Typography>
                        <Typography sx={detailsText}></Typography>
                      </FlexCenter>
                      <FlexCenter>
                        {/* <BedOutlinedIcon sx={facilityIcons} /> */}
                        <Typography sx={descriptionText}>Invoice Description: {invoiceDetails.invoice_description}</Typography>
                        <Typography sx={detailsText}></Typography>
                      </FlexCenter>
                      <FlexCenter>
                        {/* <BedOutlinedIcon sx={facilityIcons} /> */}
                        <Typography sx={descriptionText}>Customer: {invoiceDetails.related_customer? invoiceDetails.related_customer.related_user.first_name+' '+invoiceDetails.related_customer.related_user.last_name : null}</Typography>
                        <Typography sx={detailsText}></Typography>
                      </FlexCenter>
                      <FlexCenter>
                        {/* <BedOutlinedIcon sx={facilityIcons} /> */}
                        <Typography sx={descriptionText}>Order Type: {invoiceDetails.order_type}</Typography>
                        <Typography sx={detailsText}></Typography>
                      </FlexCenter>
                    </Stack>
                  </Grid>
                  <Grid xs={12} sm={6}>

                    <Stack spacing={2}>
                      <FlexCenter>
                        {/* <ChairOutlinedIcon sx={facilityIcons} /> */}
                        <Typography sx={descriptionText}>Total Amount: {currencyFormatter(parseFloat(invoiceDetails.total_amount))}</Typography>
                        <Typography sx={detailsText}></Typography>
                      </FlexCenter>
                      <FlexCenter>
                        {/* <BedOutlinedIcon sx={facilityIcons} /> */}
                        <Typography sx={descriptionText}>Total Discount: {invoiceDetails.total_discount}</Typography>
                        <Typography sx={detailsText}></Typography>
                      </FlexCenter>
                      <FlexCenter>
                        {/* <BedOutlinedIcon sx={facilityIcons} /> */}
                        <Typography sx={descriptionText}>Status: {invoiceDetails.status}</Typography>
                        <Typography sx={detailsText}></Typography>
                      </FlexCenter>
                      <FlexCenter>
                        <>
                        {/* <ShowerOutlinedIcon sx={facilityIcons} /> */}
                        {!loadingInvoiceDetails && (
                          <Typography sx={descriptionText}>Created By: {`${invoiceDetails.created_by.first_name} ${invoiceDetails.created_by.last_name}`}</Typography>
                        )}
                        <Typography sx={detailsText}></Typography>
                        </>
                      </FlexCenter>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider />
                <Typography variant="h5">Products</Typography>
                <Grid container spacing={2} marginTop={"10px"}>
                  <Grid xs={12} sm={12}>
                    <Stack spacing={2}>
                      {loadingInvoiceDetails ? (
                        <></>
                      ) : (
                        <InvoiceDetailsTable products={invoiceDetails.products} />
                      )}
                    </Stack>
                    <Stack spacing={2}>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Grid>
          <Grid sx={{ display: { xs: "none", sm: "block" } }} xs={12} sm={4}>
            <Card >
              <CardContent sx={{ padding: 0 }}>
                <FlexStart sx={{ padding: "16px" }}>
                {/* <LandLordAvatar /> */}
                  <Box sx={{ marginLeft: "8px" }}>
                    <Typography variant="body1" sx={{ fontSize: "17px" }}>
                      Notes
                    </Typography>
                  </Box>
                </FlexStart>
                <Divider />
                <Stack spacing={3} sx={{ padding: "16px" }}>
                  
                </Stack>
              </CardContent>
            </Card>
            {!invoiceDetails.status ? (
              <Grid sx={{marginTop:"30px"}}>
                <Button variant="contained" endIcon={<SendIcon />} sx={{padding:"15px", margin:"5px"}} onClick={handleClickOpen}>Create Receipt</Button>
                <Button variant="contained" endIcon={<SendIcon />} sx={{padding:"15px", margin:"5px"}} onClick={handleClickOpenInvoice}>Create Invoice</Button>
              </Grid>
            ) : (
              <Grid sx={{marginTop:"30px"}}>
                <Button variant="contained" endIcon={<SendIcon />} sx={{padding:"15px", margin:"5px"}} onClick={exceutePrint}>Print POS Receipt</Button>
                <PDFDownloadLink document={<SalesReceiptPdf viewData={invoiceDetails}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Sales Receipt #${invoiceDetails.receipt.receipt_number}`}>
                  <Button variant="contained" endIcon={<SendIcon />} sx={{padding:"15px", margin:"5px"}} >Download Receipt</Button>
                </PDFDownloadLink>
              </Grid>
            )} 
            <Grid sx={{marginTop:"30px"}}>
              {!invoiceDetails.status && !invoiceDetails.is_invoice ? (
                <PDFDownloadLink document={<SalesBillPdf viewData={invoiceDetails}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Sales Bill #${invoiceDetails.invoice_number}`}>
                <Button variant="contained" endIcon={<SendIcon />} sx={{padding:"15px", margin:"5px"}} >Download Bill</Button>
              </PDFDownloadLink>
              ): invoiceDetails.is_invoice ?(
                <PDFDownloadLink document={<SalesInvoicePdf viewData={invoiceDetails}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Sales Invoice #${invoiceDetails.invoice_number}`}>
                <Button variant="contained" endIcon={<SendIcon />} sx={{padding:"15px", margin:"5px"}} >Download Invoice</Button>
              </PDFDownloadLink>
              ) : null }
            </Grid>
              
            
            
          </Grid>
        </Grid>
        {open && (
          <FormProviderCreateReceipt >
            <NewDialog
            open={open}
            handleClose={handleClose}
            enableActions={true}
            title={"Create Receipt"}
            buttonText="Create Receipt" 
            handleSubmit={saveReceipt}
            isSubmitting={isSubmittingReceipt}
            textStatus={!isValidated}
            text={"Please Ensure that you receive and Enter the correct amount"}
            isSubmittingText={"Creating Receipt, Please wait..."}
            isValidated={isValidated}
            content={
              <ReceiptConfirmView
                amountPaid={amountPaid}
                total={invoiceDetails.total_amount}
                paymentModes={paymentTypes}
                paymentsTotal={paymentsTotal}
                handleChangeAmount={handleChangeAmount}
                multiplePayments={multiplePayments}
                handleChangeMultiplePymentModes={handleChangeMultiplePymentModes}
                receiptDate={receiptDate}
                handleChangePaymentDate={handleChangePaymentDate}
              />
            }
            />
          </FormProviderCreateReceipt>
        )}  

        {openInvoice && (
          <NewDialog
            open={openInvoice}
            handleClose={handleCloseInvoice}
            enableActions={true}
            title={"Create Customer Invoice"}
            buttonText="Create Customer iNVOICE" 
            handleSubmit={saveInvoice}
            isSubmitting={isSubmittingInvoice}
            textStatus={!isValidatedInvoice}
            text={"Please Ensure that you select the correct CUSTOMER"}
            isSubmittingText={"Creating Invoice, Please wait..."}
            isValidated={isValidatedInvoice}
            content={
              <InvoiceCreateView
                total={invoiceDetails.total_amount}
                customers={customers}
                handleChangeCustomer={handleChangeCustomer}
                customer={customer}
              />
            }
            />
        )}  
        </Paper>
      </Box>
  );
};

export default InvoiceDetailsView;
