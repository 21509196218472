import React, {useEffect} from "react";
import { Box, Alert} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import PayrollSettings from "../tables/payRollSettingsTable";
import SalesSettings from "../tables/salesSettingsTable";
import InventorySettings from "../tables/inventorySettingsTable";
import PurchaseSettings from "../tables/purchaseSettingsTable";
import CustomTab from "components/Tabs/Tab";

const SetttingsTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['settingsTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "settingsTab", tab: newValue })); 
  };
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);

  const tabComponents = [
    {Component: <SalesSettings setIsSuccess={setIsSuccess}/>, label: "SALES", "index": 0},
    {Component: <InventorySettings setIsSuccess={setIsSuccess}/>, label: "INVENTORY", "index": 1},
    {Component: <PurchaseSettings setIsSuccess={setIsSuccess}/>, label: "PURCHASES", "index": 2},
    {Component: <PayrollSettings setIsSuccess={setIsSuccess}/>, label: "PAYROLL", "index": 3},
  ] 

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  return (
    <Box>     
      {isSuccess ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="success">
          Operation was successful!
        </Alert>
      ) : isFailed ? (
        <Alert sx={{ m: "15px" }} severity="error">
          Operation was not successful!
        </Alert>
      ) : null}

      <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/>
    </Box>
  );
};

export default SetttingsTab;
