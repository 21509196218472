import React from "react";
import {Box, Stack, Typography, TextField, InputLabel, Select, MenuItem, FormControl} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FlexBetween, FlexEnd } from "components/FlexBox";

const CustomerEditForm = ({name, description, handleChangeFirstName, handleChangeLastName, handleChangeEmailAddress, handleChangeAddress, handleChangePhoneNumber}) => {
  return (
          <Grid container spacing={4}>
            <Grid xs={12} sm={12}>
                <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
                  <Grid container spacing={2} marginTop={"10px"}>
                    <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h6" component="div">
                            Enter the Name of the Customer
                        </Typography>
                        <TextField
                            required
                            id="outlined-required"
                            label="Customer Name"
                            onChange={handleChangeFirstName}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </Stack>
                    </Grid>
                    {/* <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h6" component="div">
                          Enter the Last Name of the Customer
                        </Typography>
                        <TextField
                            required
                            id="outlined-required"
                            label="Customer Last Name"
                            onChange={handleChangeLastName}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </Stack>
                    </Grid> */}
                    {/* <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h6" component="div">
                            Enter the Email Address of the Customer
                        </Typography>
                        <TextField
                            required
                            id="outlined-required"
                            label="Email Address in the format a@email.com"
                            onChange={handleChangeEmailAddress}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </Stack>
                    </Grid> */}
                    <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h6" component="div">
                            Enter the Address/Location of the Customer
                        </Typography>
                        <TextField
                            id="outlined-required"
                            label="Address/Location"
                            onChange={handleChangeAddress}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </Stack>
                    </Grid>
                    <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h6" component="div">
                            Enter the Phone Number of the Customer
                        </Typography>
                        <TextField
                            id="outlined-required"
                            label="Phone Number"
                            onChange={handleChangePhoneNumber}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
            </Grid>
          </Grid>
   );
};

export default CustomerEditForm;
  