import React from "react";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import PropTypes from "prop-types";
  
function TabPanel(props) {
const { children, value, index, key, ...other } = props;

return (
    <div
        key={key}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
    </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
};
}

const CustomTab = ({tabComponents, value, handleChange}) => {
    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs  value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                    {tabComponents.map(({label}, index) => {
                        return (
                            <Tab key={index} label={label} {...a11yProps(index)} />
                        )
                    })}
                </Tabs>
            </Box>

            <Box>
                {tabComponents.map(({Component}, index) => { 
                    return (
                        <TabPanel key={index} value={value} index={index}>{Component}</TabPanel>
                    )
                })}
            </Box>
        </>
    );
};

export default CustomTab;
  