import React, { useState } from "react";
import {Box,Paper,Alert,useTheme, useMediaQuery, Breadcrumbs,Link,Typography,MenuItem, Button, TextField, FormControlLabel, Checkbox} from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import requester from "api/pesabackend";
import { useSelector } from "react-redux";

const EditUserGroupPermssionForm = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);

  React.useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  console.log(location.state)

  const initialValuesUserGroupPermissions = {
    role_id: location.state.id,
    role_permissions: location.state.permissions
  };
  

  const saveGroupPermissions = async (values, onSubmitProps) => {
    values.related_business = business_id
    values.related_business_branch = business_branch_id

    const savedPermissionsResponse = await requester.post(`/human_resource/employee_role/edit_role`, JSON.stringify(values), 
    {
      body: JSON.stringify(values),
    });

    if (savedPermissionsResponse.status === 200) {
      onSubmitProps.resetForm();
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();
        navigate("/userManagement");
      }, 1500);
    } 
    if (savedPermissionsResponse.status === 400) {
      setIsFailed(true);
    }
  };

  const userGroupPermissionSchema = yup.object().shape({
    role_id: yup.string().required("required"),
    role_permissions: yup.array()
    .of(
      yup.object().shape({
        permission_id: yup.number().required("required"),
        status: yup.boolean().required("required"),
        })
    )
    .required('Please add a purchase record items')
    .min(1, 'At least one purchase must be added'),
  });
  


  const handleGroupPermissionFormSubmit = async (values, onSubmitProps) => {
   saveGroupPermissions(values, onSubmitProps);
  };


  return (
    <Box>
      { 
        isSuccess ? ( 
            <Alert  sx={{ m:"15px" }}variant="filled" severity="success">Operation was successful!</Alert>
        ) : isFailed ? (
            <Alert  sx={{ m:"15px" }}severity="error">Operation was not successful!</Alert>
        ) : null 
      }

      <Box
        sx={{
          padding: "20px",
          marginX: "20px",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => navigate("/userManagement")}
          >
            <ManageAccountsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            User Management
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            Edit User Group Permissions
          </Typography>
        </Breadcrumbs>
        

        <Paper
          sx={{ width: "100%", padding: "40px", margin: "20px" }}
          elevation={3}
        >
          <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>Edit {location.state.role_name} Permissions</Typography>
          <Formik
            onSubmit={handleGroupPermissionFormSubmit}
            initialValues={initialValuesUserGroupPermissions}
            // validationSchema={userGroupPermissionSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(8, minmax(0, 1fr))"
                >
                  <FieldArray
                    name="role_permissions"
                    render={(arrayHelpers) => {
                      const role_permissions = values.role_permissions;

                      return (
                        <>
                          {role_permissions.map((row, index) => {
                            row.permission_id = row.id
                          return (

                            <Box sx={{ display:"flex", flexDirection:"row", gridColumn:"span 2", alignItems:"center" }}>
                              <Typography name={`role_permissions[${index}].permission_id`} value={`role_permissions[${index}].id`}>{row.related_permission.permission_name}</Typography>
                              
                              <Checkbox 
                                name={`role_permissions[${index}].status`}
                                value={`role_permissions[${index}].status`}
                                checked={row.status}
                                onChange={handleChange}
                              />
                            </Box>
                            
                          );
                        })}
                        </>
                        
                      );
                    }}
                  />
                </Box> 
                  
                {/* BUTTONS */}
                <Box>
                  <Button
                    fullWidth
                    type="submit"
                    sx={{
                      m: "1rem 0",
                      p: "1rem",
                      backgroundColor: palette.primary.main,
                      color: palette.background.alt,
                      "&:hover": { color: palette.primary.main },
                      gridColumn:"span 4"
                    }}
                  >
                    SAVE
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
  
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              onClick={() => navigate("/userManagement")}
              variant="contained"
              color="error"
              size="small"
              sx={{
                p: "0.5rem",
              }}
            >
              Cancel
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default EditUserGroupPermssionForm;
