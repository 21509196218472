import {Box,Grid,Button,Paper,InputBase,MenuItem,TextField,Autocomplete, Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetRefundsList } from "api/hooks/billing";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import { requester } from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { currencyFormatter } from "utilities/currencyFormatter";
import RefundViewForm from "../views/refundViewForm";


export default function RefundsTable({ setIsSuccess }) {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {refundsList, errorRefundsList, isLoadingRefundsList, setSearch, setNewReturnData, setDateFilter} = useGetRefundsList(1, business_branch_id);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const [showFiter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const [searchContent, setSearchContent] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(false);
  const [receiptList, setReceiptList] = useState([]);
  const [errorReceiptList, setErrorReceiptList] = useState(false);
  const [clickedItem, setClickedItem] = useState("");

  const fetchReceiptList = async (search) => {
    try {
      const url = `/billing/receipts?search=False&business_branch_id=${business_branch_id}`;
      if (search) {
        const response = await requester.get(
          `${url}&search=${search}`
        );
        setReceiptList(response.data.data);
      } else {
        const response = await requester.get(url);
        setReceiptList(response.data.data);
      }
    } catch (e) {
      setErrorReceiptList(true);
    }
  };

  const handleOpenReturn = async (invoice) => {
    const response = await requester.get(`/billing/invoices/meta_details?id=${invoice}`);
    if (response.status === 200) {
      navigate("/refundForm", { state: {id:invoice, data:response.data.data} });
    }
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  // const handleClickEditOpen = async (e) => {
    
  // };

  const handleClickDeleteOpen = async (e) => {
    const response = await requester.get(
      `/inventory/purchase_return/get_form/${e.target.value}`
    );
    if (response.status === 200) {
      setClickedItem(e.target.value);
      setViewData(response.data.data);
      setOpenDelete(true);
    }
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleClickDelete = async () => {
    const response = await requester.post(
      `/inventory/purchase_return/cancel/${clickedItem}`
    );
    if (response.status === 201) {
      setIsSuccess(true);
      setNewReturnData(true);
      setOpenDelete(false);
    }
    
  };

  const handleClickView = async (e) => {
    const response = await requester.get(
      `/billing/refunds/details?refund_id=${e.target.value}`
    );
    if (response.status === 200) {
      setViewData(response.data.data);
      setOpenView(true);
    }
  };

  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };


  const handleChangeFilter = async (e) => {
    if (e.target.value === "THIS MONTH") {
      setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "CUSTOM") {
      setShowFilter(true);
    }
  };

  const handleClickFilter = () => {
    setDateFilter({ start: startDate, end: endDate });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Typography variant="h4">Refunds List</Typography>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={3}>
            {/* <Paper
              sx={{
                my: "10px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Employee Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper> */}
          </Grid>

          <Grid xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>FILTER BY REFUND PERIOD:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="REFUND PERIOD"
                  select
                  defaultValue="THIS MONTH"
                  size="small"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width:'100%'
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                  <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                </TextField>
              </Paper>
              
              {showFiter ? (
                <>
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                        label="Start Date"
                        type="date"
                        defaultValue={startDate}
                        size="small"
                        onChange={(e) => setStartDate(e.target.value)}
                        // sx={{
                        //   m: "10px",
                        //   backgroundColor: "white",
                        // }}
                        inputProps={{
                          max: endDate,
                        }}
                      />
                  </Paper>
                  
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                        label="End Date"
                        type="date"
                        defaultValue={endDate}
                        size="small"
                        onChange={(e) => setEndDate(e.target.value)}
                        // sx={{
                        //   m: "10px",
                        //   backgroundColor: "white",
                        // }}
                        inputProps={{
                          min: startDate,
                        }}
                      />
                  </Paper>
                  
                </>
              ) : null}
              <Button
                variant="contained"
                size="small"
                sx={{
                  m: "9px",
                }}
                onClick={() => handleClickFilter()}
              >
                FILTER
              </Button>
            </Box>
          </Grid>
          <Grid xs={3} sx={{ textAlign: "right" }}>
            <Button variant="contained" sx={{m: "10px",}} onClick={handleClickOpen}>
              RECORD REFUND
            </Button>
            <FixedSizeDialog
              dialogHeading="Input the Receipt Number for the refund you are recording"
              open={open}
              handleClose={handleClickClose}
              negativeResponse="Cancel"
              maxWidth={"sm"}
              scroll={"body"}
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  // backgroundColor={theme.palette.background.alt}
                >
                  <Autocomplete
                    onOpen={() => {
                      fetchReceiptList(null);
                    }}
                    name={`selectedInvoice`}
                    onChange={(e, value) => {
                      setSelectedInvoice(value ? value.related_invoice.id : '');
                    }}
                    sx={{ margin: "10px" }}
                    options={receiptList}
                    getOptionLabel={(option) =>
                      `${option.receipt_number} - ${option.related_invoice.related_customer ? option.related_invoice.related_customer.related_user.first_name+' '+option.related_invoice.related_customer.related_user.last_name : 'WALK-IN CUSTOMER'}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={(props, receiptList) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={receiptList.id}
                      >
                        {receiptList.receipt_number} - {receiptList.related_invoice.related_customer ? receiptList.related_invoice.related_customer.related_user.first_name+' '+receiptList.related_invoice.related_customer.related_user.last_name : 'WALK-IN CUSTOMER'}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{ ...params.inputProps }}
                        label="Reciept Number"
                        value={selectedInvoice}
                        onChange={(e) => {
                          fetchReceiptList(e.currentTarget.value);
                        }}
                      />
                    )}
                  />
                  <Button
                    variant="contained"
                    sx={{ margin: "10px" }}
                    disabled={selectedInvoice ? false : true}
                    onClick={() => handleOpenReturn(selectedInvoice)}
                  >
                    RECORD REFUND
                  </Button>
                </Box>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>Refund Date</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 100 }}>Receipt Number</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 100 }}>Customer Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Total Refund</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Created By</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {refundsList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  refundsList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell> {moment(row.date_created).format("LL")} </StyledTableCell>
                          <StyledTableCell> {row.related_receipt.receipt_number} </StyledTableCell>
                          <StyledTableCell>{row.related_receipt.related_invoice.related_customer ? row.related_receipt.related_invoice.related_customer.related_user.first_name +' '+row.related_receipt.related_invoice.related_customer.related_user.last_name  : 'WALK-IN CUSTOMER'}</StyledTableCell>
                          <StyledTableCell>{currencyFormatter(parseFloat(row.refunded_amount))}</StyledTableCell>
                          <StyledTableCell> {row.created_by.first_name} {row.created_by.last_name} </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickView(e)}
                            >
                              View
                            </Button>

                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickDeleteOpen(e)}
                            >
                              Cancel
                            </Button>
                          </StyledTableCell>
                          <FixedSizeDialog
                            dialogHeading="View Refund"
                            open={openView}
                            handleClose={handleClickViewClose}
                            negativeResponse="Cancel"
                            maxWidth={"lg"}
                            scroll={"body"}
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                // backgroundColor={theme.palette.background.alt}
                              >
                                <RefundViewForm viewData={viewData}/>
                              </Box>
                            }
                          />
                          <FixedSizeDialog
                            dialogHeading="Cancel Refund"
                            open={openDelete}
                            handleClose={handleClickDeleteClose}
                            negativeResponse="Close"
                            maxWidth={"md"}
                            scroll={"body"}
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                // backgroundColor={theme.palette.background.alt}
                              >
                                <Button
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                  color="success"
                                  onClick={handleClickDelete}
                                  sx={{ m: "1rem 0", p: "1rem" }}
                                >
                                  Yes, Cancel
                                </Button>
                              </Box>
                            }
                          />
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={refundsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
