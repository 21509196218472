import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  user: null,
  token: null,
  refresh: null,
  business_id: null,
  business_branch_id: null,
  currentTab: {
    purchaseTab: 0,
    inventoryTab: 0,
    inventorySetupTab: 0,
    customerTab: 0,
    supplierTab: 0,
    humanResourceTab: 0,
    settingsTab: 0,
    accountingTab: 0,
    dashboardTab: 0,
    userMangementTab: 0,
    returnTab: 0,
    stockTransferTab:0,
    fileUploadTab: 0
  },
  port: null,
  refreshCount: 0,
  permissions: {},
  modules: {}
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.refresh = action.payload.refresh;
      state.business_id = action.payload.business_id;
      state.branch_details = action.payload.business_branch_details
      state.business_branch_id = action.payload.business_branch_id;
      state.permissions = action.payload.permissions
      state.modules = action.payload.modules
    },
    setRefreshToken: (state, action) => {
      state.token = action.payload
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      state.refresh = null;
      state.business_id = null;
      state.business_branch_id = null;
      state.currentTab = {
        purchaseTab: 0,
        inventoryTab: 0,
        inventorySetupTab: 0,
        customerTab: 0,
        supplierTab: 0,
        humanResourceTab: 0,
        settingsTab: 0,
        accountingTab: 0,
        dashboardTab: 0,
        userMangementTab: 0,
        returnTab: 0,
        stockTransferTab:0,
        fileUploadTab: 0
      };
      state.permissions = {};
      state.modules = {};
    },
    setCurrentTab: (state, action)=>{
      state.currentTab[action.payload.name] = action.payload.tab;
    },
    setPort: (state, action) => {
      state.port = action.payload
    },
    setRefreshCount: (state) => {
      state.refreshCount = state.refreshCount+1
    }
  },
});

export const { setMode, setLogin, setLogout, setFriends, setPosts, setPost, setCurrentTab, setRefreshToken, setPort, setRefreshCount } =
  authSlice.actions;
export default authSlice.reducer;
