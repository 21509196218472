import React, {useState, useEffect} from "react";
import {Box,Button,TextField,useMediaQuery,MenuItem,useTheme, Alert, Breadcrumbs, Link, Typography, Paper} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, } from "react-router-dom";
import { useSelector } from "react-redux";
import PurchaseRequistionDetailForm from "./purchaseRequisitionDetailForm";
import { useSupplierList } from "api/hooks/inventory";
import {v4 as uuidv4} from 'uuid';
import moment from "moment/moment";
import { requester } from "api/pesabackend";
const purchaseSchema = yup.object().shape({
  requisition_date: yup.date().required("required"),
  detail: yup.string(),
  products_requisitioned: yup.array()
  .of(
    yup.object().shape({
      related_product: yup.number().required("required"),
      related_product_unit: yup.string().required("required"),
      quantity_remaining_at_requisition: yup.number(),
      quantity_sold_in_30_days: yup.number(),
      quantity_requisitioned: yup.number().required("required"),
      last_purchase_unit_price: yup.number(),
      total_amount: yup.number()
      })
  )
  .required('Please add a purchase record items')
  .min(1, 'At least one purchase must be added'),
});


const PurchaseRequisitionForm = (props) => {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const token =  useSelector((state) => state.token);

  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  const initialValuesPurchase = {
    requisition_date: moment().format('YYYY-MM-DD'),
    products_requisitioned:[
      {
        related_product: '', related_product_unit: '', quantity_remaining_at_requisition: '', quantity_sold_in_30_days: '', quantity_requisitioned: 1, last_purchase_unit_price:0, total_amount:0,  data_id: uuidv4(),
      }
    ]
  };

  const[purchaseRequistionDetailFormData, setPurchaseRequistionDetailFormData] = useState([{
    quantity_requisitioned: 1,  data_id: uuidv4(),
  }]);


  const savePurchase = async (values, onSubmitProps) => {
    values.related_business = business_id
    values.related_business_branch = business_branch_id
    values.added_by = userId

    const savedItemResponse = await requester.post(`/inventory/purchase_requisition/add`, JSON.stringify(values));

    if (savedItemResponse.data.status===201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();
        navigate("/purchase");
      }, 1500);
      
    };

    if (savedItemResponse.data.status===400) {
      setIsFailed(true)
      setIsFailedError(savedItemResponse.data.errors)
    };

  };


  const handleItemAmountChange = (event, index) =>{
    const {value} = event.target;
    const newArray = purchaseRequistionDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, amount: Number(value)}
        }
        return item
    })
    setPurchaseRequistionDetailFormData(newArray);
  };
    

  const handleAddProductDetailSubmitData = () => {
    const oneRow = {
     quantity_requisitioned: 1,  data_id: uuidv4()
    };
    setPurchaseRequistionDetailFormData([...purchaseRequistionDetailFormData, oneRow])
  }; 


  const handleRemoveProductDetailRow = (index) => {
    let newFormData = purchaseRequistionDetailFormData
    newFormData.splice(index,1);
    setPurchaseRequistionDetailFormData(newFormData);
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await savePurchase(values, onSubmitProps);
  };
  
  return (
    <Box>
      {isSuccess ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="success">
          Purchase Requisition created successfully!
        </Alert>
      ) : isFailed ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="error">
          Operation was not successful!
        </Alert>
      ) : null}

      <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/purchase")}
        >
          <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Purchase
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <b>Record Purchase Requisition Form</b>
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ p: "10px" }}>
        <Box>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesPurchase}
            validationSchema={purchaseSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              resetForm,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "95%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>Requisition Date: </label>
                      <TextField
                        label=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.requisition_date}
                        name="requisition_date"
                        type="date"
                        error={
                          Boolean(touched.requisition_date) &&
                          Boolean(errors.requisition_date)
                        }
                        helperText={
                          touched.requisition_date &&
                          errors.requisition_date
                        }
                        sx={{
                          width: "45%",
                          marginBottom: "15px",
                        }}
                      />
                      <TextField
                        label="Requisition Details"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        rows={2}
                        value={values.detail}
                        name="detail"
                        error={
                          Boolean(touched.detail) &&
                          Boolean(errors.detail)
                        }
                        helperText={touched.detail && errors.detail}
                        sx={{
                          width: "100%",
                          margin: "10px 0px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="2px"
                  p="0.5rem"
                >
                  <h5>Input the products to be requisitioned here</h5>

                  <FieldArray
                    name="products_requisitioned"
                    render={(arrayHelpers) => {
                      const products_requisitioned =
                        values.products_requisitioned;

                      return (
                        <>
                          {products_requisitioned.map((row, index) => {
                            return (
                              <PurchaseRequistionDetailForm
                                key={index}
                                index={index}
                                data={row}
                                handleRemoveProductDetailRow={(index) => {
                                  arrayHelpers.remove(index);
                                  handleRemoveProductDetailRow(index);
                                }}
                                handleItemAmountChange={
                                  handleItemAmountChange
                                }
                                handleChange={handleChange}
                                errors={arrayHelpers.form.errors}
                                touched={arrayHelpers.form.touched}
                                name={`products_requisitioned[${index}]`}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                              />
                            );
                          })}

                          <Button
                            onClick={() => {
                              arrayHelpers.push({
                                related_product: "",
                                related_product_unit: "",
                                quantity_remaining_at_requisition: "",
                                quantity_sold_in_30_days: "",
                                quantity_requisitioned: 1,
                                last_purchase_unit_price:0,
                                total_amount:0,
                                data_id: uuidv4(),
                              });
                              handleAddProductDetailSubmitData();
                            }}
                            sx={{
                              m: "1rem 0",
                            }}
                            size="small"
                            variant="contained"
                          >
                            Add Product
                          </Button>
                        </>
                      );
                    }}
                  />
                </Box>

                {/* BUTTONS */}
                <Box>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      m: "1rem",
                      p: "0.5rem",
                    }}
                  >
                    Save Requisition
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      navigate(`/purchase`);
                    }}
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{
                      m: "1rem",
                      p: "0.5rem",
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
    </Box>
  );
};

export default PurchaseRequisitionForm;
