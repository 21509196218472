import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";

const DepartmentEditForm = (props) => {
  const {data} = props;
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const token = useSelector((state) => state.token);

  const initialValuesItem = {
    department_name: data.department_name,
  };

  const itemSchema = yup.object().shape({
    department_name: yup.string().required("required"),
  });

  const saveItem = async (values, onSubmitProps) => {
    const savedItemResponse = await requester.post(`/human_resource/departments/edit?id=${data.id}`, JSON.stringify(values) );

    if (savedItemResponse.data.status === 201) {
      props.setOpen(false);
      props.setNewDepartmentData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem}
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": "span 4",
            }}
          >
            <TextField
              label="Department Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.department_name}
              name="department_name"
              error={Boolean(touched.department_name) && Boolean(errors.department_name)}
              helperText={touched.department_name && errors.department_name}
              sx={{ gridColumn: "span 4" }}
            />
            
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              size="small"
              type="submit"
              sx={{
                m: "10px 0",
                p: "10px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
                // width: "100%",
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default DepartmentEditForm;
