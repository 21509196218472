import React from "react";
import { zodResolver } from '@hookform/resolvers/zod'
import {z} from 'zod';
import { useForm, FormProvider, useFormContext } from "react-hook-form";

export const paymentTypesSchema = z.object({
    payment_type: z.number().min(1),
    amount_paid: z.number().min(1)
})

export const receiptDataSchema = z.object({
    payment_modes: z.array(paymentTypesSchema).min(1)
})

/**
 * Hooks
 */

export const useFormCreateReceipt = () => useForm(
    zodResolver(receiptDataSchema)
)


/**
 * Form Context
 */
export const FormProviderCreateReceipt = ({children}) => {
    const methods = useFormCreateReceipt()
    return <FormProvider {...methods}>{children}</FormProvider>
}

export const useFormContextCreateReceipt = () => useFormContext()