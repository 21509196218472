import React, {useEffect} from "react";
import {Box, Alert,} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import BusinessSetupViewForm from "../forms/businessSetupViewForm";
import BusinessBranchTable from "../tables/businessBranchTable";
import BusinessDashboard from "../forms/businessDashboard";
import CustomTab from "components/Tabs/Tab";

const BusinessTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['settingsTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "settingsTab", tab: newValue })); 
  };
  
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);


  const tabComponents = [
    {Component: <BusinessDashboard setIsSuccess={setIsSuccess}/>, label: "BUSINESS DASHBOARD", "index": 0},
    {Component: <BusinessBranchTable setIsSuccess={setIsSuccess}/>, label: "BUSINESS BRANCHES", "index": 1},
    {Component: <BusinessSetupViewForm setIsSuccess={setIsSuccess}/>, label: "BUSINESS SETUP", "index": 2},
  ] 
  
  // const { _id, picturePath } = useSelector((state) => state.user);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  return (
    <Box>
      {isSuccess ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="success">
          Operation was successful!
        </Alert>
      ) : isFailed ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="error">
          Operation was not successful!
        </Alert>
      ) : null}
      <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/>
    </Box>
  );
};

export default BusinessTab;
