import React, { useState, useEffect } from "react";
import {Box, Button, TextField, Alert, Breadcrumbs, Link, Typography, Paper, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Autocomplete} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { FlexRowCenter, FlexRowAround, FlexCol, BorderedFlexCol, BorderedFlexRow, FlexJustifyStart, FlexEnd, FlexCenter, FlexColCenter, FlexRow } from "components/FlexBox";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, } from "react-router-dom";
import { useSelector } from "react-redux";
import {v4 as uuidv4} from 'uuid';
import { requester } from "api/pesabackend";
import InvoiceDetailForm from "./invoiceDetailForm";
import InvoiceConfirmView from "../views/invoiceConfirmView";
import FormDialog from "components/modal";
import CustomerForm from "scenes/customer/forms/customerForm";
import moment from "moment";


const InvoiceForm = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const navigate = useNavigate();
  const [isFailed, setIsFailed]  = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [openNewCustomer, setOpenNewCustomer] = useState(false);
  const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false)
  const [customer, setCustomer] = useState(0)
  const [hasCustomer, setHasCustomer] = useState(false)
  const [customers, setCustomers] = useState([])
  const [loadingCustomers, setLoadingCustomers] = useState(false)
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState(".");
  const [email, setEmail] = useState("a@gmail.com");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmittingCustomer, setIsSubmittingCustomer] = useState(false);
  const [billDate, setBillDate] = useState(moment().format("YYYY-MM-DD HH:mm"));


  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    fetchCustomers()
    return () => clearTimeout(timer);

  }, [isSuccess, isFailed]);

  const fetchCustomers = async () => {
		try {
			const response = await requester.get(`/customers/list?search=False&business_branch=${business_branch_id}`);
			setCustomers(response.data.data);
			setLoadingCustomers(false);
		} catch (e) {
			setLoadingCustomers(false);
		}
	} 

  const handleChangeCustomer = (e) => {
    setCustomer(e.target.value)
  }

  const handleChangeHasCustomer = (e) => {
		setHasCustomer(e.target.checked);
  }

  const invoiceSchema = yup.object().shape({
    invoice_description: yup.string(),
    invoice_products: yup.array()
    .of(
      yup.object().shape({
        related_product: yup.number().required("required"),
        related_product_unit: yup.number().required("required"),
        batch: yup.string(),
        quantity: yup.number().required("required"),
        discount: yup.number().required("required"),
        })
    )
    .required('Please add atleast one invoice item')
    .min(1, 'At least one sale must be added'),
  });

  const invoiceFormInitialValues = {
    invoice_description: "",
    total_amount: 0,
    total_discount:0,
    invoice_products:[
      {
        related_product: '', 
        related_product_unit: '', 
        batch: '', 
        quantity: 0, 
        sale_price: '',  
        discount: 0, 
        total: 0,
        data_id: uuidv4(),
      }
    ]
  };

  const[invoiceDetailFormData, setInvoiceDetailFormData] = useState([{
    quantity: 1, sale_price: '', batch: '',  discount: 0, total: 0, product_name: "", product_unit: "", related_product: "", related_product_unit: "", data_id: uuidv4(),
  }]);

  const [totalDiscount, setTotalDiscount] = useState(invoiceFormInitialValues.total_discount);
  const [grandTotal, setGrandTotal] = useState(invoiceFormInitialValues.total_amount);

  const handleGrandTotal = (newArray) =>{
    //use floats instead of number
    const grandTotalCalculation = newArray.reduce((total, newArray)=>{
      return Number(total) + Number(newArray.total)
    }, 0);
    setGrandTotal(grandTotalCalculation);
  };

  const handleTotalDiscount = (newArray) =>{
    //use floats instead of number
    const totalDiscountCalculation = newArray.reduce((total, newArray)=>{
      return Number(total) + Number(newArray.discount)
    }, 0);
    setTotalDiscount(totalDiscountCalculation);
  };


  const handleChangeBillDate = (event) => {
    setBillDate(event.target.value);
  };

  
  const handleItemAmountChange = (price, total, quantity, batch, product_name, product_unit, related_product, related_product_unit, index) =>{
    const newArray = invoiceDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, sale_price: parseFloat(price), total: parseFloat(total), quantity: parseFloat(quantity), batch: batch, product_unit: product_unit, product_name: product_name, related_product: related_product, related_product_unit: related_product_unit}
        }
        return item
    })
    setInvoiceDetailFormData(newArray);
    handleGrandTotal(newArray);
  };
 

  const handleItemDiscountChange = (event, index) =>{
  const {value} = event.target;
    const newArray = invoiceDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, discount: value}
        }
        return item
    })
    handleTotalDiscount(newArray);
    setInvoiceDetailFormData(newArray);
  };

    
  const handleAddProductDetailSubmitData = () => {
    const oneRow = {
     quantity: 1, sale_price: '', discount: 0, batch: '', total:0, data_id: uuidv4()
    };
    setInvoiceDetailFormData([...invoiceDetailFormData, oneRow])
  }; 


  const handleRemoveProductDetailRow = (index) => {
    let newFormData = invoiceDetailFormData
    newFormData.splice(index,1);
    setInvoiceDetailFormData(newFormData);
    handleGrandTotal(newFormData);    
    handleTotalDiscount(newFormData);  
  };

  const handleAddNewCustomer = () => {
    setOpenNewCustomer(true);
  }

  const handleCloseNewCustomer = () => {
    setOpenNewCustomer(false);
  };

  const handleChangeFirstName = (e) => {
    setfirstName(e.target.value);
  };

  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  };

  const handleChangeEmailAddress = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleClickOpen = () => {
		setOpen(true);
	};

  const handleClose = () => {
		// setModal("");
		setOpen(false);
	};


  const saveCustomer = async () => {
    setIsSubmittingCustomer(true);
    const submitData = {
      related_business: 1,
      related_business_branch: business_branch_id,
      first_name: firstName,
      last_name: lastName,
      email: email,
      address: address,
      phone_number: phoneNumber,
    };
    try {
      const response = await requester.post("/customers/add", submitData);
      setIsSubmittingCustomer(false);
      setIsSuccess(true);
      setOpenNewCustomer(false);
    } catch (err) {
      setIsSubmittingCustomer(false);
    }
  };

  const saveInvoice = async () => {
    setIsSubmittingInvoice(true)
    const submitData = {
      "related_business": business_id,
      "related_business_branch": business_branch_id,
      "total_amount": grandTotal,
      "total_discount": totalDiscount,
      "related_customer": customer,
      "date_created":billDate,
      "invoice_products": invoiceDetailFormData
    }
    try {
      const response = await requester.post("/billing/invoices", submitData)
      setIsSubmittingInvoice(false)
      navigate("/billing")
    }catch (err){
      console.log(err)
      setIsSubmittingInvoice(false)
    }
  }
  
  return (
    <Box>
        {isSuccess ? (
          <Alert sx={{ m: "15px" }} variant="filled" severity="success">
            Sales Invoice recorded successfully!
          </Alert>
        ) : isFailed ? (
          <Alert sx={{ m: "15px" }} variant="filled" severity="error">
            Operation was not successful!
          </Alert>
        ) : null}

        <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => navigate("/billing")}
          >
            <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Sales
          </Link>
          <Typography sx={{ display: "flex", alignItems: "center" }} color="text.primary">
            <b>Record Sales Billl</b>
          </Typography>
        </Breadcrumbs>
        <Paper sx={{ p: "10px" }}>
          <Box>
            <Formik
              initialValues={invoiceFormInitialValues}
              validationSchema={invoiceSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                resetForm,
                setFieldValue,
              }) => (
                <form >
                  <FlexCenter>
                      <h5>Date of Bill: </h5>
                      <TextField sx={{ marginX: "0.2rem" }} size="small"  type="datetime-local" onChange={handleChangeBillDate} value={billDate}/>
                  </FlexCenter>
                  <FlexRowAround>
                    <FormControlLabel control={<Checkbox checked={hasCustomer} onChange={handleChangeHasCustomer} />} label="Attach Customer to Bill" />
                  
                    {hasCustomer && (
                      <>
                        <FormControl fullWidth sx={{marginTop: 2}}>
                          {/* <InputLabel id="demo-simple-select-label">Select a Customer</InputLabel> */}
                          <Autocomplete
                            onChange={(e,value)=>{if (value) {setCustomer(value.id)} else {setCustomer("")}}}
                            sx={{ gridColumn: "span 3" }}
                            options={customers}
                            getOptionLabel={(option) => `${option.related_user.first_name}  ${option.related_user.last_name}`}
                            isOptionEqualToValue={(option, value)=>option.id === value.id}
                            renderOption={(props, customers) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={customers.id}>
                                {customers.related_user.first_name} {customers.related_user.last_name} 
                                </Box>
                            )}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    inputProps={{...params.inputProps}}
                                    label="Select a Customer"
                                    value={customer}
                                    onBlur={handleBlur}
                                />
                            }
                          />
                          {/* <Select
                              labelId="demo-simple-select-label"
                              id="category-select"
                              value={customer}
                              label="Select a Customer"
                              onChange={handleChangeCustomer}
                          >
                              {customers.map((value, index ) => {
                                  return (
                                    <MenuItem key={index} value={value.id}>{`${value.related_user.first_name} ${value.related_user.last_name}`}</MenuItem>
                                  )
                              })}
                          </Select> */}
                        </FormControl>
                        <Button size="small" variant="contained" sx={{ alignItems:"center", padding:"0.5rem", margin:"1rem", width:"10%" }} onClick={handleAddNewCustomer}>Create New Customer</Button>
                    </>
                  )}
                  </FlexRowAround>

                  <h4>Input the products to bill below</h4>
                  <BorderedFlexCol>

                    <FieldArray
                      name="invoice_products"
                      render={(arrayHelpers) => {
                        const invoice_products = values.invoice_products;

                        return (
                          <>
                            {invoice_products.map((row, index) => {
                              return (
                                <InvoiceDetailForm
                                  key={row.data_id}
                                  index={index}
                                  data={row}
                                  handleRemoveProductDetailRow={(index) => {
                                    arrayHelpers.remove(index);
                                    handleRemoveProductDetailRow(index);
                                  }}
                                  handleItemAmountChange={
                                    handleItemAmountChange
                                  }
                                  handleItemDiscountChange={
                                    handleItemDiscountChange
                                  }
                                  handleChange={handleChange}
                                  errors={arrayHelpers.form.errors}
                                  touched={arrayHelpers.form.touched}
                                  name={`invoice_products[${index}]`}
                                  handleBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                />
                              );
                            })}

                            <FlexJustifyStart>
                              <FlexRowAround>
                                <Button size="small" variant="contained"
                                  onClick={() => {
                                    arrayHelpers.push({
                                      related_product: "",
                                      related_product_unit: "",
                                      batch: "",
                                      quantity: 0,
                                      sale_price: "",
                                      discount: 0,
                                      total:0,
                                      data_id: uuidv4(),
                                    });
                                    handleAddProductDetailSubmitData();
                                  }}
                                  sx={{m: "1rem", p: "0.5rem"}}
                                >
                                  Add Product
                                </Button>

                                <Button size="small" variant="contained" color="error"
                                disabled
                                  sx={{m: "1rem", p: "0.5rem"}}
                                >
                                  Remove All
                                </Button>
                              </FlexRowAround>
                            </FlexJustifyStart>
                          </>
                        );
                      }}
                    />
                  </BorderedFlexCol>
                  
                  <BorderedFlexRow>
                    <FlexCol>
                      <label>Total Discount: </label>
                      <TextField
                        label=""
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={(values.discount = totalDiscount)}
                        name="discount"
                        type="number"
                        size="small"
                        error={
                          Boolean(touched.discount) &&
                          Boolean(errors.discount)
                        }
                        helperText={touched.discount && errors.discount}
                        inputProps={{
                          min: 0,
                        }}
                      />
                    </FlexCol>
                    <FlexCol>
                      <label>Grand Total: </label>
                      <TextField
                        label=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={(values.grand_total = grandTotal)}
                        name="grand_total"
                        disabled
                        size="small"
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        error={
                          Boolean(touched.grand_total) &&
                          Boolean(errors.grand_total)
                        }
                        helperText={touched.grand_total && errors.grand_total}
                      />
                    </FlexCol>
                  </BorderedFlexRow>

                  {/* BUTTONS */}
                  <BorderedFlexRow>
                    <Button variant="contained" color="error" size="small"
                      onClick={() => {
                        resetForm();
                        navigate(`/billing`);
                      }}
                      sx={{m: "1rem", p: "0.5rem"}}>
                      Cancel
                    </Button>
                    <Button onClick={handleClickOpen} variant="contained" size="small"
                      sx={{m: "1rem", p: "0.5rem",}}>
                      Create Bill
                    </Button>
                  </BorderedFlexRow>
                </form>
              )}
            </Formik>
          </Box>
        </Paper>
        <FormDialog
          open={open}
          handleClose={handleClose}
          title={"Confirm Bill Details"}
          handleSubmit={saveInvoice}
          // enableActions={true}
          buttonText="Confirm Bill"
          isSubmitting={isSubmittingInvoice}
          isSubmittingText={"Submitting Bill, Please Wait..."}
          content={
            <InvoiceConfirmView
              invoiceDescription={"New Bill"}
              invoiceDetails={invoiceDetailFormData}
              total={grandTotal}
              discount={totalDiscount}
            />
          }
        />
        <FormDialog
          open={openNewCustomer}
          handleClose={handleCloseNewCustomer}
          enableActions={true}
          title={"Add New Customer"}
          buttonText="Create New Customer" 
          handleSubmit={saveCustomer}
          isSubmitting={isSubmittingCustomer}
          isSubmittingText={"Creating Customer, Please wait..."}
          textStatus={true}
          text={`Please note that this action is going to create a new ledger called ${firstName} ${lastName} under Accounts Receivable`}
          content={
            <CustomerForm
              handleChangeFirstName={handleChangeFirstName}
              handleChangeLastName={handleChangeLastName}
              handleChangeEmailAddress={handleChangeEmailAddress}
              handleChangeAddress={handleChangeAddress}
              handleChangePhoneNumber={handleChangePhoneNumber}
            />
          }
          />
    </Box>
  );
};

export default InvoiceForm;
