import { Box, useTheme, Grid, Button, Paper, InputBase } from "@mui/material";
import ResponsiveDialog from "components/responsiveDialog";
import { useState } from "react";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import DepartmentCreateForm from "../forms/departmentForms/departmentCreateForm";
import DepartmentEditForm from "../forms/departmentForms/departmentEditForm";
import { useDepartmentsList } from "api/hooks/humanResource";
import { requester } from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";

export default function DepartmentTable({ setIsSuccess }) {
  const theme = useTheme();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const token = useSelector((state) => state.token);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState("");
  const [clickedItem, setClickedItem] = useState("");
  const [searchContent, setSearchContent] = useState("");
  const { departmentList, isLoadingDepartmentList, errorDepartmentList, setSearchDepartments, setNewDepartmentData} = useDepartmentsList(1, business_branch_id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickEditOpen = async (e) => {
    const response = await requester.get(`/human_resource/departments/get/${e.target.value}`);
    setEditData(response.data.data);
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false);
  };

  const handleClickDeleteOpen = async (e) => {
    setClickedItem(e.target.value);
    setOpenDelete(true);
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleClickDelete = async () => {
    const savedItemResponse = await requester.post(`/human_resource/departments/delete?id=${clickedItem}`);
    setIsSuccess(true);
    setNewDepartmentData(true);
    setOpenDelete(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value);
  };

  const handleClickSearch = async () => {
    setSearchDepartments(searchContent);
  };

  return (
    <Box>
      <h4>Department List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs>
            <Paper
              sx={{
                my: "4px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Department Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid xs></Grid>
          <Grid xs sx={{ textAlign: "right" }}>
            <Button variant="contained" onClick={handleClickOpen}>
              Add Department
            </Button>
            <ResponsiveDialog
              dialogButtonName="Add Department "
              dialogHeading="Add a New Department"
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              negativeResponse="Cancel"
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  backgroundColor={theme.palette.background.alt}
                >
                  <DepartmentCreateForm
                    setIsSuccess={setIsSuccess}
                    setOpen={setOpen}
                    setNewDepartmentData={setNewDepartmentData}
                  />
                </Box>
              }
            ></ResponsiveDialog>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell>Department Name</StyledTableCell>

                  <StyledTableCell style={{ textAlign: "center" }}>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {departmentList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  departmentList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{row.department_name}</StyledTableCell>

                          <StyledTableCell style={{ textAlign: "center" }}>
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickEditOpen(e)}
                            >
                              Edit
                            </Button>

                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickDeleteOpen(e)}
                            >
                              Delete
                            </Button>

                            {/* <Button value={row.id} size="small" onClick={(e) => handleClickView(e)} >{viewButtonName}</Button> */}

                            <ResponsiveDialog
                              dialogHeading="Edit"
                              open={openEdit}
                              handleClose={handleClickEditClose}
                              negativeResponse="Cancel"
                              content={
                                <Box
                                  p="1rem"
                                  borderRadius="0.5rem"
                                  backgroundColor={theme.palette.background.alt}
                                >
                                  <DepartmentEditForm
                                    setOpen={setOpenEdit}
                                    setNewDepartmentData={setNewDepartmentData}
                                    data={editData}
                                    setIsSuccess={setIsSuccess}
                                  />
                                </Box>
                              }
                            />

                            <ResponsiveDialog
                              dialogHeading="Are you sure you want to delete?"
                              open={openDelete}
                              handleClose={handleClickDeleteClose}
                              negativeResponse="Cancel"
                              content={
                                <Box
                                  p="1rem"
                                  borderRadius="0.5rem"
                                  backgroundColor={theme.palette.background.alt}
                                >
                                  {
                                    <Button
                                      fullWidth
                                      type="submit"
                                      variant="contained"
                                      onClick={handleClickDelete}
                                      sx={{
                                        m: "1rem 0",
                                        p: "1rem",
                                      }}
                                    >
                                      Yes, Delete
                                    </Button>
                                  }
                                </Box>
                              }
                            />

                            {/* <ResponsiveDialog
                                  dialogHeading = "Are you sure you want to delete?"
                                  open = {openView}
                                  handleClose = {handleClickViewClose}
                                  negativeResponse = "Cancel"
                                  content = {
                                    <Box
                                      p="1rem"
                                      borderRadius="0.5rem"
                                      backgroundColor={theme.palette.background.alt}
                                    >
                                      {ViewContent}
                                    </Box>
                                      
                                      }
                                /> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={departmentList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
