import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  MenuItem
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";
import { requester } from "api/pesabackend";

const itemSchema = yup.object().shape({
  uploaded_file: yup.string().required("required"),
});

const UploadCreateForm = (props) => {
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const token = useSelector((state) => state.token);

  const initialValuesItem = {
    uploaded_file: "",
  };

  

  const handleFormSubmit = async (values, onSubmitProps) => {
    values.related_business = business_id;
    values.related_business_branch = business_branch_id;

    /* Then create a new FormData obj */
    let formData = new FormData();

    /* append input field values to formData */
    for (let value in values) {
      formData.append(value, values[value]);
    }

    /* Can't console.log(formData), must
       use formData.entries() - example:  */
    for (let property of formData.entries()) {
      console.log(property[0], property[1]);
    }

    /* Now POST your formData: */
    props.saveItem(formData, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem}
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <Box
              gridColumn="span 4"
              border={`1px solid ${palette.neutral.medium}`}
              borderRadius="5px"
              p="1rem"
            >
              <Dropzone
                acceptedFiles=".jpg,.jpeg,.png, .pdf, .ppt, .xls, .doc"
                multiple={false}
                onDrop={(acceptedFiles) =>
                  setFieldValue("uploaded_file", acceptedFiles[0])
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.uploaded_file ? (
                      <p>Upload Document Here</p>
                    ) : (
                      <FlexBetween>
                        <Typography>{values.uploaded_file.name}</Typography>
                        <EditOutlinedIcon />
                      </FlexBetween>
                    )}
                  </Box>
                )}
              </Dropzone>
            </Box>
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default UploadCreateForm;
