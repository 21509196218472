import { Document, Text, StyleSheet, View, Page, Font, } from "@react-pdf/renderer";
import { PdfHeading, PdfRegular, PdfMedium, PdfPageNumber, PdfSmall, PdfSubSubHeading, PdfSubSubSubHeading } from "components/pdf/typographyComponents";
import { PdfHeaderView, PdfSubHeaderView, PdfView, PdfBorderView, PdfFooterView, PdfGrid } from "components/pdf/wrappedViewsComponents";
import moment from "moment";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

Font.register({
  family: "arimaRegular",
  src: "../../../../components/pdf/Arima/static/Arima-Regular.ttf",
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingBottom: "80pt",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    borderColor: "#000",
    borderWidth: 1,
    marginHorizontal: 20,
    flexFlow: 1,
  },
  tableRow: {
    flexDirection: "row",
  },
  headerBg: {
    backgroundColor: "#108ED4",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
  },
  tableCellHeader: {
    margin: 2,
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    margin: 2,
    fontSize: 12,
  },
  textCenter: {
    textAlign: "center",
  },
});



const SalesBillPdf = ({data, viewData, printedBy}) => {


  const invoice_number = viewData.invoice_number;
  const invoice_description = viewData.invoice_description;
  const grand_total = viewData.total_amount;
//   const discount = viewData.discount;
  const date_created = viewData.date_created;
  const related_customer = viewData.related_customer ? viewData.related_customer :{related_user:{ first_name:'',last_name:'', address:'', phone_number:'' }}
  const products_sold = viewData.products;

  const fields = [
    {
      title: "#",
      width: "3",
    },
    {
      title: "Description",
      width: "30",
    },
    {
      title: "Quantity",
      width: "12",
    },
    {
      title: "Unit",
      width: "10",
    },
    {
      title: "Batch",
      width: "15",
    },
    {
      title: "Price",
      width: "15",
    },
    {
      title: "Amount",
      width: "15",
    },
  ];

  const PdfTable = ({ headerFixed = false, fields, style }) => (
  <View style={styles.table}>
    <View style={[styles.tableRow, styles.headerBg]}>
      {fields.map((item, index) => (
        <View key={index} style={[{ width: item.width + "%" }]}>
          <Text style={[styles.tableCellHeader]}>{item?.title}</Text>
        </View>
      ))}
    </View>
    {products_sold?.map((row, index) => (
      <View key={index} style={styles.tableRow}>
        
          <PdfBorderView key={0} style={{   width: fields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{index + 1}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.related_product.product_name}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.quantity}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[3]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.related_product_unit.related_business_product_unit.name}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[4]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{row.batch}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[5]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{withoutSymbolFormatter(parseFloat(row.sale_price))}</PdfSmall>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[6]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <PdfSmall style={{ textAlign: 'center' }}>{withoutSymbolFormatter(parseFloat(row.total))}</PdfSmall>
          </PdfBorderView>
          
        
      </View>
    ))}
    <View style={styles.tableRow}>
        
          <PdfBorderView key={0} style={{   width: fields[0]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[1]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[2]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[3]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[4]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[5]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <Text style={[styles.tableCellHeader]}>TOTAL</Text>
          </PdfBorderView>
          <PdfBorderView key={0} style={{   width: fields[6]?.width + "%",   borderStyle: "solid",   borderLeftWidth: 0,   borderTopWidth: 0, }} mh={0} mv={0} p={0} bw={1} borderColor="#000">
            <Text style={[styles.tableCellHeader]} >{currencyFormatter(parseFloat(grand_total))}</Text>
          </PdfBorderView>
          
        
      </View>
  </View>
); 



  return (
    <Document title={`Sales Invoice #${invoice_number}`}>
      <Page size="A4" style={styles.page}>
        {/* HEADER */}
        <PdfHeaderView fixed>
          {/* <PdfHeading>JOLILABS PHARMACY</PdfHeading> */}
        </PdfHeaderView>
        <PdfSubHeaderView fixed>
          <PdfSmall>+256 761 780 800/ +256 704 912 888</PdfSmall>
          <PdfSmall>jolilabspharmacy@gmail.com</PdfSmall>
          <PdfSmall>www.jolilabspharmacy.com</PdfSmall>
          <PdfSmall>Located in Mukono Town Next to Bank of Baroda.</PdfSmall>
        </PdfSubHeaderView>

        {/* DOCUMENT TITLE */}
        <PdfView flexDirection={"row"} fixed>
          <View style={{ flexGrow: 3 }}>
            <PdfSubSubHeading>SALES BILL</PdfSubSubHeading>
          </View>
          <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
            <PdfSubSubSubHeading>#{invoice_number}</PdfSubSubSubHeading>
          </View>
        </PdfView>

        {/* ADDRESS */}
        <PdfView flexDirection={"row"} style={{ margin: 5 }}>
          <View style={{ flexGrow: 3 }}>
            <PdfSubSubSubHeading>Bill To</PdfSubSubSubHeading>
            <PdfRegular>{related_customer.related_user.first_name+' '+related_customer.related_user.last_name}</PdfRegular>
            <PdfRegular>Phone: {related_customer.related_user.phone_number}</PdfRegular>
          </View>
          <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
            <PdfSubSubSubHeading>Bill Date</PdfSubSubSubHeading>
            <PdfRegular>{moment(date_created).format("LL")}</PdfRegular>
          </View>
        </PdfView>

        {/* TABLE */}
        <PdfTable headerFixed fields={fields} />

        {/* DOCUMENT DETAILS */}
        <PdfBorderView mv={10}>
          <PdfSubSubSubHeading>Bill Details</PdfSubSubSubHeading>
          <PdfRegular>{invoice_description}</PdfRegular>
        </PdfBorderView>

        {/* PAGE NUMBER */}
        <PdfPageNumber />

        {/* FOOTER */}
        <PdfFooterView fixed>
          <PdfSmall style={{ color: "#F00B6B" }}>**DELIVERY IS FREE**</PdfSmall>
          <PdfSmall style={{ color: "#F00B6B" }}>Here to make you feel better</PdfSmall>
          <PdfSmall style={{ color: "#F00B6B" }}>Printed By: {printedBy} {moment().format("LLL")}</PdfSmall>
        </PdfFooterView>
      </Page>
    </Document>
  );};


export default SalesBillPdf;