import {
  Box,
  Grid,
  Button,
  Paper,
  InputBase,
  MenuItem,
  TextField,
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePurchaseList, useSupplierList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { inventoryApi } from "api/pesabackend";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DownloadIcon from "@mui/icons-material/Download";

export default function RecievableReport() {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { purchaseList, isLoadingPurchaseList, setSearch, setSupplierFilter } =
    usePurchaseList(1, business_branch_id);
  const { supplierList } = useSupplierList(1, business_branch_id);

  const handleChangeFilter = async (e) => {
    setSupplierFilter(e.target.value);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      
      <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/reports")}
        >
          <AssessmentIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Reports
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <b>Item Purchase Report</b>
        </Typography>
      </Breadcrumbs>
      <Box>
        <Box p="1rem" m="1rem" borderRadius="0.5rem">
          <Grid container spacing={4} className="alignHorizantal">
            <Grid xs={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // m: "4px",
                }}
              >
                <h4>REPORT PERIOD:</h4>

                <TextField
                  label="PERIOD"
                  select
                  defaultValue="THIS WEEK"
                  size="small"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    maxWidth: 300,
                    m: "10px",
                    backgroundColor: "white",
                  }}
                >
                  <MenuItem value="THIS WEEK">THIS WEEK</MenuItem>
                  <MenuItem value="TODAY">TODAY</MenuItem>
                  <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                  <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                </TextField>
              </Box>
            </Grid>

            <Grid xs={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // m: "4px",
                }}
              >
                <h4>FILTER BY SUPPLIER:</h4>

                <TextField
                  label="SUPPLIERS"
                  select
                  defaultValue="all"
                  size="small"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    maxWidth: 300,
                    m: "10px",
                    backgroundColor: "white",
                  }}
                >
                  <MenuItem key={"DEFAULT"} value="all">
                    ALL
                  </MenuItem>
                  {supplierList.map((row, index) => {
                    return (
                      <MenuItem key={index} value={row.id}>
                        {row.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            </Grid>
            <Grid xs={4} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                onClick={() =>
                  navigate("/purchaseForm", { formType: "create" })
                }
                endIcon={<DownloadIcon />}
              >
                EXPORT
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: "100vh" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                maxHeight="100vh"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 50 }}>No.</TableCell>
                    <TableCell style={{ minWidth: 100 }}>Item</TableCell>
                    <TableCell style={{ minWidth: 100 }}>
                      Description
                    </TableCell>
                    <TableCell>Average Cost</TableCell>
                    <TableCell>Current Stock</TableCell>
                    <TableCell>Discount</TableCell>
                    <TableCell>Supplier</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchaseList.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={12}
                        style={{ textAlign: "center" }}
                      >
                        <h3>No Data found</h3>
                      </TableCell>
                    </TableRow>
                  ) : (
                    purchaseList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {moment(row.date_added).format("LL")}
                            </TableCell>
                            <TableCell>{row.invoice_number}</TableCell>
                            <TableCell>{row.purchase_date}</TableCell>
                            <TableCell>{row.grand_total}</TableCell>
                            <TableCell>{row.discount}</TableCell>
                            <TableCell>
                              {row.related_supplier.name}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={purchaseList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
