import { Box,  Grid, Button, Paper, InputBase, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import DummyTable from "components/Tables/DummyTable";
import Chip from "@mui/material/Chip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FormDialog from "components/modal";
import moment from "moment";
import ExpenseTypesForm from "../forms/expenseTypesForm";
import ExpenseTransactionsForm from "../forms/expenseTransactionsForm";
import requester from "api/pesabackend";
import { setRefreshCount } from "state";
import { useGetPaymentTypesList } from "api/hooks/billing";
import { useGetExpenseTypesList } from "api/hooks/accounting";


export default function ExpenseTypesTable () {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {paymentTypesList} = useGetPaymentTypesList(1, business_branch_id);
  const {expenseTypesList, isLoadingExpenseTypesList, errorExpenseTypesList, setNewExpenseType} = useGetExpenseTypesList(1, business_branch_id)

  const categories = [
    {
        "name": "CASH",
        "id": 1
    },
    {
        "name": "MOBILE MONEY",
        "id": 2
    }
  ]

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [expenseId, setExpenseId] = useState("");
  const [paymentTypes, setPaymentTypes] = useState([] );
  const [paymentId, setPaymentId] = useState("");
  const [transactionDate, setTransactionDate] = useState(moment().format('YYYY-MM-DD'));
  const [isSubmittingExpenseType, setIsSubmittingExpenseType] = useState(false)
  const [isSubmittingExpenseTransaction, setIsSubmittingExpenseTransaction] = useState(false);
  const [open, setOpen] = useState(false);
  const [openExpenseRecording, setOpenExpenseRecording] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const[searchContent, setSearchContent] = useState("")

  const handleChangeName = (e) => {
    setName(e.target.value)
  }

  const handleChangeDescription = (e) => {
    setDescription(e.target.value)
  }


  const handleChangePaymentId = (e) => {
    setPaymentId(e.target.value);
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleChangeTransactionDate = (e) => {
    setTransactionDate(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setModal("");
    setOpen(false);
  };

  const handleClickOpenExpenseRecording = (expenseId) => {
    setExpenseId(expenseId);
    setPaymentTypes(paymentTypesList);
    setOpenExpenseRecording(true);
  };

  const handleCloseExpenseRecording = () => {
    // setModal("");
    setOpenExpenseRecording(false);
  };

  const saveExpenseType = async () => {
    setIsSubmittingExpenseType(true)
    const submitData = {
      "related_business": 1,
      "related_business_branch": business_branch_id,
      "expense_type_name": name,
      "expense_type_description": description,
    }
    try {
      const response = await requester.post("/accounting/expense_types", submitData)
      setIsSubmittingExpenseType(false)
      dispatch(setRefreshCount());
      setNewExpenseType(true);
      setOpen(false)
    }catch (err){
        setIsSubmittingExpenseType(false)
    }
  };

  const saveExpenseTransaction = async () => {
    setIsSubmittingExpenseTransaction(true)
    const submitData = {
      "expense_id": expenseId,
      "payment_type_id": paymentId,
      "amount": amount,
      "date": transactionDate,
    }
    try {
      const response = await requester.post("/accounting/expense_types/transaction", submitData)
      setIsSubmittingExpenseTransaction(false)
      dispatch(setRefreshCount())
      setOpenExpenseRecording(false)
    }catch (err){
        setIsSubmittingExpenseTransaction(false)
    }
  };

  const renderStatusPills = (status) => {
		switch (status) {
			case false:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="INACTIVE" size="medium" color="warning" />;
			case true:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="ACTIVE" size="medium" color="primary" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffeded", fontSize:"10px", fontWeight:700 }} label="CANCELLED" size="medium" color="danger" />;
		}
	};

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };


  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
    <Box>
      <h3>Expense Types</h3>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <InputBase
            placeholder="Search by Account Name"
            value={searchContent}
            onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
          />
          {/* <IconButton onClick={handleClickSearch}>
            <SearchIcon />
          </IconButton> */}

          <Button variant="contained" onClick={handleClickOpen}>
            CREATE EXPENSE TYPE
          </Button>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          {isLoadingExpenseTypesList ? (
            <DummyTable />
          ) : (
            <>
              <TableContainer sx={{ maxHeight: "100vh" }}>
                <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell style={{ minWidth: 50 }}>
                        No.
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: 100 }}>
                        Expense Type Name
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: 100 }}>
                        Description
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: 50 }}>
                        Created By
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ textAlign: "center", minWidth: 150 }}
                      >
                        Actions
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {expenseTypesList.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          <h3>No Data found</h3>
                        </TableCell>
                      </TableRow>
                    ) : (
                      expenseTypesList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <StyledTableRow key={row.id}>
                              <StyledTableCell>{index + 1}</StyledTableCell>
                              <StyledTableCell>
                                {row.expense_type_name}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.expense_type_description}
                              </StyledTableCell>
                              <StyledTableCell>{`${row.added_by.first_name} ${row.added_by.last_name}`}</StyledTableCell>
                              <StyledTableCell
                                style={{ textAlign: "center", minWidth: 100 }}
                              >
                                <Button
                                  value={row.id}
                                  size="small"
                                  onClick={(e) =>handleClickOpenExpenseRecording(row.id)}
                                >
                                  Record Expense
                                </Button>

                                {/* <Button
                                  value={row.id}
                                  size="small"
                                  onClick={() =>
                                    navigate("/ledgerTransactions", {
                                      state: {
                                        id: row.id,
                                        name: row.expense_type_name,
                                      },
                                    })
                                  }
                                >
                                  View Details
                                </Button> */}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={expenseTypesList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}

          <FormDialog
            open={open}
            handleClose={handleClose}
            enableActions={true}
            title={"Add New Expense Type"}
            buttonText="Create Expense Type"
            handleSubmit={saveExpenseType}
            isSubmitting={isSubmittingExpenseType}
            isSubmittingText={"Creating Expense Type, Please wait..."}
            textStatus={true}
            text={`Please note that this action is going to create a new ledger called ${name} under Expenses`}
            content={
              <ExpenseTypesForm
                handleChangeName={handleChangeName}
                handleChangeDescription={handleChangeDescription}
              />
            }
          />
          <FormDialog
            open={openExpenseRecording}
            handleClose={handleCloseExpenseRecording}
            enableActions={true}
            title={"Record Expense"}
            buttonText="Save Expense"
            handleSubmit={saveExpenseTransaction}
            isSubmitting={isSubmittingExpenseTransaction}
            isSubmittingText={"Recording Expense, Please wait..."}
            textStatus={true}
            text={`Please note that this action is going to create a transaction record`}
            content={
              <ExpenseTransactionsForm
                handleChangePaymentId={handleChangePaymentId}
                handleChangeAmount={handleChangeAmount}
                handleChangeTransactionDate={handleChangeTransactionDate}
                paymentTypes={paymentTypes}
              />
            }
          />
        </Paper>
      </Box>
    </Box>
  );
};