import {Box,Button,TextField,useMediaQuery,useTheme,} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";

const itemTypeSchema = yup.object().shape({
  name: yup.string().required("required"),
  mobile: yup.string().required("required"),
  address: yup.string().required("required"),
});


const SupplierForm = (props) => {
  

  const pageType = props.formType;
  let {id, editData} = props;
  const userId = useSelector((state) => state.user.id);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const token = useSelector((state) => state.token);
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNew = pageType === "create";
  const isEdit = pageType === "edit";

  const initialEditValuesItemUnit = {
    name: editData.name,
    mobile: editData.mobile,
    address: editData.address,
  };

  const initialValuesItemUnit = {
    name: "",
    mobile: "",
    address: "",
  };

  const saveItemUnit = async (values, onSubmitProps) => {
    values.related_business = business_id
    values.related_business_branch = business_branch_id
    values.added_by = userId

    const savedResponse = await requester.post(`/inventory/suppliers/add`, JSON.stringify(values));

    if (savedResponse.data.status === 201) {
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
      props.setOpen(false);
      props.setNewSupplierData(true);
    }
  };

  const editItemUnit = async (values, onSubmitProps) => {
    const savedResponse = await requester.post(`/inventory/suppliers/edit/${id}`, JSON.stringify(values));
    if (savedResponse.data.status === 201) {
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
      props.setOpen(false);
      props.setNewSupplierData(true);
    }
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    if (isNew) await saveItemUnit(values, onSubmitProps);
    if (isEdit) await editItemUnit(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={isNew ? initialValuesItemUnit : initialEditValuesItemUnit}
      validationSchema={itemTypeSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            
            <TextField
              label="Supplier Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              name="name"
              error={Boolean(touched.name) && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Mobile Number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.mobile}
              name="mobile"
              error={Boolean(touched.mobile) && Boolean(errors.mobile)}
              helperText={touched.mobile && errors.mobile}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              label="Address"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.address}
              name="address"
              error={Boolean(touched.address) && Boolean(errors.address)}
              helperText={touched.address && errors.address}
              sx={{ gridColumn: "span 2" }}
            />
          
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default SupplierForm;
  