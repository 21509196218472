import { Box, Grid, Paper, Breadcrumbs, Link, Typography, MenuItem, TextField, Button, Autocomplete } from "@mui/material";
import { useSelector } from "react-redux";
import {useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import moment from "moment";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import DummyTable from "components/Tables/DummyTable";
import requester from "api/pesabackend"; 
import { useLocation, useNavigate } from "react-router-dom";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useProductLogList } from "api/hooks/inventory";
import { useGetLedgerTransactions } from "api/hooks/accounting";


export default function LedgerTransactionsTable () {
  const location = useLocation()
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const navigate = useNavigate()
  const [showFiter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().endOf("month").format("YYYY-MM-DD"));

  const ledger_name = location.state.name;
  const ledger_id = location.state.id

  const {ledgerTransactions, isLoadingLedgerTransactions, errorLedgerTransactions, setAction, setFilter} = useGetLedgerTransactions(business_branch_id, ledger_id);

  const handleChangePeriodFilter = async (e) => {
    if (e.target.value === "THIS MONTH") {
      setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "THIS WEEK") {
      setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "TODAY") {
      setStartDate(moment().format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "CUSTOM") {
      setShowFilter(true);
    }
  };

  const handleClickFilter = () => {
    setFilter({ start: startDate, end: endDate });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    
  return (
    <Box>
      <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/accounting")}
        >
          <AccountBalanceIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Accounting
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <b>{ledger_name}</b>
        </Typography>
      </Breadcrumbs>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mx: "4px",
              }}
            >
              <h4>REPORT PERIOD:</h4>

              <TextField
                label="PERIOD"
                select
                defaultValue="THIS MONTH"
                size="small"
                onChange={(e) => {
                  handleChangePeriodFilter(e);
                }}
                sx={{
                  minWidth: 50,
                  maxWidth: 300,
                  m: "10px",
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="THIS WEEK">THIS WEEK</MenuItem>
                <MenuItem value="TODAY">TODAY</MenuItem>
                <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                <MenuItem value="CUSTOM">CUSTOM</MenuItem>
              </TextField>

              {showFiter ? (
                <>
                  <TextField
                    label="Start Date"
                    type="date"
                    defaultValue={startDate}
                    size="small"
                    onChange={(e) => setStartDate(e.target.value)}
                    sx={{
                      m: "10px",
                      backgroundColor: "white",
                    }}
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    defaultValue={endDate}
                    size="small"
                    onChange={(e) => setEndDate(e.target.value)}
                    sx={{
                      m: "10px",
                      backgroundColor: "white",
                    }}
                  />
                </>
              ) : null}
              <Button
                variant="contained"
                size="small"
                sx={{
                  m: "9px",
                }}
                onClick={() => handleClickFilter()}
              >
                FILTER
              </Button>
            </Box>
          </Grid>
          <Grid xs={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>FILTER BY ACTION:</h4>
              <TextField
                label={"ACTION"}
                defaultValue={"all"}
                select
                size="small"
                onChange={(e) => setAction(e.target.value)}
                sx={{
                  minWidth: 50,
                  maxWidth: 300,
                  m: "10px",
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="all">ALL</MenuItem>
                <MenuItem value="CREDIT">CREDIT</MenuItem>
                <MenuItem value="DEBIT">DEBIT</MenuItem>
              </TextField>
            </Box>
          </Grid>
          <Grid xs={3}></Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          {isLoadingLedgerTransactions ? (
            <DummyTable />
          ) : (
            <>
              <TableContainer sx={{ maxHeight: "100vh" }}>
                <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell
                        style={{ minWidth: 50, padding: "19px 6px" }}
                      >
                        Date Carried Out
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ minWidth: 150, padding: "19px 6px" }}
                      >
                        Memo
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ minWidth: 150, padding: "19px 6px" }}
                      >
                        Amount
                      </StyledTableCell>
                      <StyledTableCell style={{ padding: "19px 6px" }}>
                        Transaction Type
                      </StyledTableCell>
                      <StyledTableCell style={{ padding: "19px 6px" }}>
                        Done By
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {ledgerTransactions.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell
                          colSpan={12}
                          style={{ textAlign: "center" }}
                        >
                          <h3>No Data found</h3>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      ledgerTransactions
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <StyledTableRow>
                              <StyledTableCell style={{ padding: "19px 6px" }}>
                                {moment(row.posted_on).format("LLL")}
                              </StyledTableCell>
                              <StyledTableCell style={{ padding: "19px 6px" }}>
                                {row.memo}
                              </StyledTableCell>
                              <StyledTableCell style={{ padding: "19px 6px" }}>
                                {row.transaction_amount}
                              </StyledTableCell>
                              <StyledTableCell style={{ padding: "19px 6px" }}>
                                {row.ledger_side}
                              </StyledTableCell>
                              <StyledTableCell style={{ padding: "19px 6px" }}>
                                {row.posted_by.first_name}{" "}
                                {row.posted_by.last_name}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={ledgerTransactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};