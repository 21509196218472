import {Box,Button,TextField,useMediaQuery,useTheme,MenuItem,} from "@mui/material";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { inventoryApi } from "api/pesabackend";

const UserCreateForm = (props) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const token = useSelector((state) => state.token);

  const initialValuesItem = {
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    password: "",
    related_role: 0,
    
  };


  const itemSchema = yup.object().shape({
    first_name: yup.string().required("required"),
    last_name: yup.string().required("required"),
    email: yup.string().email("Input a valid email").required("required"),
    username: yup.string().required("required"),
    password: yup.string().required("required"),
    related_role: yup.number().required("required"),
  });

  const saveItem = async (values, onSubmitProps) => {


    const savedItemResponse = await inventoryApi.post( `/product_purchase/add`, JSON.stringify(values));
    const savedItem = await savedItemResponse.json();

    if (savedItem.status === 201) {
      props.setOpen(false);
      props.setNewUserData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem}
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              label="First Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.first_name}
              name="first_name"
              error={Boolean(touched.first_name) && Boolean(errors.first_name)}
              helperText={touched.first_name && errors.first_name}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              label="Last Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.last_name}
              name="last_name"
              error={Boolean(touched.last_name) && Boolean(errors.last_name)}
              helperText={touched.last_name && errors.last_name}
              sx={{ gridColumn: "span 1" }}
            />

            <TextField
              label="Username"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
              name="username"
              error={Boolean(touched.username) && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              sx={{ gridColumn: "span 1" }}
            />

            <TextField
              label="User Group"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.role}
              select
              name="role"
              error={Boolean(touched.role) && Boolean(errors.role)}
              helperText={touched.role && errors.role}
              sx={{ gridColumn: "span 1" }}
            >
              <MenuItem value={"CASHIER"}>{"CASHIER"}</MenuItem>
              <MenuItem value={"DISPENSER"}>{"DISPENSER"}</MenuItem>
            </TextField>

            <TextField
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              name="password"
              error={Boolean(touched.password) && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              sx={{ gridColumn: "span 2" }}
            />
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              size="small"
              type="submit"
              sx={{
                m: "10px 0",
                p: "10px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
                width: "20%",
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default UserCreateForm;
