import React, { useRef } from "react";
import {Box, Paper, Button} from "@mui/material";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import requester from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PurchaseDraftPdf from "./purchaseDraftPdf";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";


const PurchaseDraftViewForm = (props) => {

  // const [isSidebar, setIsSidebar] = useState(true);
  const user = useSelector((state) => state.user);
  const { viewData } = props;
  // const location = useLocation();
  const componentRef = useRef();

  const invoice_number = viewData.invoice_number;
  const detail= viewData.detail;
  const grand_total= viewData.grand_total;
  const discount=viewData.discount;
  const purchase_date= viewData.purchase_date;
  const related_supplier= viewData.related_supplier;
  const products_purchased=viewData.products_purchased;
  const status = viewData.status

  const handlePurchaseConfirm = async ()=>{
    const response = await requester.post(`/inventory/purchase_draft/confirm/${viewData.id}`);
    if (response.status === 201) {
      props.setIsSuccess(true);
      props.setNewPurchaseDraftData(true);
      props.setOpenView(false);
    }
  }


  return (
    <>
      <Paper sx={{ margin: "20px" }} elevation={3}>
        <Box
          sx={{ padding: "20px", margin: "20px", width: "95%" }}
          ref={componentRef}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h1>DRAFT PURCHASE INVOICE</h1>
            <h2># {invoice_number}</h2>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Invoice From
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {related_supplier.name}
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Address: {related_supplier.address}
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Phone: {related_supplier.mobile}
              </p>

              <br />

              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Invoice Date
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {moment(purchase_date).format("LL")}
              </p>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4
                style={{ textAlign: "right", margin: "0px", padding: "0px" }}
              ></h4>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Table>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: "lightgrey" }}>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    #
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Description
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",

                    }}
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Unit
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Batch
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      minWidth: 100
                    }}
                  >
                    Expiry Date
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      minWidth: 130
                    }}
                  >
                    Amount
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      minWidth: 100
                    }}
                  >
                    Discount
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!products_purchased ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  products_purchased.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell style={{   padding: "6px 6px",   border: "1px solid", }}>{index + 1}</StyledTableCell>
                        <StyledTableCell style={{   padding: "6px 6px",   border: "1px solid", }}>{row.related_product.product_name} {row.related_product.description}</StyledTableCell>
                        <StyledTableCell style={{   padding: "6px 6px",   border: "1px solid", }}>{row.quantity_purchased}</StyledTableCell>
                        <StyledTableCell style={{   padding: "6px 6px",   border: "1px solid", }}>{row.related_product_unit.related_business_product_unit.name}</StyledTableCell>
                        <StyledTableCell style={{   padding: "6px 6px",   border: "1px solid", }}>{row.batch}</StyledTableCell>
                        <StyledTableCell style={{   padding: "6px 6px",   border: "1px solid", }}>{row.expiry_date} </StyledTableCell>
                        <StyledTableCell style={{   padding: "6px 6px",   border: "1px solid", }}>{withoutSymbolFormatter(parseFloat(row.amount))}</StyledTableCell>
                        <StyledTableCell style={{   padding: "6px 6px",   border: "1px solid", }}>{withoutSymbolFormatter(parseFloat(row.discount))}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>TOTAL</b>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>{currencyFormatter(parseFloat(grand_total))}</b>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>{currencyFormatter(parseFloat(discount))}</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
              {/* <TableFooter>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    TOTAL
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    {grand_total}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    {discount}
                  </StyledTableCell>
                </StyledTableRow>
              </TableFooter> */}
            </Table>
          </Box>
          <br />
          <Box>
            <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              Purchase Details
            </h4>
            <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              {detail}
            </p>
          </Box>
          <hr />
        </Box>
      </Paper>
      {/* <ReactToPrint
        documentTitle={`Draft Purchase Invoice #${invoice_number}`}
        trigger={() => (
          <Button
            size="small"
            variant="contained"
            sx={{
              width: "20%",
              my: "15px",
              mx: "5px",
            }}
          >
            Print
          </Button>
        )}
        content={() => componentRef.current}
      /> */}
      <PDFDownloadLink document={<PurchaseDraftPdf viewData={viewData}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Draft Purchase Invoice #${invoice_number}`}>
        <Button
        size="small"
        variant="contained"
        sx={{
          width: "20%",
          my: "15px",
          mx: "5px",
        }}
      >
        Download
      </Button>
      </PDFDownloadLink>
      
      {status === 0 ? (
        <>
          <Button
            size="small"
            variant="contained"
            onClick={handlePurchaseConfirm}
            sx={{
              width: "20%",
              my: "15px",
              mx: "5px",
            }}
            color="success"
          >
            CONFIRM PURCHASE
          </Button>
          <p style={{ color: "green" }}>
            *If the purchase draft details are acceptable please click the
            CONFIRM PURCHASE button to record the purchase
          </p>
        </>
      ) : null}
    </>
  );

}

export default PurchaseDraftViewForm;