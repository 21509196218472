import React, {useEffect} from "react";
import { Box, Alert  } from "@mui/material";
import ItemCategories from "../tables/itemCategories";
import ItemUnits from "../tables/itemUnits";
import ItemSubCategories from "../tables/itemSubCategories";
import Items from "../tables/items";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import CustomTab from "components/Tabs/Tab";

const InventorySetupTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['inventorySetupTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "inventorySetupTab", tab: newValue })); 
  };
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);

  const tabComponents = [
    {Component: <Items setIsSuccess={setIsSuccess}/>, label: "ITEMS", "index": 0},
    {Component: <ItemUnits setIsSuccess={setIsSuccess}/>, label: "ITEM SALES UNITS", "index": 1},
    {Component: <ItemCategories setIsSuccess={setIsSuccess}/>, label: "ITEM CATEGORIES", "index": 2},
    {Component: <ItemSubCategories setIsSuccess={setIsSuccess}/>, label: "ITEM SUB CATEGORIES", "index": 3},
  ] 

  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  return (
    <Box>
      {isSuccess ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="success">
          Operation was successful!
        </Alert>
      ) : isFailed ? (
        <Alert sx={{ m: "15px" }} severity="error">
          Operation was not successful!
        </Alert>
      ) : null}
        
      <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/>
    </Box>
  );
};

export default InventorySetupTab;