import {Box,useTheme,Grid,Button,Paper,InputBase,TextField,MenuItem,} from "@mui/material";
import FixedSizeDialog from "components/fixedSizeDialog";
import ResponsiveDialog from "components/responsiveDialog";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { requester } from "api/pesabackend";
import { useEmployeeShiftsList } from "api/hooks/humanResource";
import ShiftAssignmentForm from "../forms/shiftForms/shiftAssignmentForm";
import EditShiftAssignmentForm from "../forms/shiftForms/editShiftAssignmentForm";
import moment from "moment";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";

export default function EmployeeShiftAssignmentTable({ setIsSuccess, setIsFailed }) {
  const theme = useTheme();
  const token = useSelector((state) => state.token);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [searchContent, setSearchContent] = useState("");

  const {employeeShiftList,isLoadingEmployeeShiftList,error,setFilter, setNewEmployeeShiftData, setEmployee} = useEmployeeShiftsList(1, business_branch_id);
  
  const [showFiter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const handleChangePeriodFilter = async (e) => {
    if (e.target.value === "THIS MONTH") {
      setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "THIS WEEK") {
      setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "TODAY") {
      setStartDate(moment().format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "CUSTOM") {
      setShowFilter(true);
    }
  };

  const handleClickFilter = () => {
    setFilter({ start: startDate, end: endDate });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const[employeeData, setEmployeeData] = useState("")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen =  () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEdit = async (e) => {
    const response = await requester.get(`/human_resource/employees/shifts/get/${e.target.value}` );
    setEmployeeData(response.data.data);
    setOpenEdit(true);
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClickOpenDelete = async (e) => {
    const response = await requester.get(`/human_resource/employees/shifts/get/${e.target.value}`);
    setEmployeeData(response.data.data);
    setOpenDelete(true);
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickDelete = async () => {
    const savedItemResponse = await requester.post( `/human_resource/employees/shifts/delete/${employeeData.id}`);

    if (savedItemResponse.data.status === 201) {
      setIsSuccess(true);
      setNewEmployeeShiftData(true);
      setOpenDelete(false);
    }

    if (savedItemResponse.data.status === 400) {
      setIsFailed(true);
    }
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value);
  };

  const handleClickSearch = async () => {
    setEmployee(searchContent);
  };

  return (
    <Box>
      <h4>Employee Shift Assignment List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={3}>
            <Paper
              sx={{
                my: "4px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Employee Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>ASSIGNMENT PERIOD:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="PERIOD"
                  select
                  defaultValue="THIS MONTH"
                  size="small"
                  onChange={(e) => {
                    handleChangePeriodFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width: "100%",
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem value="TODAY">TODAY</MenuItem>
                  <MenuItem value="THIS WEEK">THIS WEEK</MenuItem>
                  <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                  <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                </TextField>
              </Paper>

              {showFiter ? (
                <>
                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="Start Date"
                      type="date"
                      defaultValue={startDate}
                      size="small"
                      onChange={(e) => setStartDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                      inputProps={{
                        max: endDate,
                      }}
                    />
                  </Paper>

                  <Paper
                    sx={{
                      m: "10px",
                      display: "flex",
                      alignItems: "center",
                      minWidth: 130,
                      maxWidth: 200,
                    }}
                  >
                    <TextField
                      label="End Date"
                      type="date"
                      defaultValue={endDate}
                      size="small"
                      onChange={(e) => setEndDate(e.target.value)}
                      // sx={{
                      //   m: "10px",
                      //   backgroundColor: "white",
                      // }}
                      inputProps={{
                        min: startDate,
                      }}
                    />
                  </Paper>
                </>
              ) : null}
              <Button
                variant="contained"
                size="small"
                sx={{
                  m: "9px",
                }}
                onClick={() => handleClickFilter()}
              >
                FILTER
              </Button>
            </Box>
          </Grid>
          <Grid xs={3} sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              size="small"
              sx={{
                m: "9px",
              }}
              onClick={handleClickOpen}
            >
              ASSIGN SHIFT
            </Button>
            <ResponsiveDialog
              dialogHeading="Employee Shift Assignment"
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              negativeResponse="Cancel"
              content={
                <Box
                  p="1rem"
                  borderRadius="0.5rem"
                  backgroundColor={theme.palette.background.alt}
                >
                  <ShiftAssignmentForm
                    setOpen={setOpen}
                    setNewEmployeeShiftData={setNewEmployeeShiftData}
                    setIsSuccess={setIsSuccess}
                  />
                </Box>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Phone Number</StyledTableCell>
                  <StyledTableCell>Shift Assigned</StyledTableCell>
                  <StyledTableCell>Day Assigned</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {employeeShiftList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  employeeShiftList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell>
                            {row.related_employee.first_name}{" "}
                            {row.related_employee.last_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.related_employee.phone_number}
                          </StyledTableCell>
                          <StyledTableCell>{row.related_shift.shift_name}</StyledTableCell>
                          <StyledTableCell>
                            {moment(row.day_assigned).format("LL")}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {/* {row.is_assigned_shift ? ( */}
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickOpenEdit(e)}
                            >
                              Edit
                            </Button>
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickOpenDelete(e)}
                            >
                              Delete
                            </Button>
                            <FixedSizeDialog
                              dialogHeading="Edit Employee Shift Assignment"
                              open={openEdit}
                              maxWidth={"md"}
                              handleClickOpen={handleClickOpenEdit}
                              handleClose={handleCloseEdit}
                              negativeResponse="Cancel"
                              si
                              content={
                                <Box
                                  p="1rem"
                                  borderRadius="0.5rem"
                                  backgroundColor={theme.palette.background.alt}
                                >
                                  <EditShiftAssignmentForm
                                    setOpen={setOpenEdit}
                                    setNewEmployeeShiftData={
                                      setNewEmployeeShiftData
                                    }
                                    setIsSuccess={setIsSuccess}
                                    employeeData={employeeData}
                                  />
                                </Box>
                              }
                            />
                            <ResponsiveDialog
                              dialogHeading="Are you sure you want to delete this Shift Assignment?"
                              open={openDelete}
                              handleClickOpen={handleClickOpenDelete}
                              handleClose={handleCloseDelete}
                              negativeResponse="Cancel"
                              content={
                                <Box
                                  p="1rem"
                                  borderRadius="0.5rem"
                                  backgroundColor={theme.palette.background.alt}
                                >
                                  <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    onClick={handleClickDelete}
                                    sx={{
                                      m: "1rem 0",
                                      p: "1rem",
                                    }}
                                  >
                                    Yes, Delete
                                  </Button>
                                </Box>
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={employeeShiftList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
