import React from "react";
import {Box, Stack, Typography, TextField, InputLabel, Select, MenuItem, FormControl} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";

const ExpenseTransactionsForm = ({handleChangeTransactionDate, handleChangeAmount, handleChangePaymentId, paymentTypes}) => {
  return (
          <Grid container spacing={4}>
            <Grid xs={12} sm={12}>
                <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
                  <Grid container spacing={2} marginTop={"10px"}>
                    <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h6" component="div">
                            Enter the Date the Expense was made
                        </Typography>
                        <TextField
                            required
                            id="outlined-required"
                            type="date"
                            label="Date of Expense"
                            defaultValue={moment().format('YYYY-MM-DD')}
                            onChange={handleChangeTransactionDate}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </Stack>
                    </Grid>
                    <Grid xs={12} sm={12}>
                      <FormControl fullWidth sx={{marginTop: 2}}>
                          <InputLabel id="demo-simple-select-label">Select the Payment Type</InputLabel>
                          <Select
                              labelId="demo-simple-select-label"
                              id="category-select"
                              label="Select the Payment Type "
                              onChange={handleChangePaymentId}
                          >
                              {paymentTypes.map((value, index ) => {
                                  return (
                                      <MenuItem key={index} value={value.id}>{value.payment_type_name}</MenuItem>
                                  )
                              })}
                          </Select>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h6" component="div">
                            Enter the Expense Amount
                        </Typography>
                        <TextField
                            id="outlined-required"
                            label="Expense Amount"
                            type="number"
                            onChange={handleChangeAmount}
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
            </Grid>
          </Grid>
   );
};

export default ExpenseTransactionsForm;