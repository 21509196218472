import {Box,Button,TextField,useTheme, Breadcrumbs, Link, Typography, Alert, Paper} from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory"; 
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, } from "react-router-dom";
import { useSelector } from "react-redux";
import AdjustmentDetailForm from "./adjustmentDetailForm";
import {v4 as uuidv4} from 'uuid';
import {  useState } from "react";
import moment from "moment/moment";
import { requester } from "api/pesabackend";


const adjustmentSchema = yup.object().shape({
  detail: yup.string().required("required"),
  // grand_total: yup.number().required("required"),
  products_adjusted: yup
    .array()
    .of(
      yup.object().shape({
        related_product: yup.number().required("required"),
        quantity: yup.number().required("required"),
        system_quantity: yup.number().required(),
        // amount: yup.number().required("required"),
        adjustment_type: yup.string().required("required"),
        batch: yup.string().nullable(),
      })
    )
    .required("required"),
});

const initialValuesAdjustment = {
  adjustment_date: moment().format("YYYY-MM-DD"),
  detail: "",
  grand_total: 0,
  products_adjusted: [
    {
      related_product: "",
      batch: "",
      adjustment_type: "",
      quantity: "",
      system_quantity: "",
    },
  ],
};



const AdjustmentForm = (props) => {
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const token = useSelector((state) => state.token);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const[purchaseDetailFormData, setPurchaseDetailFormData] = useState([{
    related_product: '',  batch: '', adjustment_type: '', quantity: '', system_quantity:'',
      data_id: uuidv4(),
  }]);
  

  const savePurchase = async (values, onSubmitProps) => {
    values.related_business = business_id;
    values.related_business_branch = business_branch_id;
    values.added_by = userId;

    const savedItemResponse = await requester.post(`/inventory/stock_adjustment/add`, JSON.stringify(values));
    if (savedItemResponse.data.status === 201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();
        navigate("/inventory");
      }, 1500);
    }

    if (savedItemResponse.data.status === 400) {
      setIsFailed(true);
      setIsFailedError(savedItemResponse.data.errors);
    }
  };


  const handleAddProductDetailSubmitData = () => {
    const oneRow = {
      related_product: '',  batch: '', adjustment_type: '', quantity: '', system_quantity:'',
        data_id: uuidv4()
    };
    setPurchaseDetailFormData([...purchaseDetailFormData, oneRow])
  }; 


  const handleRemoveProductDetailRow = (index) => {
    const newFormData = purchaseDetailFormData
    newFormData.splice(index, 1);
    setPurchaseDetailFormData(newFormData);  
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await savePurchase(values, onSubmitProps);
  };
  
  return (
    <Box>
      {isSuccess ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="success">
          Adjustment recorded successfully!
        </Alert>
      ) : isFailed ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="error">
          Operation was not successful!
        </Alert>
      ) : null}
      <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/inventory")}
        >
          <InventoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Inventory
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <b>Record Stock Adjustment Form</b>
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ p: "10px" }}>
        <Box>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesAdjustment}
            validationSchema={adjustmentSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              resetForm,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    marginBottom: "20px",
                  }}
                >
                  {/* <Box */}
                  {/* sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      > */}

                  <label>Date: </label>
                  <TextField
                    label=""
                    disabled
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.adjustment_date}
                    name="adjustment_date"
                    type="date"
                    error={
                      Boolean(touched.adjustment_date) &&
                      Boolean(errors.adjustment_date)
                    }
                    helperText={
                      touched.adjustment_date && errors.adjustment_date
                    }
                    sx={{
                      width: "20%",
                      marginBottom: "15px",
                    }}
                  />
                  <TextField
                    label="Adjustment Details"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    multiline
                    rows={8}
                    value={values.detail}
                    name="detail"
                    error={
                      Boolean(touched.detail) && Boolean(errors.detail)
                    }
                    helperText={touched.detail && errors.detail}
                    sx={{
                      width: "60%",
                      marginTop: "15px",
                    }}
                  />
                  {/* </Box> */}
                </Box>
                <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="2px"
                  p="0.5rem"
                >
                  <h5>Input the products to be adjusted here</h5>
                  <FieldArray
                    name="products_adjusted"
                    render={(arrayHelpers) => {
                      const products_adjusted = values.products_adjusted;

                      return(
                        <>
                        {products_adjusted.map((row, index)=>{
                          return(
                            <AdjustmentDetailForm
                              key={row.data_id}
                              index={index}
                              data={row}
                              handleRemoveProductDetailRow={(index) => {
                                  arrayHelpers.remove(index);
                                  handleRemoveProductDetailRow(index);
                                }}
                              handleChange={handleChange}
                              errors={arrayHelpers.form.errors}
                              touched={arrayHelpers.form.touched}
                              name={`products_adjusted[${index}]`}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                            />
                          )
                        })}
                        <Button
                          onClick={() => {
                            arrayHelpers.push({
                              related_product: "",
                              batch: "",
                              adjustment_type: "",
                              quantity: "",
                              system_quantity: "",
                            });
                            handleAddProductDetailSubmitData();
                          }}
                          sx={{
                            m: "1rem 0",
                          }}
                          size="small"
                          variant="contained"
                        >
                          Add Product
                        </Button>
                        </>
                      )
                    }}
                  />
                  

                  
                </Box>

                {/* BUTTONS */}
                <Box>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      m: "1rem",
                      p: "0.5rem",
                    }}
                  >
                    Save Adjustments
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      navigate(`/inventory`);
                    }}
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{
                      m: "1rem",
                      p: "0.5rem",
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
    </Box>
  );
};

export default AdjustmentForm;
  