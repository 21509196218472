import {Box,Button,TextField,useMediaQuery,useTheme, Alert} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";
import { useState } from "react";
import moment from "moment";

const ShiftCreateForm = (props) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const [isFailed, setIsFailed] = useState(false);

  const initialValuesItem = {
    shift_name: "",
    start_time: moment().format("HH:mm"),
    end_time: moment().format("HH:mm"),
  };

  const itemSchema = yup.object().shape({
    shift_name: yup.string().required("required"),
    start_time: yup.string().required("required"),
    end_time: yup.string().required("required"),
  });

  const saveItem = async (values, onSubmitProps) => {

    values.related_business = business_id;
    values.related_business_branch = business_branch_id;
    values.added_by = userId;

    const savedItemResponse = await requester.post(`/human_resource/shifts`, values);

    if (savedItemResponse.data.status === 201) {
      props.setOpen(false);
      props.setNewShiftData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }

    if (savedItemResponse.data.status === 400) {
      // // props.setOpen(false);
      // props.setNewUserData(true);
      // onSubmitProps.resetForm();
      setIsFailed(true);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem}
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {isFailed ? (
              <Alert sx={{ m: "15px" }} variant="filled" severity="error">
                Operation was not successful!
              </Alert>
            ) : null}

            <TextField
              label="Shift Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.shift_name}
              name="shift_name"
              error={Boolean(touched.shift_name) && Boolean(errors.shift_name)}
              helperText={touched.shift_name && errors.shift_name}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              label="Start Time"
              type="time"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.start_time}
              name="start_time"
              error={Boolean(touched.start_time) && Boolean(errors.start_time)}
              helperText={touched.start_time && errors.start_time}
              sx={{ gridColumn: "span 1" }}
            />

            <TextField
              label="End Time"
              type="time"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.end_time}
              name="end_time"
              error={Boolean(touched.end_time) && Boolean(errors.end_time)}
              helperText={touched.end_time && errors.end_time}
              sx={{ gridColumn: "span 1" }}
            />
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              size="small"
              type="submit"
              sx={{
                m: "10px 0",
                p: "10px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
                // width: "20%",
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ShiftCreateForm;
