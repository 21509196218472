import { useEffect, useState } from "react";
import requester from "api/pesabackend";
import moment from "moment";

const useItemPurchaseReportList = (pageNumber, business_branch_id) => {
    const [itemPurchaseReportList, setItemPurchaseReportList] = useState([]);
	const [isLoadingItemPurchaseReportList, setLoadingItemPurchaseReportList] = useState(true);
	const [error, setError] = useState(false);
	const [supplier, setSupplier] = useState('all');
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});


	let urlString;

	if (supplier !==('all')) {
		urlString = `/inventory/reports/item_purchase?business_branch=${business_branch_id}&supplier=${supplier}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/inventory/reports/item_purchase?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	}

	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setItemPurchaseReportList(response.data.data);
				setLoadingItemPurchaseReportList(false);
			} catch (e) {
				setError(true);
				setLoadingItemPurchaseReportList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemPurchaseReportList, supplier, filter ]);
	return { itemPurchaseReportList, isLoadingItemPurchaseReportList, error, setSupplier, setFilter };
};

const useItemPurchaseSummativeReportList = (pageNumber, business_branch_id) => {
    const [itemPurchaseSummativeReportList, setItemPurchaseSummativeReportList] = useState([]);
	const [isLoadingItemPurchaseSummativeReportList, setLoadingItemPurchaseSummativeReportList] = useState(true);
	const [error, setError] = useState(false);
	const [supplier, setSupplier] = useState('all');
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});


	let urlString;

	if (supplier !==('all')) {
		urlString = `/inventory/reports/item_summative_purchase?business_branch=${business_branch_id}&supplier=${supplier}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/inventory/reports/item_summative_purchase?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	}

	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setItemPurchaseSummativeReportList(response.data.data);
				setLoadingItemPurchaseSummativeReportList(false);
			} catch (e) {
				setError(true);
				setLoadingItemPurchaseSummativeReportList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemPurchaseSummativeReportList, supplier, filter ]);
	return { itemPurchaseSummativeReportList, isLoadingItemPurchaseSummativeReportList, error, setSupplier, setFilter };
};


const useItemBatchwiseStockReportList = (pageNumber, business_branch_id) => {
    const [itemBatchwiseStockReportList, setItemBatchwiseStockReportList] = useState([]);
	const [isLoadingItemBatchwiseStockReportList, setLoadingItemBatchwiseStockReportList] = useState(true);
	const [error, setError] = useState(false);
	const [supplier, setSupplier] = useState('all');
	const [search, setSearch] = useState(false);

	let urlString;

	if (search) {
		urlString = `/inventory/reports/batchwise_stock?business_branch=${business_branch_id}&supplier=${supplier}&search=${search}`;
	} else {
		urlString = `/inventory/reports/batchwise_stock?business_branch=${business_branch_id}&supplier=${supplier}`;
	}

	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setItemBatchwiseStockReportList(response.data.data);
				setLoadingItemBatchwiseStockReportList(false);
			} catch (e) {
				setError(true);
				setLoadingItemBatchwiseStockReportList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemBatchwiseStockReportList, supplier, search ]);
	return { itemBatchwiseStockReportList, isLoadingItemBatchwiseStockReportList, error, setSupplier, setSearch };
};


const useItemSalesReportList = (pageNumber, business_branch_id) => {
    const [itemSaleReportList, setItemSaleReportList] = useState([]);
	const [isLoadingItemSaleReportList, setLoadingItemSaleReportList] = useState(true);
	const [error, setError] = useState(false);
	const [invoicedBy, setInvoicedBy] = useState('all');
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD HH:mm"), end:moment().endOf('month').format("YYYY-MM-DD HH:mm")});


	let urlString;

	if (invoicedBy !==('all')) {
		urlString = `/billing/reports/item_sales?business_branch=${business_branch_id}&invoiced_by=${invoicedBy}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/billing/reports/item_sales?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	}

	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setItemSaleReportList(response.data.data);
				setLoadingItemSaleReportList(false);
			} catch (e) {
				setError(true);
				setLoadingItemSaleReportList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemSaleReportList, invoicedBy, filter ]);
	return { itemSaleReportList, isLoadingItemSaleReportList, error, setInvoicedBy, setFilter };
};


const useItemSaleSummativeReportList = (pageNumber, business_branch_id) => {
    const [itemSaleSummativeReportList, setItemSaleSummativeReportList] = useState([]);
	const [isLoadingItemSaleSummativeReportList, setLoadingItemSaleSummativeReportList] = useState(true);
	const [error, setError] = useState(false);
	const [supplier, setSupplier] = useState('all');
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD HH:mm"), end:moment().endOf('month').format("YYYY-MM-DD HH:mm")});
	const [orderParameter, setOrderParameter] = useState('invoice_amount')
	const [listOrdering, setListOrdering] = useState('DESENDING')


	let urlString;

	if (supplier !==('all')) {
		urlString = `/billing/reports/item_summative_sales?business_branch=${business_branch_id}&supplier=${supplier}&order_parameter=${orderParameter}&list_order=${listOrdering}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/billing/reports/item_summative_sales?business_branch=${business_branch_id}&order_parameter=${orderParameter}&list_order=${listOrdering}&start=${filter.start}&end=${filter.end}`;
	}

	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setItemSaleSummativeReportList(response.data.data);
				setLoadingItemSaleSummativeReportList(false);
			} catch (e) {
				setError(true);
				setLoadingItemSaleSummativeReportList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemSaleSummativeReportList, urlString ]);
	return { itemSaleSummativeReportList, isLoadingItemSaleSummativeReportList, error, setSupplier, setFilter, setListOrdering, setOrderParameter};
};


const useItemReturnReportList = (pageNumber, business_branch_id) => {
    const [itemReturnReportList, setItemReturnReportList] = useState([]);
	const [isLoadingItemReturnReportList, setLoadingItemReturnReportList] = useState(true);
	const [error, setError] = useState(false);
	const [supplier, setSupplier] = useState('all');
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD HH:mm"), end:moment().endOf('month').format("YYYY-MM-DD HH:mm")});


	let urlString;

	if (supplier !==('all')) {
		urlString = `/inventory/reports/item_return?business_branch=${business_branch_id}&supplier=${supplier}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/inventory/reports/item_return?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	}

	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setItemReturnReportList(response.data.data);
				setLoadingItemReturnReportList(false);
			} catch (e) {
				setError(true);
				setLoadingItemReturnReportList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemReturnReportList, supplier, filter ]);
	return { itemReturnReportList, isLoadingItemReturnReportList, error, setSupplier, setFilter };
};


const useItemAdjustmentReportList = (pageNumber, business_branch_id) => {
    const [itemAdjustmentReportList, setItemAdjustmentReportList] = useState([]);
	const [isLoadingItemAdjustmentReportList, setLoadingItemAdjustmentReportList] = useState(true);
	const [error, setError] = useState(false);
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD HH:mm"), end:moment().endOf('month').format("YYYY-MM-DD HH:mm")});


	let urlString = `/inventory/reports/stock_adjustment?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;

	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setItemAdjustmentReportList(response.data.data);
				setLoadingItemAdjustmentReportList(false);
			} catch (e) {
				setError(true);
				setLoadingItemAdjustmentReportList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemAdjustmentReportList, filter ]);
	return { itemAdjustmentReportList, isLoadingItemAdjustmentReportList, error, setFilter };
};


const useItemDeadStockReportList = (pageNumber, business_branch_id) => {
    const [itemDeadStockReportList, setItemDeadStockReportList] = useState([]);
	const [isLoadingItemDeadStockReportList, setLoadingItemDeadStockReportList] = useState(true);
	const [error, setError] = useState(false);
	const [search, setSearch] = useState(false);

	let urlString;

	if (search) {
		urlString = `/inventory/reports/dead_stock?business_branch=${business_branch_id}&search=${search}`;
	} else {
		urlString = `/inventory/reports/dead_stock?business_branch=${business_branch_id}`;
	}

	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setItemDeadStockReportList(response.data.data);
				setLoadingItemDeadStockReportList(false);
			} catch (e) {
				setError(true);
				setLoadingItemDeadStockReportList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemDeadStockReportList, search ]);
	return { itemDeadStockReportList, isLoadingItemDeadStockReportList, error, setSearch };
};


const useProductTrends = () => {
  const [productTrends, setProductTrends] = useState([]);
  const [isLoadingProductTrends, setLoadingProductTrends] = useState(true);
  const [error, setError] = useState(false);
  const [newPeriod, setNewPeriod] = useState(moment().format("YYYY"));
  const [product, setProduct] = useState(null);

  let urlString

  if (product) {
    urlString = `/inventory/reports/item_trends?period=${newPeriod}&product=${product}`;
  } else {
    urlString = `/inventory/reports/item_trends?period=${newPeriod}`;
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requester.get(urlString);
        setProductTrends(response.data.data);
        setLoadingProductTrends(false);
      } catch (e) {
        setError(true);
        setLoadingProductTrends(false);
      }
    };
    fetchData();
  }, [isLoadingProductTrends, urlString]);
  return { productTrends, isLoadingProductTrends, error, setNewPeriod, setProduct };
};


const useItemRefundReportList = (pageNumber, business_branch_id) => {
    const [itemRefundReportList, setItemRefundReportList] = useState([]);
	const [isLoadingItemRefundReportList, setLoadingItemRefundReportList] = useState(true);
	const [error, setError] = useState(false);
	const [customer, setCustomer] = useState('all');
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD HH:mm"), end:moment().endOf('month').format("YYYY-MM-DD HH:mm")});


	let urlString;

	if (customer !==('all')) {
		urlString = `/billing/reports/item_refunds?business_branch=${business_branch_id}&customer=${customer}&start=${filter.start}&end=${filter.end}`;
	} else {
		urlString = `/billing/reports/item_refunds?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	}

	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setItemRefundReportList(response.data.data);
				setLoadingItemRefundReportList(false);
			} catch (e) {
				setError(true);
				setLoadingItemRefundReportList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingItemRefundReportList, customer, filter ]);
	return { itemRefundReportList, isLoadingItemRefundReportList, error, setCustomer, setFilter };
};


export { useItemPurchaseReportList, useItemBatchwiseStockReportList, useItemSalesReportList, useItemReturnReportList, useItemPurchaseSummativeReportList, useItemSaleSummativeReportList, useItemAdjustmentReportList, useItemDeadStockReportList, useProductTrends, useItemRefundReportList };