import { Box,  Grid, Button, Paper, InputBase, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePurchaseList, useSupplierList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import { useState } from "react";
import FixedSizeDialog from "components/fixedSizeDialog";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import moment from "moment";
import PurchaseViewForm from "../forms/purchaseForms/purchaseViewForm";
import { requester } from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { currencyFormatter } from "utilities/currencyFormatter";

     

export default function Purchases ({setIsSuccess}) {
  
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {purchaseList, isLoadingPurchaseList, setSearch, setSupplierFilter, setNewPurchaseData} = usePurchaseList(1, business_branch_id);
  const {supplierList} = useSupplierList(1, business_branch_id);
  
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState({});
  const[searchContent, setSearchContent] = useState("");
  const [clickedItem, setClickedItem] = useState("");

  

  const handleClickDeleteOpen = async (e) => {
    const response = await requester.get(`/inventory/product_purchase/get_form/${e.target.value}`);
    if (response.status===200){
      setClickedItem(e.target.value);
      setViewData(response.data.data);
      setOpenDelete(true);
    }
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };

  const handleClickDelete = async () => {
  const response = await requester.post(`/inventory/product_purchase/cancel/${clickedItem}`);
  if (response.status === 201) {
    setIsSuccess(true);
    setNewPurchaseData(true);
    setOpenDelete(false);
  }
  };

  const handleClickView = async (e) => {
    const response = await requester.get(`/inventory/product_purchase/get_form/${e.target.value}`);
    if (response.status === 200) {
      // navigate("/purchaseViewForm", { state: response.data.data });
      setViewData(response.data.data);
      setOpenView(true);
    }
  };

  const handleClickViewClose = async (e) => {
    setOpenView(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearch(searchContent)
  };

  const handleChangeFilter = async (e)=> {
    setSupplierFilter(e.target.value);
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
    <Box>
      <h4>Purchase Invoice List</h4>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={4}>
            <Paper
              sx={{
                my: "10px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Invoice Number"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          <Grid xs={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>FILTER BY SUPPLIER:</h4>
              <Paper
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 130,
                  maxWidth: 200,
                }}
              >
                <TextField
                  label="SUPPLIERS"
                  select
                  defaultValue="all"
                  size="small"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  sx={{
                    minWidth: 50,
                    width:'100%'
                    // m: "10px",
                    // backgroundColor: "white",
                  }}
                >
                  <MenuItem key={"DEFAULT"} value="all">
                    ALL
                  </MenuItem>
                  {supplierList.map((row, index) => {
                    return (
                      <MenuItem key={index} value={row.id}>
                        {row.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Paper>
            </Box>
          </Grid>
          <Grid xs={4} sx={{ textAlign: "right" }}>
            {/* <Button
              variant="contained"
              onClick={() => navigate("/purchaseForm", { formType: "create" })}
            >
              ADD PURCHASE
            </Button> */}
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 50 }}>No.</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>Date Added</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Invoice Number</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 150 }}>Invoice Date</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Grand Total</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Discount</StyledTableCell>
                  <StyledTableCell style={{ minWidth: 50 }}>Supplier</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", minWidth: 150 }}>
                    Actions
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {purchaseList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  purchaseList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>
                            {moment(row.date_added).format("LLL")}
                          </StyledTableCell>
                          <StyledTableCell>{row.invoice_number}</StyledTableCell>
                          <StyledTableCell>{row.purchase_date}</StyledTableCell>
                          <StyledTableCell>{currencyFormatter(parseFloat(row.grand_total))}</StyledTableCell>
                          <StyledTableCell>{currencyFormatter(parseFloat(row.discount))}</StyledTableCell>
                          <StyledTableCell>{row.related_supplier.name}</StyledTableCell>
                          <StyledTableCell
                            style={{ textAlign: "center", minWidth: 150 }}
                          >
                            <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickView(e)}
                            >
                              View
                            </Button>
                            {/* <Button
                              value={row.id}
                              size="small"
                              onClick={(e) => handleClickDeleteOpen(e)}
                            >
                              Cancel
                            </Button> */}
                          </StyledTableCell>
                          <FixedSizeDialog
                            dialogHeading="View Purchase Invoice"
                            open={openView}
                            handleClose={handleClickViewClose}
                            negativeResponse="Cancel"
                            maxWidth={"lg"}
                            scroll={"body"}
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                // backgroundColor={theme.palette.background.alt}
                              >
                                <PurchaseViewForm viewData={viewData} />
                              </Box>
                            }
                          />
                          <FixedSizeDialog
                            dialogHeading="Cancel Purchase"
                            open={openDelete}
                            handleClose={handleClickDeleteClose}
                            negativeResponse="Close"
                            maxWidth={"md"}
                            scroll={"body"}
                            content={
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                // backgroundColor={theme.palette.background.alt}
                              >
                                <PurchaseViewForm viewData={viewData} setIsSuccess={setIsSuccess} />
                                <Button
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                  color="success"
                                  onClick={handleClickDelete}
                                  sx={{ m: "1rem 0", p: "1rem" }}
                                >
                                  Yes, Cancel
                                </Button>
                              </Box>
                            }
                          />
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={purchaseList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};