import React, {useEffect} from "react";
import { Box, Alert } from "@mui/material";
import Stock from "../tables/stock";
import StockAdjustments from "../tables/stockAdjustments";
import OutOfStock from "../tables/outOfStockTable";
import ExpiredStock from "../tables/expiriesTable";
import NearExpiryStock from "../tables/nearExpiriesTable";
import NearOutOfStock from "../tables/nearOutOfStockTable";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import CustomTab from "components/Tabs/Tab";


const InventoryTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['inventoryTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "inventoryTab", tab: newValue })); 
  };
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);

  const tabComponents = [
    {Component: <Stock />, label: "CURRENT STOCK", "index": 0},
    {Component: <StockAdjustments />, label: "STOCK ADJUSTMENTS", "index": 1},
    {Component: <NearOutOfStock />, label: "NEARLY OUT OF STOCK", "index": 2},
    {Component: <OutOfStock />, label: "OUT OF STOCK", "index": 3},
    {Component: <ExpiredStock />, label: "EXPIRED STOCK", "index": 4},
    {Component: <NearExpiryStock />, label: "NEAR EXPIRY STOCK", "index": 5},
  ] 

  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);


  return (
    <Box>
      
      {
        isSuccess ? ( 
            <Alert sx={{ m:"15px" }} variant="filled" severity="success">Operation was successful!</Alert>
        ) : isFailed ? (
            <Alert  sx={{ m:"15px" }} variant="filled" severity="error">Operation was not successful!</Alert>
        ) : null
                                        
      }
      <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/> 
    </Box>
  );
};

export default InventoryTab;