import {useEffect, useState} from "react";
import requester from "api/pesabackend"; 
import moment from "moment";


const useUploadTemplateList = (pageNumber, business_branch_id) => {
    const [uploadTemplateList, setUploadTemplateList] = useState([]);
	const [isLoadingUploadTemplateList, setLoadingUploadTemplateList] = useState(true);
	const [error, setError] = useState(false);
	const [supplier, setSupplier] = useState('all');
	const [filter, setFilter] = useState({start:moment().startOf('month').format("YYYY-MM-DD"), end:moment().endOf('month').format("YYYY-MM-DD")});


	let urlString = `/uploads/templates?business_branch=${business_branch_id}&start=${filter.start}&end=${filter.end}`;
	

	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(urlString);
				setUploadTemplateList(response.data.data);
				setLoadingUploadTemplateList(false);
			} catch (e) {
				setError(true);
				setLoadingUploadTemplateList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingUploadTemplateList, supplier, filter ]);
	return { uploadTemplateList, isLoadingUploadTemplateList, error, setSupplier, setFilter };
};

const useGetProductUploadList = (business_branch_id) => {
    const [productUploadList, setProductUploadList] = useState([]);
	const [isLoadingProductUploadList, setLoadingProductUploadList] = useState(true);
	const [errorProductUploadList, setErrorProductUploadList] = useState(false);
	const [newProductUploadData, setNewProductUploadData] = useState(true);

	const url = `/uploads/products?business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setProductUploadList(response.data.data);
				setLoadingProductUploadList(false);
				setNewProductUploadData(false);
				setErrorProductUploadList(false);
			} catch (e) {
				setErrorProductUploadList(true);
				setLoadingProductUploadList(false);
			}
		};
		fetchData();
	}, [newProductUploadData]);
	return { productUploadList, isLoadingProductUploadList, setNewProductUploadData, errorProductUploadList };
};


const useGetInitalStockUploadList = (business_branch_id) => {
    const [initalStockUploadList, setInitalStockUploadList] = useState([]);
	const [isLoadingInitalStockUploadList, setLoadingInitalStockUploadList] = useState(true);
	const [errorInitalStockUploadList, setErrorInitalStockUploadList] = useState(false);
	const [newInitalStockUploadData, setNewInitalStockUploadData] = useState(true);

	const url = `/uploads/stock?business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setInitalStockUploadList(response.data.data);
				setLoadingInitalStockUploadList(false);
				setNewInitalStockUploadData(false);
				setErrorInitalStockUploadList(false);
			} catch (e) {
				setErrorInitalStockUploadList(true);
				setLoadingInitalStockUploadList(false);
			}
		};
		fetchData();
	}, [newInitalStockUploadData]);
	return { initalStockUploadList, isLoadingInitalStockUploadList, setNewInitalStockUploadData, errorInitalStockUploadList };
};


const useGetStockTakeUploadList = (business_branch_id) => {
    const [stockTakeUploadList, setStockTakeUploadList] = useState([]);
	const [isLoadingStockTakeUploadList, setLoadingStockTakeUploadList] = useState(true);
	const [errorStockTakeUploadList, setErrorStockTakeUploadList] = useState(false);
	const [newStockTakeUploadData, setNewStockTakeUploadData] = useState(true);

	const url = `/uploads/stock?business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setStockTakeUploadList(response.data.data);
				setLoadingStockTakeUploadList(false);
				setNewStockTakeUploadData(false);
				setErrorStockTakeUploadList(false);
			} catch (e) {
				setErrorStockTakeUploadList(true);
				setLoadingStockTakeUploadList(false);
			}
		};
		fetchData();
	}, [newStockTakeUploadData]);
	return { stockTakeUploadList, isLoadingStockTakeUploadList, setNewStockTakeUploadData, errorStockTakeUploadList };
};


export { useUploadTemplateList, useGetProductUploadList, useGetInitalStockUploadList, useGetStockTakeUploadList }