import {Box,Grid,Paper,Alert,useTheme, useMediaQuery, Breadcrumbs,Link,Typography, Step, Stepper, StepLabel, Button, TextField, FormControlLabel, Checkbox} from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import requester from "api/pesabackend";
import { useSelector } from "react-redux";

const userGroupSchema = yup.object().shape({
  role_name: yup.string().required("required"),
  description: yup.string()
});


const UserGroupCreateForm = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [error, setError] = useState(false);
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);

  React.useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  
  const initialValuesUserGroup = {
    role_name: '',
    description:'',
  };

  const fetchUserGroupPermissionList = async (group_id) => {
    try {
        const response = await requester.get(`/human_resource/employee_roles/${group_id}`);
        if (response.status === 200) {
            navigate("/userGroupPermissions", { state: response.data.data });
          } 
    } catch (e) {
        setError(true);
    }
  }

  const saveUserGroup = async (values, onSubmitProps) => {
    values.related_business = business_id
    values.related_business_branch = business_branch_id

    const savedUserGroupResponse = await requester.post(`/human_resource/employee_roles`, JSON.stringify(values), 
    {
      body: JSON.stringify(values),
    });

    if (savedUserGroupResponse.data.status === 201) {
      onSubmitProps.resetForm();
      setIsSuccess(true);
      fetchUserGroupPermissionList(savedUserGroupResponse.data.data.id);
    }
  };

  const handleUserGroupFormSubmit = async (values, onSubmitProps) => {
    saveUserGroup(values, onSubmitProps);
  };


  return (
    <Box>
      { 
        isSuccess ? ( 
            <Alert  sx={{ m:"15px" }}variant="filled" severity="success">Operation was successful!</Alert>
        ) : isFailed ? (
            <Alert  sx={{ m:"15px" }}severity="error">Operation was not successful!</Alert>
        ) : null 
      }

      <Box
        sx={{
          padding: "20px",
          marginX: "20px",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => navigate("/userManagement")}
          >
            <ManageAccountsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            User Management
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            Create User Group
          </Typography>
        </Breadcrumbs>
        

        <Paper
          sx={{ width: "100%", padding: "40px", margin: "20px", justifyContent: "center" }}
          elevation={3}
        >
              <Typography sx={{ mt: 2, mb: 1 }}>Create New User Group</Typography>
              <Formik
                onSubmit={handleUserGroupFormSubmit}
                initialValues={initialValuesUserGroup}
                validationSchema={userGroupSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }, 
                      }}
                    >
                      <TextField
                        label="User Group Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.role_name}
                        name="role_name"
                        error={Boolean(touched.role_name) && Boolean(errors.role_name)}
                        helperText={touched.role_name && errors.role_name}
                        sx={{ gridColumn: "span 3" }}
                      />
                      <TextField
                        label="Description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        multiline
                        rows={2}
                        name="description"
                        error={Boolean(touched.description) && Boolean(errors.description)}
                        helperText={touched.description && errors.description}
                        sx={{ gridColumn: "span 3" }}
                      />
                    </Box>
          
                    {/* BUTTONS */}
                    <Box display="grid" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                      <Button
                        fullWidth
                        type="submit"
                        sx={{
                          m: "1rem 0",
                          p: "1rem",
                          backgroundColor: palette.primary.main,
                          color: palette.background.alt,
                          "&:hover": { color: palette.primary.main },
                          gridColumn: "span 3"
                        }}
                        // onClick={handleNext}
                      >
                        SAVE
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  onClick={() => navigate("/userManagement")}
                  variant="contained"
                  color="error"
                  size="small"
                  sx={{
                    p: "0.5rem",
                  }}
                >
                  Cancel
                </Button>
              </Box>
          
        </Paper>
      </Box>
    </Box>
  );
};

export default UserGroupCreateForm;
