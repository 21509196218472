import {Box,Button,TextField,useMediaQuery,MenuItem,useTheme,Alert,Divider,Breadcrumbs,Link,Typography,Paper,Stack, Grid} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {  FlexCenter } from "components/FlexBox";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";
import { useState, useEffect } from "react";
import { useGetPaymentTypesList } from "api/hooks/billing";
import RefundDetailForm from "./refundDetailForm";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

const detailsText = {
  fontSize: "16px",
  fontWeight: 600,
  padding: "5px",
};

const descriptionText = {
  fontSize: "16px",
  fontWeight: 400,
};

const RefundForm = () => {
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const token = useSelector((state) => state.token);
  const [invoiceSelect, setInvoiceSelect] = useState(true);
  const [settlementType, setSettlementType] = useState('');
  const {paymentTypesList} = useGetPaymentTypesList(1, business_branch_id)
  const invoiceDetails= location.state.data;
  const products= location.state.data.products;
  const [loadingInvoiceDetails, setLoadingInvoiceDetails] = useState(true);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  const initialValuesPayslip = {
    related_receipt: location.state.data.receipt.id,
    related_payment_type: "",
    payment_period:1,
    settlement_type: "",
    refunded_amount: 0,
    partially_refunded:false,
    description: "",
    refunded_products: products,
  };

  const [totalReturn, setTotalReturn] = useState(0);


  const [returnDetailFormData, setReturnDetailFormData] = useState(initialValuesPayslip.refunded_products);


  const returnSchema = yup.object().shape({
    related_receipt: yup.number().required("required"),
    refunded_amount: yup.number().required("required").min(1, "Total refund amount must be at least 1"),
    settlement_type: yup.number().required("required"),
    related_payment_type: yup.number().when('settlement_type', {is: 1, then:yup.number().required("required")}),
    payment_period: yup.number().when('settlement_type', {is: 2, then:yup.number().required("required")}),
    partially_refunded: yup.boolean(),
    description: yup.string().required("required"),
    refunded_products: yup.array().of(
      yup.object().shape({
        related_invoice_product: yup.number(),
        stock_returned: yup.boolean().required('required'),
        stock_condition: yup.boolean(),
        stock_condition_description: yup.boolean(),
        quantity_returned: yup.number().min(1, "A return must be at least 1"),
        return_amount: yup.number(),
      })
    ),
  });

 

  const savePayslip = async (values, onSubmitProps) => {
    values.related_business = business_id;
    values.related_business_branch = business_branch_id;

    const savedItemResponse = await requester.post(
      `/billing/refunds`,
      JSON.stringify(values),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (savedItemResponse.data.status === 201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();
        navigate("/billing");
      }, 1500);
    }

    if (savedItemResponse.data.status === 400) {
      setIsFailed(true);
      setIsFailedError(savedItemResponse.data.errors);
    }
  };
  

  const handleTotalReturn = (newArray) => {
    const totalReturnCalculation = newArray.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(currentValue.return_amount);
    }, 0);
    setTotalReturn(totalReturnCalculation);
  };


  const handleReturnAmountChange = (event, index) => {
    const newArray = returnDetailFormData.map((item, idx) => {
      if (idx === index) {
        return { ...item, return_amount: Number(event) };
      }
      return item;
    });
    setReturnDetailFormData(newArray);
    handleTotalReturn(newArray);
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await savePayslip(values, onSubmitProps);
  };

  return (
    <Box>
      {isSuccess ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="success">
          Payslip Invoice recorded successfully!
        </Alert>
      ) : isFailed ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="error">
          Operation was not successful!
        </Alert>
      ) : null}

      <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/billing")}
        >
          <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Sales
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <b>Record Customer Refund Form</b>
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ p: "10px" }}>
        <Box>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesPayslip}
            validationSchema={returnSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              resetForm,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
                    <Grid container spacing={2} marginBottom={"5px"}>
                      <Grid xs={12} sm={6}>

                        <Stack spacing={2}>
                          <FlexCenter>
                            {/* <ChairOutlinedIcon sx={facilityIcons} /> */}
                            <Typography sx={descriptionText}>Receipt Number: {invoiceDetails.receipt.receipt_number}</Typography>
                            <Typography sx={detailsText}></Typography>
                          </FlexCenter>
                          <FlexCenter>
                            {/* <BedOutlinedIcon sx={facilityIcons} /> */}
                            <Typography sx={descriptionText}>Invoice Description: {invoiceDetails.invoice_description}</Typography>
                            <Typography sx={detailsText}></Typography>
                          </FlexCenter>
                          <FlexCenter>
                            {/* <BedOutlinedIcon sx={facilityIcons} /> */}
                            <Typography sx={descriptionText}>Customer: {invoiceDetails.related_customer? invoiceDetails.related_customer.related_user.first_name+' '+invoiceDetails.related_customer.related_user.last_name : null}</Typography>
                            <Typography sx={detailsText}></Typography>
                          </FlexCenter>
                          <FlexCenter>
                            <>
                            {/* <ShowerOutlinedIcon sx={facilityIcons} /> */}
                            {!loadingInvoiceDetails && (
                              <Typography sx={descriptionText}>Created By: {`${invoiceDetails.created_by.first_name} ${invoiceDetails.created_by.last_name}`}</Typography>
                            )}
                            <Typography sx={detailsText}></Typography>
                            </>
                          </FlexCenter>
                        </Stack>
                      </Grid>
                      <Grid xs={12} sm={6}>

                        <Stack spacing={2}>
                          <FlexCenter>
                            {/* <ChairOutlinedIcon sx={facilityIcons} /> */}
                            <Typography sx={descriptionText}>Total Amount: {currencyFormatter(parseFloat(invoiceDetails.total_amount))}</Typography>
                            <Typography sx={detailsText}></Typography>
                          </FlexCenter>
                          {/* <FlexCenter>
                            <BedOutlinedIcon sx={facilityIcons} />
                            <Typography sx={descriptionText}>Total Discount: {invoiceDetails.total_discount}</Typography>
                            <Typography sx={detailsText}></Typography>
                          </FlexCenter> */}
                          <FlexCenter>
                            {/* <ChairOutlinedIcon sx={facilityIcons} /> */}
                            <Typography sx={descriptionText}>Amount Tendered: {invoiceDetails.receipt.amount_tendered}</Typography>
                            <Typography sx={detailsText}></Typography>
                          </FlexCenter>
                          <FlexCenter>
                            {/* <ChairOutlinedIcon sx={facilityIcons} /> */}
                            <Typography sx={descriptionText}>Balance: {invoiceDetails.receipt.balance}</Typography>
                            <Typography sx={detailsText}></Typography>
                          </FlexCenter>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                {invoiceSelect ? (
                  <>
                    <Box
                      gridColumn="span 4"
                      border={`1px solid ${palette.neutral.medium}`}
                      borderRadius="2px"
                      p="0.5rem"
                      my="10px"
                    >
                      <TextField
                        label="Reason for Refund"
                        size="small"
                        multiline
                        rows={2}
                        name="description"
                        value={values.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        sx={{width:"50%", mx:"20px"}}
                        error={Boolean(touched.description) && Boolean(errors.description)}
                        helperText={touched.description && errors.description}
                      />
                      <h4>Select the Payment Terms Here</h4>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          // m: "4px",
                        }}
                      >
                        
                        <TextField
                          label="REFUND PAYMENT PROCEDURE"
                          select
                          onBlur={handleBlur}
                          defaultValue={1}
                          name="settlement_type"
                          value={values.settlement_type}
                          size="small"
                          onChange={(e)=>{
                            setSettlementType(e.target.value);
                            handleChange(e)
                          }}
                          error={Boolean(touched.settlement_type) && Boolean(errors.settlement_type)}
                          helperText={touched.settlement_type && errors.settlement_type}
                          sx={{width:"40%", mx:"20px"}}
                        >
                          <MenuItem value={1}>CASH</MenuItem>
                          {invoiceDetails.related_customer?<MenuItem value={2}>CREDIT</MenuItem> : null}
                        </TextField>
                        
                        {settlementType === 1 ? (
                          
                          <TextField
                              label="Payment Type"
                              size="small"
                              select
                              name="related_payment_type"
                              value={values.related_payment_type}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              sx={{width:"30%", mx:"5px"}}
                              error={Boolean(touched.related_payment_type) && Boolean(errors.related_payment_type)}
                              helperText={touched.related_payment_type && errors.related_payment_type}
                            >
                              {paymentTypesList.map((item, index)=>{
                                return(<MenuItem key={index} value={item.id}>{item.payment_type_name}</MenuItem>);
                              })}
                            </TextField>
                            
                        ) : settlementType === 2 ? (
                          
                          <TextField
                              label="Payment Period in days"
                              size="small"
                              type="number"
                              name="payment_period"
                              value={values.payment_period}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              sx={{width:"30%", mx:"5px"}}
                              error={Boolean(touched.payment_period) && Boolean(errors.payment_period)}
                              helperText={touched.payment_period && errors.payment_period}
                              inputProps={{
                                min: 1,
                              }}
                            />
                        ) : null }
                      </Box>
                    </Box>
                    <Box
                      gridColumn="span 4"
                      border={`1px solid ${palette.neutral.medium}`}
                      borderRadius="2px"
                      p="0.5rem"
                      my="10px"
                    >
                      <h4>Input the Refund Details here</h4>
                      <p style={{ color: "red" }}>* Select the checkbox for the product and batch you would like to return</p>

                      <FieldArray
                        name="refunded_products"
                        render={(arrayHelpers) => {
                          const refunded_products = values.refunded_products;

                          return (
                            <>
                              {refunded_products.map((row, index) => {
                                return (
                                  <RefundDetailForm
                                    index={index}
                                    data={row}
                                    handleChange={handleChange}
                                    handleReturnAmountChange={
                                      handleReturnAmountChange
                                    }
                                    errors={arrayHelpers.form.errors}
                                    touched={arrayHelpers.form.touched}
                                    name={`refunded_products[${index}]`}
                                  />
                                );
                              })}
                            </>
                          );
                        }}
                      />
                    </Box>

                    <Box
                      border={`1px solid ${palette.neutral.medium}`}
                      borderRadius="2px"
                      p="0.5rem"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mx: "2px",
                        }}
                      >
                        <label>Total Refund Amount: </label>
                        <TextField
                          label=""
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={(values.refunded_amount = totalReturn)}
                          name="refunded_amount"
                          disabled
                          size="small"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          error={
                            Boolean(touched.refunded_amount) &&
                            Boolean(errors.refunded_amount)
                          }
                          helperText={
                            touched.refunded_amount && errors.refunded_amount
                          }
                          sx={{
                            // width: "100%",
                            marginBottom: "15px",
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                ) : (
                  <h3
                    style={{
                      textAlign: "center",
                      color: "red",
                      margin: "40px 0px",
                    }}
                  >
                    PLEASE INPUT THE RECEIPT NUMBER FOR THE REUFND
                    YOU WANT TO RECORD.
                  </h3>
                )}

                {/* BUTTONS */}
                <Box>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      m: "1rem",
                      p: "0.5rem",
                    }}
                  >
                    Save Refund
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      navigate(`/billing`);
                    }}
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{
                      m: "1rem",
                      p: "0.5rem",
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
    </Box>
  );
};

export default RefundForm;
