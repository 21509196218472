import { Box, Grid, Paper, Breadcrumbs, Link, Typography, MenuItem, TextField, Button, Autocomplete } from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useProductLogList } from "api/hooks/inventory";
import { useSelector } from "react-redux";
import {useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import moment from "moment";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import requester from "api/pesabackend"; 
import { useLocation, useNavigate } from "react-router-dom";


export default function ProductLog () {
  const location = useLocation()
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const navigate = useNavigate()
  const [showFiter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().endOf("month").format("YYYY-MM-DD"));
	const [product, setProduct] = useState(location.state.productList.length > 0 ?  location.state.productList[0].id : "");
  const [productList, setProductList] = useState(location.state.productList);
  const [productListError, setProductListError] = useState(false);

  const {productLogList, isLoadingProductLogList,  setAction, setFilter} = useProductLogList(1, business_branch_id, product)

  const [isSelectedProduct, setIsSelectedProduct] = useState(false);

  const fetchItemList = async (search) => {
    try {
      if (search) {
        if (search.length >= 3) {
          const response = await requester.get(
            `/inventory/products/list?business_branch=${business_branch_id}&search=${search}&typeFilter=all`
          );
          setProductList(response.data.data);
        }
      } else {
        // const response = await requester.get(
        //   `/inventory/products/list?business_branch=${business_branch_id}&typeFilter=all`
        // );
        // setProductList(response.data.data);
      }
    } catch (e) {
      setProductListError(true);
    }
  };

  const onChangeAutoComplete = async (value) => {
    if (value) {
      setIsSelectedProduct(true);
      setProduct(value.id);
    } else {
      setIsSelectedProduct(false);
    }
  };


  const handleChangePeriodFilter = async (e) => {
    if (e.target.value === "THIS MONTH") {
      setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "THIS WEEK") {
      setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "TODAY") {
      setStartDate(moment().format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
      setShowFilter(false);
    }
    if (e.target.value === "CUSTOM") {
      setShowFilter(true);
    }
  };

  const handleClickFilter = () => {
    setFilter({ start: startDate, end: endDate });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    
  return (
    <Box>
      <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/inventory")}
        >
          <InventoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Inventory
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <b>Item Logs</b>
        </Typography>
      </Breadcrumbs>
      <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={3}>
            <Paper
              sx={{
                m: "10px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <Autocomplete
                size="small"
                onOpen={() => {
                  fetchItemList(null);
                }}
                sx={{ width: "100%" }}
                defaultValue={productList[0]}
                onChange={(e, value) => {
                  console.log(value);
                  onChangeAutoComplete(value);
                }}
                options={productList}
                getOptionLabel={(option) =>
                  `${option.product_name}  ${option.description}`
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
                renderOption={(props, productList) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={productList.id}
                  >
                    {productList.product_name} {productList.description}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    label="Select Product"
                    onChange={(e) => {
                      fetchItemList(e.currentTarget.value);
                    }}
                  />
                )}
              />
            </Paper>
          </Grid>
          <Grid xs={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>FILTER BY ACTION:</h4>
              <TextField
                label={"ACTION"}
                defaultValue={"all"}
                select
                size="small"
                onChange={(e) => setAction(e.target.value)}
                sx={{
                  minWidth: 50,
                  maxWidth: 300,
                  m: "10px",
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="all">ALL</MenuItem>
                <MenuItem value="PURCHASE">PURCHASE</MenuItem>
                <MenuItem value="SALE">SALE</MenuItem>
                <MenuItem value="ADJUSTMENT">ADJUSTMENT</MenuItem>
                <MenuItem value="RETURN">RETURN</MenuItem>
              </TextField>
            </Box>
          </Grid>
          <Grid xs={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // m: "4px",
              }}
            >
              <h4>REPORT PERIOD:</h4>

              <TextField
                label="PERIOD"
                select
                defaultValue="THIS MONTH"
                size="small"
                onChange={(e) => {
                  handleChangePeriodFilter(e);
                }}
                sx={{
                  minWidth: 50,
                  maxWidth: 300,
                  m: "10px",
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="THIS WEEK">THIS WEEK</MenuItem>
                <MenuItem value="TODAY">TODAY</MenuItem>
                <MenuItem value="THIS MONTH">THIS MONTH</MenuItem>
                <MenuItem value="CUSTOM">CUSTOM</MenuItem>
              </TextField>

              {showFiter ? (
                <>
                  <TextField
                    label="Start Date"
                    type="date"
                    defaultValue={startDate}
                    size="small"
                    onChange={(e) => setStartDate(e.target.value)}
                    sx={{
                      m: "10px",
                      backgroundColor: "white",
                    }}
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    defaultValue={endDate}
                    size="small"
                    onChange={(e) => setEndDate(e.target.value)}
                    sx={{
                      m: "10px",
                      backgroundColor: "white",
                    }}
                  />
                </>
              ) : null}
              <Button
                variant="contained"
                size="small"
                sx={{
                  m: "9px",
                }}
                onClick={() => handleClickFilter()}
              >
                FILTER
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell
                    style={{ minWidth: 50, padding: "19px 6px" }}
                  >
                    Date Carried Out
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ minWidth: 150, padding: "19px 6px" }}
                  >
                    Item Name
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ minWidth: 150, padding: "19px 6px" }}
                  >
                    Description
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "19px 6px" }}>
                    Action
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "19px 6px" }}>
                    Transaction No.
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "19px 6px" }}>
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "19px 6px" }}>
                    Batch No.
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "19px 6px" }}>
                    Cost
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "19px 6px" }}>
                    Done By
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {productLogList.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell
                      colSpan={12}
                      style={{ textAlign: "center" }}
                    >
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  productLogList
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      return (
                        <StyledTableRow>
                          <StyledTableCell style={{ padding: "19px 6px" }}>
                            {moment(row.date_added).format("LLL")}
                          </StyledTableCell>
                          <StyledTableCell style={{ padding: "19px 6px" }}>
                            {row.related_product.product_name}
                          </StyledTableCell>
                          <StyledTableCell style={{ padding: "19px 6px" }}>
                            {row.related_product.description}
                          </StyledTableCell>
                          <StyledTableCell style={{ padding: "19px 6px" }}>
                            {row.action}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ padding: "19px 6px" }}
                          ></StyledTableCell>
                          <StyledTableCell style={{ padding: "19px 6px" }}>
                            {row.quantity}
                          </StyledTableCell>
                          <StyledTableCell style={{ padding: "19px 6px" }}>
                            {row.related_batch}
                          </StyledTableCell>
                          <StyledTableCell style={{ padding: "19px 6px" }}>
                            {row.cost}
                          </StyledTableCell>
                          <StyledTableCell style={{ padding: "19px 6px" }}>
                            {row.added_by.first_name}{" "}
                            {row.added_by.last_name}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={productLogList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};