import React, { useState } from "react";
import {Box,Breadcrumbs,Link,Typography,Paper,} from "@mui/material";
import { useSelector } from "react-redux";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {FlexBetween,FlexEnd,} from "components/FlexBox";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {StyledTableCell,StyledTableRow,} from "components/Tables/TableCellandRow";
import { useGetBalanceSheet } from "api/hooks/accounting";
import {  currencyOneSignficantDigitFormatter, withoutSymbolOneSignificantDigitFormatter } from "utilities/currencyFormatter"; 

const BalanceSheetView = () => {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);

  const {balanceSheet, isLoadingBalanceSheet} = useGetBalanceSheet(business_branch_id);
  
  const assetData = balanceSheet ? balanceSheet.Assets : {child_ledgers:[], ledger_total: 0};
  const liabilityData = balanceSheet ? balanceSheet.Liabilities : {child_ledgers:[], ledger_total: 0};
  const equityData = balanceSheet ? balanceSheet.Equity : {child_ledgers:[], ledger_total: 0};
  const totalEquityAndLiabilities = balanceSheet ? balanceSheet.total_equity_and_liabilities : 0;
  

  return (
    <Box>
        <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => navigate("/accounting")}
          >
            <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Accounting
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <b>Balance Sheet</b>
          </Typography>
        </Breadcrumbs>
        <Box sx={{display: "flex", width:"100%", justifyContent:"center" }}>
          <Paper sx={{ p: "10px", display: "flex", flexDirection:"column", margin:"10px 0", width:"210mm", }}>
          <Typography variant="h1" sx={{ justifyContent:"flex-start", padding:"6px" }}> Balance Sheet</Typography>
          <FlexEnd> <Typography variant="h3"> As of</Typography>  </FlexEnd>
          

          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      backgroundColor: "white",
                      padding: "6px 6px",
                      margin:"5px",
                      borderBottom: "2px solid",
                    }}
                    colSpan={12}
                  >
                    <FlexBetween><Typography variant="h4">ASSETS</Typography></FlexBetween>
                  </StyledTableCell>
                </StyledTableRow>

                {/* asset row */}
                <StyledTableRow>
                  <StyledTableCell colSpan={12}>
                    <Box width={"100%"} padding={"5px"} margin={"5px"}>
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          maxHeight="100vh"
                        >
                          <TableBody>
                            {assetData.child_ledgers.length === 0 ? (
                              <></>
                            ) : (
                              assetData.child_ledgers.map((row, index)=>{
                                return(
                                  <StyledTableRow>
                                    <StyledTableCell style={{padding: "1px 1px",border: "1px",}} colSpan={12}>
                                      <FlexBetween><Typography variant="h6">{row.name}</Typography> {withoutSymbolOneSignificantDigitFormatter(parseFloat(row.amount))}</FlexBetween>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )
                            }))}
                            <StyledTableRow>
                              <StyledTableCell style={{padding: "1px 1px",borderBottom: "1px solid",}} colSpan={12}>
                                <FlexBetween> <Typography variant="h5 ">TOTAL ASSETS</Typography>{currencyOneSignficantDigitFormatter(parseFloat(assetData.ledger_total))}</FlexBetween>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>

                {/* liability row */}
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      backgroundColor: "white",
                      padding: "6px 6px",
                      margin:"5px",
                      borderBottom: "2px solid",
                    }}
                    colSpan={12}
                  >
                    <FlexBetween> <Typography variant="h4">LIABILITIES</Typography></FlexBetween>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={12}>
                    <Box width={"100%"} padding={"5px"} margin={"5px"}>
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          maxHeight="100vh"
                          size="small"
                        >
                          <TableBody>
                            {liabilityData.child_ledgers.length === 0 ? (
                              <></>
                            ) : (
                              liabilityData.child_ledgers.map((row, index)=>{
                                return(
                                  <StyledTableRow>
                                    <StyledTableCell style={{padding: "1px 0px", margin:"1px" ,border: "1px",}} colSpan={12}>
                                      <FlexBetween><Typography variant="h6">{row.name}</Typography>{withoutSymbolOneSignificantDigitFormatter(parseFloat(row.amount))}</FlexBetween>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )
                            }))}
                            <StyledTableRow>
                              <StyledTableCell style={{padding: "1px 1px",borderBottom: "1px solid",}} colSpan={12}>
                                <FlexBetween> <Typography variant="h5 ">TOTAL LIABILITIES</Typography> {currencyOneSignficantDigitFormatter(parseFloat(liabilityData.ledger_total))}</FlexBetween>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>

                {/* equity row */}
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      backgroundColor: "white",
                      padding: "6px 6px",
                      margin:"5px",
                      borderBottom: "2px solid",
                    }}
                    colSpan={12}
                  >
                    <FlexBetween><Typography variant="h4">EQUITY</Typography></FlexBetween>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={12}>
                    <Box width={"100%"} padding={"5px"} margin={"5px"}>
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          maxHeight="100vh"
                        >
                          <TableBody>
                            {equityData.child_ledgers.length === 0 ? (
                              <></>
                            ) : (
                              equityData.child_ledgers.map((row, index)=>{
                                return(
                                  <StyledTableRow>
                                    <StyledTableCell style={{padding: "1px 1px",border: "1px",}} colSpan={12}>
                                      <FlexBetween><Typography variant="h6">{row.name}</Typography>{withoutSymbolOneSignificantDigitFormatter(parseFloat(row.amount))}</FlexBetween>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )
                            }))}
                            <StyledTableRow>
                              <StyledTableCell style={{padding: "1px 1px",borderBottom: "1px solid",}} colSpan={12}>
                                <FlexBetween> <Typography variant="h5">TOTAL EQUITY</Typography> {currencyOneSignficantDigitFormatter(parseFloat(equityData.ledger_total))}</FlexBetween>
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell style={{padding: "1px 1px",borderBottom: "1px solid",}} colSpan={12}>
                                <FlexBetween> <Typography variant="h5">TOTAL LIABILITIS & EQUITY</Typography> {currencyOneSignficantDigitFormatter(parseFloat(totalEquityAndLiabilities))}</FlexBetween>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>

                {/* equity + laibility row */}

              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        </Box>
    </Box>
  );
};

export default BalanceSheetView;
