import {Box, Paper, Button} from "@mui/material";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useRef } from "react";
import TableHead from '@mui/material/TableHead';
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import RefundPdf from "./refundPdfView";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

const RefundViewForm = (props) => {

  const user = useSelector((state) => state.user);
  const { viewData, handleClickApprove } = props;
  const componentRef = useRef();

  const return_number = viewData.id;
  const description= viewData.description;
  const created_by = viewData.created_by
  const approvedBy = viewData.approved_by
  const requisitionStatus = viewData.status
  const customer = viewData.related_receipt.related_invoice.related_customer
  const grand_total= viewData.refunded_amount;
  const return_date = viewData.date_created;
  const refund_products=viewData.refund_products;
  const receipt_number = viewData.related_receipt.receipt_number;


  return (
    <>
      <Paper sx={{ margin: "20px" }} elevation={3}>
        <Box
          sx={{ padding: "20px", margin: "20px", width: "95%" }}
          ref={componentRef}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h1>REFUND RECEIPT</h1>
            <h2># {return_number}</h2>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Customer Information
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Name: {customer ? customer.related_user.first_name +' '+customer.related_user.last_name  : 'WALK-IN CUSTOMER'}
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Phone: {customer ? customer.related_user.phone_number:""}
              </p>

              <br />

              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Refund Date
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {moment(return_date).format("LL")}
              </p>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "right", margin: "0px", padding: "0px" }}>
                Refunded on Receipt:
              </h4>
              <p style={{ textAlign: "right", margin: "0px", padding: "0px" }}>
                {receipt_number}
              </p>
            </Box>
          </Box>
          <Box>
            <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              Refund Details
            </h4>
            <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              {description}
            </p>
            <br />
          </Box>
          <hr />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Table>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: "lightgrey" }}>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                      width: 25,
                    }}
                  >
                    #
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Description
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    UOM
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Purchase Amount
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!refund_products ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  refund_products.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell style={{   padding: "6px 6px",   border: "1px solid", }}> {index + 1}</StyledTableCell>
                        <StyledTableCell  style={{   padding: "6px 6px",   border: "1px solid", }}> {row.related_invoice_product.related_product.product_name}</StyledTableCell>
                        <StyledTableCell  style={{   padding: "6px 6px",   border: "1px solid", }}> {row.related_invoice_product.related_product_unit.related_business_product_unit.name}</StyledTableCell>
                        <StyledTableCell  style={{   padding: "6px 6px",   border: "1px solid", }}> {row.related_invoice_product.quantity}</StyledTableCell>
                        <StyledTableCell  style={{   padding: "6px 6px",   border: "1px solid", }}> {withoutSymbolFormatter(parseFloat(row.related_invoice_product.total))}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>TOTAL</b>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>{currencyFormatter(parseFloat(grand_total))}</b>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={3}>
                    <b>CREATED BY: {created_by.first_name} {created_by.last_name}</b>
                  </StyledTableCell>
                  <StyledTableCell colSpan={3}>
                    {/* APPROVED BY{" "}
                    {approvedBy
                      ? approvedBy.first_name + " " + approvedBy.last_name
                      : null} */}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
              {/* <TableFooter>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    TOTAL
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    {grand_total}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={3}>
                    CREATED BY {created_by.first_name} {created_by.last_name}
                  </StyledTableCell>
                  <StyledTableCell colSpan={3}>
                    APPROVED BY{" "}
                    {approvedBy
                      ? approvedBy.first_name + " " + approvedBy.last_name
                      : null}
                  </StyledTableCell>
                </StyledTableRow>
              </TableFooter> */}
            </Table>
          </Box>
          <br />
        </Box>
      </Paper>
      {/*<ReactToPrint
        documentTitle={`Purchase Requisition #${requisition_number}`}
        trigger={() => (
          <Button
            size="small"
            variant="contained"
            sx={{
              width: "20%",
              m: "15px",
            }}
          >
            Print
          </Button>
        )}
        content={() => componentRef.current}
      /> */}
      <PDFDownloadLink document={<RefundPdf viewData={viewData}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Customer Refund #${return_number}`}>
        <Button
          size="small"
          variant="contained"
          sx={{
            width: "20%",
            my: "15px",
            mx: "5px",
          }}
        >
          Download
        </Button>
      </PDFDownloadLink>
      {requisitionStatus === 0 ? (
        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={handleClickApprove}
          sx={{
            width: "20%",
            m: "15px",
          }}
        >
          APPROVE
        </Button>
      ) : null}
    </>
  );

}

export default RefundViewForm;