import {Box,Button,TextField,useMediaQuery,MenuItem,Autocomplete,Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {useState} from "react";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";

const RefundDetailForm = (props) => {
  const {
    index,
    data,
    handleReturnQuantityChange,
    handleReturnAmountChange,
    name,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  } = props;

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [error, setError] = useState(false);
  const [productList, setProductList] = useState(data.product_info ? [data.product_info] : []);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const [unitsForGivenItemList, setUnitsForGivenItemList] = useState(
    data.product_units_list ? data.product_units_list : []
  );

  const fetchUnitsForGivenItemList = async (productId) => {
    try {
      const response = await requester.get(
        `/inventory/products/unit_list/${productId}`
      );
      setUnitsForGivenItemList(response.data.data);
    } catch (e) {
      setError(true);
    }
  };

  const fetchItemList = async (search) => {
    try {
      if (search) {
        const response = await requester.get(
          `/inventory/products/list?business_branch=${business_branch_id}&search=${search}&typeFilter=all`
        );
        setProductList(response.data.data);
      } else {
        const response = await requester.get(
          `/inventory/products/list?business_branch=${business_branch_id}&typeFilter=all`
        );
        setProductList(response.data.data);
      }
    } catch (e) {
      setError(true);
    }
  };

  const [totalCalculation, setTotalCalculation] = useState(0)
  data.return_amount = totalCalculation;
  data.related_invoice_product=data.id;
  const handleTotal = (e) =>{
    setTotalCalculation(e);
    handleReturnAmountChange(e, index);
  }

  const [makeReturn, setMakeReturn] = useState(true);

  const handleMakeReturn = () => {
    setMakeReturn(!makeReturn);
    console.log(data.total)
    if (makeReturn) {
      handleTotal(data.total)
    } else {
      handleTotal(0);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box>
        <p>#{index + 1}</p>
      </Box>
      <Box
        display="grid"
        gap="5px"
        p="0.5rem"
        gridTemplateColumns="repeat(20, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
        }}
      >
        <>
          <p
            hidden
            name={`${name}.related_purchase_detail`}
            value={data.id}
          ></p>

          <Autocomplete
            size="small"
            disabled
            onOpen={() => {
              fetchItemList(null);
            }}
            name={`${name}.related_product`}
            onChange={(e, value) => {
              if (value) {
                fetchUnitsForGivenItemList(value.id);
              }
              setFieldValue(`${name}.related_product`, value ? value.id : "");
            }}
            sx={{ gridColumn: "span 4" }}
            options={productList}
            defaultValue={productList[0]}
            getOptionLabel={(option) =>
              `${option.product_name}  ${option.description}`
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, productList) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
                key={productList.id}
              >
                {productList.product_name} {productList.description}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                }}
                label="Item Name"
                value={data.related_product ? data.related_product ?? null : ""}
                error={
                  Boolean(
                    touched?.return_detail &&
                      touched?.return_detail[index]?.related_product
                  ) &&
                  Boolean(
                    errors?.return_detail &&
                      errors?.return_detail[index]?.related_product
                  )
                }
                helperText={
                  touched?.return_detail &&
                  touched?.return_detail[index]?.related_product &&
                  errors?.return_detail &&
                  errors?.return_detail[index]?.related_product
                }
                onBlur={handleBlur}
                onChange={(e) => {
                  fetchItemList(e.currentTarget.value);
                }}
              />
            )}
          />

          <TextField
            label="Batch"
            size="small"
            disabled
            onBlur={handleBlur}
            onChange={handleChange}
            value={data.batch}
            name={`${name}.batch`}
            error={
              Boolean(
                touched?.return_detail && touched?.return_detail[index]?.batch
              ) &&
              Boolean(
                errors?.return_detail && errors?.return_detail[index]?.batch
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.batch &&
              errors?.return_detail &&
              errors?.return_detail[index]?.batch
            }
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            label="Quantity Sold"
            size="small"
            disabled
            type="number"
            onBlur={handleBlur}
            onChange={handleChange}
            value={data.quantity}
            inputProps={{
              min: 0,
            }}
            name={`${name}.quantity`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.quantity
              ) &&
              Boolean(
                errors?.return_detail && errors?.return_detail[index]?.quantity
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.quantity &&
              errors?.return_detail &&
              errors?.return_detail[index]?.quantity
            }
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            label="Unit Cost"
            size="small"
            disabled
            type="number"
            onBlur={handleBlur}
            onChange={handleChange}
            value={data.sale_price}
            inputProps={{
              min: 0,
            }}
            name={`${name}.sale_price`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.sale_price
              ) &&
              Boolean(
                errors?.return_detail &&
                  errors?.return_detail[index]?.sale_price
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.sale_price &&
              errors?.return_detail &&
              errors?.return_detail[index]?.sale_price
            }
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            label="Item Unit"
            size="small"
            disabled
            onBlur={handleBlur}
            onChange={handleChange}
            select
            value={data.related_product_unit}
            name={`${name}.related_product_unit`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.related_product_unit
              ) &&
              Boolean(
                errors?.return_detail &&
                  errors?.return_detail[index]?.related_product_unit
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.related_product_unit &&
              errors?.return_detail &&
              errors?.return_detail[index]?.related_product_unit
            }
            sx={{ gridColumn: "span 2" }}
          >
            {unitsForGivenItemList.map((oneUnit, index) => {
              return (
                <MenuItem
                  key={index}
                  value={oneUnit.id}
                  selected={
                    oneUnit.id === data.related_product_unit ? true : false
                  }
                >
                  {oneUnit.related_business_product_unit.name}
                </MenuItem>
              );
            })}
          </TextField>

          {/* <TextField
            label="Quantity Returned"
            size="small"
            disabled={makeReturn}
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              handleTotal(e);
            }}
            value={data.quantity_returned}
            type="number"
            defaultValue={0}
            inputProps={{
              min: 0,
              max: data.quantity,
            }}
            name={`${name}.quantity_returned`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.quantity_returned
              ) &&
              Boolean(
                errors?.return_detail &&
                  errors?.return_detail[index]?.quantity_returned
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.quantity_returned &&
              errors?.return_detail &&
              errors?.return_detail[index]?.quantity_returned
            }
            sx={{ gridColumn: "span 2" }}
          /> */}

          <TextField
            label="Refund Amount"
            size="small"
            onBlur={handleBlur}
            disabled
            onChange={handleChange}
            value={(data.total)}
            type="number"
            name={`${name}.total`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.total
              ) &&
              Boolean(
                errors?.return_detail &&
                  errors?.return_detail[index]?.total
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.total &&
              errors?.return_detail &&
              errors?.return_detail[index]?.total
            }
            sx={{ gridColumn: "span 2" }}
          />

          <TextField
            label="Stock Condition"
            size="small"
            select
            disabled={makeReturn}
            defaultValue={true}
            onBlur={handleBlur}
            onChange={handleChange}
            value={data.stock_condition}
            name={`${name}.stock_condition`}
            error={
              Boolean(
                touched?.return_detail &&
                  touched?.return_detail[index]?.stock_condition
              ) &&
              Boolean(
                errors?.return_detail &&
                  errors?.return_detail[index]?.stock_condition
              )
            }
            helperText={
              touched?.return_detail &&
              touched?.return_detail[index]?.stock_condition &&
              errors?.return_detail &&
              errors?.return_detail[index]?.stock_condition
            }
            sx={{ gridColumn: "span 2" }}
          >
            <MenuItem value={true}>GOOD</MenuItem>
            <MenuItem value={false}>POOR</MenuItem>
          </TextField>

          <Box sx={{ gridColumn: "span 2", alignContent: "center" }}>
            <label>Return</label>
            <Checkbox
              value={(data.stock_returned = !makeReturn)}
              name={`${name}.stock_returned`}
              onChange={(e) => {
                handleChange(e);
                handleMakeReturn();
              }}
              sx={{ margin: "0px", p: "0px 8px" }}
              color="success"
            />
          </Box>
        </>
      </Box>
    </Box>
  );
};

export default RefundDetailForm;
