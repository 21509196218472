import React, {useEffect} from "react";
import { Box, Alert  } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import ProductUploadsTable from "../tables/productUploadsTable";
import InitialStockTable from "../tables/initialStockTable";
import StockTakeUploadsTable from "../tables/stockTakeUploadsTable";
import CustomTab from "components/Tabs/Tab";

const UploadsTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['fileUploadTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "fileUploadTab", tab: newValue })); 
  };
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);

  const tabComponents = [
    {Component: <ProductUploadsTable setIsSuccess={setIsSuccess}/>, label: "PRODUCT UPLOADS", "index": 0},
    {Component: <StockTakeUploadsTable setIsSuccess={setIsSuccess}/>, label: "STOCK TAKE", "index": 1},
    {Component: <InitialStockTable setIsSuccess={setIsSuccess}/>, label: "INITIAL STOCK", "index": 2},
  ] 

  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  return (
    <Box>
      {
        isSuccess ? ( 
            <Alert  sx={{ m:"15px" }}variant="filled" severity="success">Operation was successful!</Alert>
        ) : isFailed ? (
            <Alert  sx={{ m:"15px" }}severity="error">Operation was not successful!</Alert>
        ) : null                                             
      }
      <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/>      
    </Box> 
  );
};

export default UploadsTab;