import {Box,Grid,Button,Paper,InputBase,MenuItem,TextField,Breadcrumbs,Link,Typography,useMediaQuery,Menu} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSupplierList } from "api/hooks/inventory";
import {  useItemBatchwiseStockReportList } from "api/hooks/reports";
import { useSelector } from "react-redux";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DownloadIcon from "@mui/icons-material/Download";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import BatchwiseItemStockReportPdf from "scenes/reports/pdfForms/inventory/batchwiseItemStockReportPdf";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

export default function BatchwiseItemStockReport({ setIsSuccess }) {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const user = useSelector((state) => state.user);
  const {itemBatchwiseStockReportList, isLoadingItemBatchwiseStockReportList, error, setSearch, setSupplier} = useItemBatchwiseStockReportList(1, business_branch_id);
  const { supplierList } = useSupplierList(1, business_branch_id);

  const [openExportOptions, setOpenExportOptions] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);

  const handleClickOpen = (event) => {
    setAnchorE1(event.currentTarget);
    setOpenExportOptions(true);
  };

  const handleClose = () => {
    setOpenExportOptions(false);
  };

  const handleChangeSupplierFilter = async (e) => {
    setSupplier(e.target.value);
  };

  const [searchContent, setSearchContent] = useState("");

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value);
  };

  const handleClickSearch = async () => {
    setSearch(searchContent);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/reports")}
        >
          <AssessmentIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Reports
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <b>Batchwise Item Stock Report</b>
        </Typography>
      </Breadcrumbs>
      <Box>
        <Box p="1rem" m="1rem" borderRadius="0.5rem">
          <Grid container spacing={4} className="alignHorizantal">
            <Grid xs={4}>
              <Paper
                sx={{
                  my: "4px",
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  minWidth: 170,
                  maxWidth: 300,
                }}
              >
                <InputBase
                  placeholder="Search Item Name"
                  value={searchContent}
                  onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                />
                <IconButton onClick={handleClickSearch}>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>

            <Grid xs={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // m: "4px",
                }}
              >
                <h4>FILTER BY SUPPLIER:</h4>

                <Paper
                  sx={{
                    m: "10px",
                    display: "flex",
                    alignItems: "center",
                    minWidth: 130,
                    maxWidth: 200,
                  }}
                >
                  <TextField
                    label="SUPPLIERS"
                    select
                    defaultValue="all"
                    size="small"
                    onChange={(e) => {
                      handleChangeSupplierFilter(e);
                    }}
                    sx={{
                      minWidth: 50,
                      width: "100%",
                    }}
                  >
                    <MenuItem key={"DEFAULT"} value="all">
                      ALL
                    </MenuItem>
                    {supplierList.map((row, index) => {
                      return (
                        <MenuItem key={index} value={row.id}>
                          {row.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Paper>
              </Box>
            </Grid>
            <Grid xs={2} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                onClick={handleClickOpen}
                anchorE1={anchorE1}
                endIcon={<DownloadIcon />}
              >
                EXPORT
              </Button>
              <Menu
                style={{ marginRight: "30px" }}
                anchorEl={anchorE1}
                open={openExportOptions}
                onClose={handleClose}
              >
                <MenuItem
                  style={{color: "black",margin: "5px 0 5px 0",fontSize: "12px",width: "100px",textWrap: "pretty",borderRadius: "5px",}}
                  onClick={() => {handleClose();}}
                >
                  <PDFDownloadLink document={<BatchwiseItemStockReportPdf viewData={{ report_detail: itemBatchwiseStockReportList,}}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Item Purchase Summative Report`}>
                    PDF
                  </PDFDownloadLink>
                </MenuItem>    
                <MenuItem
                  style={{color: "black",margin: "5px 0 5px 0",fontSize: "12px",width: "100px",textWrap: "pretty",borderRadius: "5px",}}
                  onClick={() => {handleClose();}}
                >
                  EXCEL
                </MenuItem>  
              </Menu>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: "100vh" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                maxHeight="100vh"
              >
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell style={{ width: 300 }}>Item</StyledTableCell>
                    <StyledTableCell style={{ width: 300 }}>
                      Description
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Current Stock
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      Batch
                    </StyledTableCell>
                    <StyledTableCell>Expiry Date</StyledTableCell>
                    <StyledTableCell>Unit</StyledTableCell>
                    <StyledTableCell>Cost Price</StyledTableCell>
                    <StyledTableCell>Supplier</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {itemBatchwiseStockReportList.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={12}
                        style={{ textAlign: "center" }}
                      >
                        <h3>No Data found</h3>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    itemBatchwiseStockReportList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell>{row.related_product.product_name}</StyledTableCell>
                            <StyledTableCell>{row.related_product.description}</StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>{row.remaining_quantity}</StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>{row.batch}</StyledTableCell>
                            <StyledTableCell>{moment(row.expiry_date).format("L")}</StyledTableCell>
                            <StyledTableCell>{row.related_product_unit.related_business_product_unit.name}</StyledTableCell>
                            <StyledTableCell>{currencyFormatter(parseFloat(Number(row.smallest_unit_unit_cost)*Number(row.remaining_quantity)))}</StyledTableCell>
                            <StyledTableCell>{row.related_product_purchase.related_supplier.name}</StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={itemBatchwiseStockReportList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
