import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import requester from "api/pesabackend";

const BusinessDetailEditForm = (props) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { editData } = props;

  const initialValuesItem = {
    business_name: editData.business_name,
    business_slogan: editData.business_slogan,
    business_location: editData.business_location,
    business_email : editData.business_email ? editData.business_email : "",
  };

  const itemSchema = yup.object().shape({
    business_name: yup.string().required("required"),
    business_slogan: yup.string().required("required"),
    business_location: yup.string().required("required"),
    business_email : yup.string(),
  });

  const saveItem = async (values, onSubmitProps) => {
    const savedItemResponse = await requester.post( `/tenants/business/edit/${editData.id}`, JSON.stringify(values) );

    if (savedItemResponse.status === 201) {
      props.setOpen(false);
      props.setNewBusinessData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem}
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              label="Business Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.business_name}
              name="business_name"
              error={
                Boolean(touched.business_name) && Boolean(errors.business_name)
              }
              helperText={touched.business_name && errors.business_name}
              sx={{ gridColumn: "span 4" }}
            />
            
            <TextField
              label="Slogan"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.business_slogan}
              name="business_slogan"
              error={Boolean(touched.business_slogan) && Boolean(errors.business_slogan)}
              helperText={touched.business_slogan && errors.business_slogan}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              label="Address"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.business_location}
              name="business_location"
              error={Boolean(touched.business_location) && Boolean(errors.business_location)}
              helperText={touched.business_location && errors.business_location}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.business_email }
              name="business_email "
              error={Boolean(touched.business_email ) && Boolean(errors.business_email )}
              helperText={touched.business_email  && errors.business_email }
              sx={{ gridColumn: "span 2" }}
            />
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              size="small"
              type="submit"
              sx={{
                m: "10px 0",
                p: "10px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
                width: "20%",
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default BusinessDetailEditForm;
