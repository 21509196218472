import React from 'react';
  import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
  import { Formik } from "formik";
  import * as yup from "yup";
  import { useSelector } from "react-redux";
  import { requester } from 'api/pesabackend';

  const itemTypeSchema = yup.object().shape({
    category_name: yup.string().required("required"),
    
  });
  
  
  
  const ItemCategoryForm = (props) => {
    

    const pageType = props.formType;
    let {id, editData} = props;
    const userId = useSelector((state) => state.user.userId);
    const business_id = useSelector((state) => state.business_id);
    const business_branch_id = useSelector((state) => state.business_branch_id);
    const token = useSelector((state) => state.token);
    const { palette } = useTheme();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const isNew = pageType === "create";
    const isEdit = pageType === "edit";
    
    const initialEditValuesItemType = {
      category_name: editData.category_name,
    };
    
    
    const initialValuesItemType = {
      category_name: '',
    };
    
  
    const saveItemType = async (values, onSubmitProps) => {
      values.related_business = business_id
      values.related_business_branch = business_branch_id
      values.added_by = userId
  
      const savedTypeResponse = await requester.post(`/inventory/product_categories/add`, JSON.stringify(values));

      if (savedTypeResponse.data.status === 201) {
        onSubmitProps.resetForm();
        props.setIsSuccess(true);
        props.setNewItemTypeData(true);
        props.setOpen(false);
      }
    };
  
    const editItemType = async (values, onSubmitProps) => {
      const savedTypeResponse = await requester.post(`/inventory/product_categories/edit/${id}`, JSON.stringify(values));

      if (savedTypeResponse.data.status === 201) {
        onSubmitProps.resetForm();
        props.setIsSuccess(true);
        props.setNewItemTypeData(true);
        props.setOpen(false);
      }
    };

  
    const handleFormSubmit = async (values, onSubmitProps) => {
      if (isNew) await saveItemType(values, onSubmitProps);
      if (isEdit) await editItemType(values, onSubmitProps);
    };
  
    return (
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={isNew ? initialValuesItemType : initialEditValuesItemType}
        validationSchema={itemTypeSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                label="Item Category Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.category_name}
                name="category_name"
                error={Boolean(touched.category_name) && Boolean(errors.category_name)}
                helperText={touched.category_name && errors.category_name}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
  
            {/* BUTTONS */}
            <Box>
              <Button
                fullWidth
                type="submit"
                sx={{
                  m: "1rem 0",
                  p: "1rem",
                  backgroundColor: palette.primary.main,
                  color: palette.background.alt,
                  "&:hover": { color: palette.primary.main },
                }}
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    );
  };
  
  export default ItemCategoryForm;
  