import { Box, Button, TextField, useMediaQuery, useTheme, Rating, Typography, FormControlLabel, Switch, MenuItem,Card, CardContent } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";
import { useState } from "react";
import moment from "moment";
import { useCustomerList } from "api/hooks/customer";


const labels = {
  0.5: 'Very Disappointing',
  1: 'Disappointing',
  1.5: 'Very Poor',
  2: 'Poor',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const ViewCustomerFeedbackForm = (props) => {
  
  let { viewData } = props;
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");

 

  const itemTypeSchema = yup.object().shape({
    follow_up:yup.string().required("required"),
  });

  const initialValuesItemUnit = {
    follow_up:viewData.follow_up ? viewData.follow_up : "",
  };

  const [value, setValue] = useState(viewData.rating);
  const [hover, setHover] = useState(-1);


  const saveItem = async (values, onSubmitProps) => {
    values.feedback_id = viewData.id;

    const savedUnitResponse = await requester.post(`/customers/feedback/follow_up`, JSON.stringify(values));

    if (savedUnitResponse.status===201){
      props.setOpen(false);
      props.setNewCustomerFeedbackData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await saveItem(values, onSubmitProps);
  };

  


  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItemUnit}
      validationSchema={itemTypeSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <Card sx={{ gridColumn:"span 2" }}>
              <CardContent>
                <Typography variant="h4" sx={{ display: "flex", alignItems: "center", justifyContent:"center" }} color="text.primary">
                  Rating & Feedback
                </Typography>
                <Box sx={{ justifyContent:'center',  margin:"3px" }}>
                  <Rating
                    label="Customer rating"
                    onBlur={handleBlur}
                    disabled
                    size="large" 
                    onChange={(event, newValue) => {
                      handleChange(event);
                      setValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    value={viewData.rating}
                    name="rating"
                    precision={0.5}
                    error={Boolean(touched.rating) && Boolean(errors.rating)}
                    helperText={touched.rating && errors.rating}
                    sx={{ justifyContent:'center', width:"100%",  margin:"3px" }}
                  />
                    {value !== null && (
                        <Typography variant="subtitle2" sx={{ display: "flex", alignItems: "center", justifyContent:"center" }} color="text.primary">{labels[hover !== -1 ? hover : value]}</Typography>
                      )}
                </Box>
                <Typography variant="body1" sx={{ display: "flex", alignItems: "center", justifyContent:"center" }} color="text.primary">
                       " {viewData.feedback} "
                </Typography>
                <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center", justifyContent:"center" }} color="text.primary">
                      {viewData.related_customer ? viewData.related_customer.related_user.first_name+" "+viewData.related_customer.related_user.last_name : null}
                </Typography>
                <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center", justifyContent:"center" }} color="text.primary">
                      {viewData.phone_number}
                </Typography>
                <hr/>
                <Typography variant="overline" sx={{ display: "flex", alignItems: "center", justifyContent:"center" }} color="text.primary">
                        Recorded by: {viewData.created_by ? viewData.created_by.first_name+" "+viewData.created_by.last_name : null}
                  </Typography>
                  <Typography variant="overline" sx={{ display: "flex", alignItems: "center", justifyContent:"center" }} color="text.primary">
                        Captured on on: {moment(viewData.date_added).format("LLL")}
                  </Typography>
              </CardContent>
            </Card>

            {viewData.follow_up ? 
              <Card sx={{ gridColumn:"span 3" }}>
                <CardContent>
                  <Typography variant="h4" sx={{ display: "flex", alignItems: "center", justifyContent:"center" }} color="text.primary">
                    Follow Up
                  </Typography>
                  <Typography variant="body1" sx={{ display: "flex", alignItems: "center", justifyContent:"center", my: "3px" }} color="text.primary">
                        " {viewData.follow_up} "
                  </Typography>
                  <hr/>
                  <Typography variant="overline" sx={{ display: "flex", alignItems: "center", justifyContent:"center" }} color="text.primary">
                        Followed-up by: {viewData.followed_up_by ? viewData.followed_up_by.first_name+" "+viewData.followed_up_by.last_name : null}
                  </Typography>
                  <Typography variant="overline" sx={{ display: "flex", alignItems: "center", justifyContent:"center" }} color="text.primary">
                        Followed-up on: {moment(viewData.follow_up_date).format("LLL")}
                  </Typography>
                </CardContent>
              </Card> 
              : 
              
              <Box sx={{ gridColumn: "span 3" }}>
                
                <TextField
                  label="Add a follow_up here"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  multiline
                  rows={5}
                  value={values.follow_up}
                  name="follow_up"
                  error={Boolean(touched.follow_up) && Boolean(errors.follow_up)}
                  helperText={touched.follow_up && errors.follow_up}
                  sx={{ width: "100%"}}
                />

                {/* BUTTONS */}
                <Button
                  fullWidth
                  type="submit"
                  sx={{
                    m: "1rem 0",
                    p: "1rem",
                    backgroundColor: palette.primary.main,
                    color: palette.background.alt,
                    "&:hover": { color: palette.primary.main },
                  }}
                >
                  Save
                </Button>
              </Box>
            }
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ViewCustomerFeedbackForm;
