import React, {useEffect} from "react";
import { Box, Alert  } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";
import StockTransferCreateTable from "../tables/stockTransferCreateTable";
import StockTransferRecieveTable from "../tables/stockTransferRecieveTable";
import CustomTab from "components/Tabs/Tab";

const StockTransferTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['stockTransferTab']) );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setCurrentTab({ name: "stockTransferTab", tab: newValue })); 
  };
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);

  const tabComponents = [
    {Component: <StockTransferCreateTable />, label: "SEND TO", "index": 0},
    {Component: <StockTransferRecieveTable />, label: "RECEIVE FROM", "index": 1},
  ] 

  React.useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  return (
    <Box>
      {
        isSuccess ? ( 
            <Alert  sx={{ m:"15px" }}variant="filled" severity="success">Operation was successful!</Alert>
        ) : isFailed ? (
            <Alert  sx={{ m:"15px" }}severity="error">Operation was not successful!</Alert>
        ) : null                                             
      }
      <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/>
    </Box> 
  );
};

export default StockTransferTab;