import {Box, Grid, Button, Paper, Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetCustomerBalances } from "api/hooks/accounting";
import { useGetPaymentTypesList } from "api/hooks/billing";
import { useSelector } from "react-redux";
import { useState } from "react";
import FormDialog from "components/modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import DummyTable from "components/Tables/DummyTable";
import ReconciliationTransactionsForm from "../forms/reconcilliationForm";
import moment from "moment";
import requester from "api/pesabackend";
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";

export default function CustomerRecievablesList({ setIsSuccess }) {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { paymentTypesList } = useGetPaymentTypesList(1, business_branch_id);
  const {customerBalances, isLoadingCustomerBalances, errorCustomerBalances, setNewBalanceData} = useGetCustomerBalances(1, business_branch_id);
  const [amount, setAmount] = useState("");
  const [reconcilliationId, setReconcilliationId] = useState("");
  const [paymentTypes, setPaymentTypes] = useState([] );
  const [paymentId, setPaymentId] = useState("");
  const [memo, setMemo] = useState("");
  const [transactionDate, setTransactionDate] = useState(moment().format('YYYY-MM-DD'));
  const [isSubmittingReconcilliationTransaction, setIsSubmittingReconcilliationTransaction] = useState(false);
  const [openReconcilliationRecording, setOpenReconcilliationRecording] = useState(false);
  


  const handleChangePaymentId = (e) => {
    setPaymentId(e.target.value);
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleChangeTransactionDate = (e) => {
    setTransactionDate(e.target.value);
  };

  const handleChangeMemo = (e) => {
    setMemo(e.target.value);
  }

  const handleClickOpenReconcilliationRecording = (reconcilliationId) => {
    setReconcilliationId(reconcilliationId);
    setPaymentTypes(paymentTypesList);
    setOpenReconcilliationRecording(true);
  };

  const handleCloseReconcilliationRecording = () => {
    // setModal("");
    setOpenReconcilliationRecording(false);
  };

  const saveReconcilliationTransaction = async () => {
    setIsSubmittingReconcilliationTransaction(true)
    const submitData = {
      "customer_id": reconcilliationId,
      "payment_type_id": paymentId,
      "amount": amount,
      "memo": memo,
      "date": transactionDate,
    }
    try {
      const response = await requester.post("/accounting/customer/reconciliation", submitData)
      setIsSubmittingReconcilliationTransaction(false);
      setOpenReconcilliationRecording(false);
      setNewBalanceData(true);
    }catch (err){
        setIsSubmittingReconcilliationTransaction(false)
    }
  };



  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ marginBottom: "1rem" }}>Customer Balances List</Typography>
      {/* <Box p="1rem" m="1rem" borderRadius="0.5rem">
        <Grid container spacing={4} className="alignHorizantal">
          <Grid xs={4}>
            <Paper
              sx={{
                my: "10px",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: 170,
                maxWidth: 300,
              }}
            >
              <InputBase
                placeholder="Search Customer Name"
                value={searchContent}
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
              />
              <IconButton onClick={handleClickSearch}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>

          <Grid xs={4}>
            
          </Grid>
          <Grid xs={4} sx={{ textAlign: "right" }}>
            
          </Grid>
        </Grid>
      </Box> */}

      <Box>
        <Paper sx={{ width: "100%" }}>
          {isLoadingCustomerBalances ? (
            <DummyTable />
          ) : (
            <>
              <TableContainer sx={{ maxHeight: "100vh" }}>
                <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell style={{ minWidth: 50 }}>
                        No.
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: 150 }}>
                        Customer Name
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: 50 }}>
                        Outstanding Balance
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: 50 }}>
                        Balance Parity
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ textAlign: "center", minWidth: 150 }}
                      >
                        Actions
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {customerBalances.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell
                          colSpan={12}
                          style={{ textAlign: "center" }}
                        >
                          <h3>No Data found</h3>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      customerBalances
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <StyledTableRow key={row.id}>
                              <StyledTableCell>{index + 1}</StyledTableCell>
                              <StyledTableCell>{row.ledger_name}</StyledTableCell>
                              <StyledTableCell>{row.balance.total}</StyledTableCell>
                              <StyledTableCell>{row.balance.parity}</StyledTableCell>
                              <StyledTableCell
                                style={{ textAlign: "center", minWidth: 150 }}
                              >
                                <Button
                                  value={row.id}
                                  size="small"
                                  onClick={() => navigate("/ledgerTransactions", { state: { id: row.id, name: row.ledger_name,},})}
                                >
                                  View Ledger
                                </Button>
                                <Button
                                  value={row.id}
                                  size="small"
                                  onClick={(e) =>handleClickOpenReconcilliationRecording(row.related_customer)}
                                >
                                  Reconcile
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={customerBalances.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
          <FormDialog
            open={openReconcilliationRecording}
            handleClose={handleCloseReconcilliationRecording}
            enableActions={true}
            title={"Record Reconcilliation"}
            buttonText="Save Reconcilliation"
            handleSubmit={saveReconcilliationTransaction}
            isSubmitting={isSubmittingReconcilliationTransaction}
            isSubmittingText={
              "Recording Reconcilliation, Please wait..."
            }
            textStatus={true}
            text={`Please note that this action is going to create a transaction record`}
            content={
              <ReconciliationTransactionsForm
                handleChangePaymentId={handleChangePaymentId}
                handleChangeAmount={handleChangeAmount}
                handleChangeTransactionDate={handleChangeTransactionDate}
                handleChangeMemo={handleChangeMemo}
                paymentTypes={paymentTypes}
              />
            }
          />
        </Paper>
      </Box>
    </Box>
  );
}
