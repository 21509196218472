import {Box, Button, TextField, useMediaQuery, useTheme,} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";

const DepartmentCreateForm = (props) => {
  const { palette } = useTheme();
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);

  const initialValuesItem = {
    department_name: "",
  };

  const itemSchema = yup.object().shape({
    department_name: yup.string().required("required"),
  });

  const saveItem = async (values, onSubmitProps) => {
    values.related_business = business_id;
    values.related_business_branch = business_branch_id;
    values.added_by = userId;

    const savedItemResponse = await requester.post(`/human_resource/departments`, JSON.stringify(values));

    if (savedItemResponse.data.status === 201) {
      props.setOpen(false);
      props.setNewDepartmentData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem}
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": "span 4"
            }}
          >
            <TextField
              label="Department Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.department_name}
              name="department_name"
              error={Boolean(touched.department_name) && Boolean(errors.department_name)}
              helperText={touched.department_name && errors.department_name}
              sx={{ gridColumn: "span 4" }}
            />
            
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              size="small"
              type="submit"
              sx={{
                m: "10px 0",
                p: "10px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
                // width: "100%",
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default DepartmentCreateForm;
