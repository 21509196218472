import React, {useEffect, useState} from "react";
import {Box, Stack, Radio, RadioGroup,Typography, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Button} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FlexBetween, FlexEnd, FlexRow } from "components/FlexBox";
import { currencyFormatter } from "utilities/currencyFormatter";
import { useFormContextCreateReceipt } from "../forms/formSetups/receiptCreateForm";
import { useFieldArray } from "react-hook-form";

const ReceiptConfirmView = ({amountPaid, total, paymentModes, paymentsTotal, handleChangeAmount, handleChangeMultiplePymentModes, multiplePayments, receiptDate, handleChangePaymentDate}) => {

  const {register, errors, watch, control } = useFormContextCreateReceipt();
  const {append, remove, fields} = useFieldArray({name: 'payment_modes', control});
  

  useEffect(() => {
    const subscription = watch((value) => {
      handleChangeAmount(value.payment_modes)
    })
    return () => subscription.unsubscribe()
  }, [watch])
  

  return (
          <Grid container spacing={4}>
            <Grid xs={12} sm={12}>
                <FlexBetween>
                  <Typography fontSize={16} variant="h4" sx={{marginBottom: 1}}>Amount to be paid - {currencyFormatter(parseFloat(total))}</Typography>
                  <TextField  sx={{ width:"35%" }} label="Date of Payment" name="date_created" type="datetime-local" onChange={handleChangePaymentDate} value={receiptDate}/>
                </FlexBetween>
                <RadioGroup
                  row
                  value={multiplePayments}
                  onChange={handleChangeMultiplePymentModes}
                >
                  <FormControlLabel disabled value={false} control={<Radio />} label="Single Payment Mode" />
                  <FormControlLabel  disabled value={true} control={<Radio />} label="Multiple Payment Modes" />
                </RadioGroup>

                {multiplePayments === false ? (
                  <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "2px", padding: "0.5rem" }}>
                  <Typography>Input Payment</Typography>
                  <form>
                    {fields.map((payment_mode, index) => {
                      return(
                        <FlexBetween key={payment_mode.id} sx={{marginY: 2, marginX: 2}}>
                          <FormControl fullWidth sx={{flex: 1}}>
                            <InputLabel id="demo-simple-select-label">Select a Payment Mode</InputLabel>
                              <Select
                                  labelId="demo-simple-select-label"
                                  id="payment_type"
                                  name="payment_type"
                                  // value={`payment_modes.${index}.payment_type`}
                                  label="Select Payment Type Category"
                                  {...register(`payment_modes.${index}.payment_type`)}
                              >
                                  {paymentModes.map((value, index ) => {
                                      return (
                                          <MenuItem key={index} value={value?.id}>{value.payment_type_name}</MenuItem>
                                      )
                                  })}
                              </Select>
                          </FormControl>

                          <TextField
                            required
                            id="outlined-required"
                            label="Enter Amount Received"
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                            {...register(`payment_modes.${index}.amount_paid`)}
                            sx={{marginLeft: "0.2rem", flex: 1.5}}
                          />

                        </FlexBetween>
                      )
                    })}
                  </form>
                </Box>
                ):(
                  <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "2px", padding: "0.5rem" }}>
                  <Typography>Input Payment Modes</Typography>
                  <form>
                    {fields.map((payment_mode, index) => {
                      return(
                        <FlexBetween key={payment_mode.id} sx={{marginY: 2, marginX: 2}}>
                          <FormControl fullWidth sx={{flex: 1}}>
                            <InputLabel id="demo-simple-select-label">Select a Payment Mode</InputLabel>
                              <Select
                                  labelId="demo-simple-select-label"
                                  id="payment_type"
                                  name="payment_type"
                                  // value={`payment_modes.${index}.payment_type`}
                                  label="Select Payment Type Category"
                                  {...register(`payment_modes.${index}.payment_type`)}
                              >
                                  {paymentModes.map((value, index ) => {
                                      return (
                                          <MenuItem key={index} value={value?.id}>{value.payment_type_name}</MenuItem>
                                      )
                                  })}
                              </Select>
                          </FormControl>

                          <TextField
                            required
                            id="outlined-required"
                            label="Enter Amount Received"
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                            {...register(`payment_modes.${index}.amount_paid`)}
                            sx={{marginLeft: "0.2rem", flex: 1.5}}
                          />

                          <FlexBetween sx={{marginLeft: "0.2rem", flex:0.5}}>
                            <Button onClick={() => {
                              remove(index)
                            }} variant="text">
                              X
                            </Button>
                          </FlexBetween>
                        </FlexBetween>
                      )
                    })}
                    <Button onClick={() => {
                          append({payment_type: paymentModes[0].id, amount_paid: 0})
                      }} variant="contained">
                      Add Payment Mode
                    </Button>
                  </form>
                </Box>
                )}

                <FlexEnd>
                    <Grid>
                        <Typography variant="h5">Amount Tendered - {currencyFormatter(parseFloat(paymentsTotal))}</Typography>
                        <Typography variant="h5">Amount To Be Paid - {currencyFormatter(parseFloat(total))}</Typography>
                        {paymentsTotal && !multiplePayments && (
                          <Typography variant="h5">Balance - {currencyFormatter((parseFloat(paymentsTotal) - parseFloat(total)))}</Typography>
                        )}
                        {paymentsTotal && multiplePayments && (
                          <Typography variant="h5">Balance - {currencyFormatter(0)}</Typography>
                        )}
                    </Grid>
                </FlexEnd>
            </Grid>
          </Grid>
   );
};

export default ReceiptConfirmView;