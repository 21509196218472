import {Box, Button, Paper} from "@mui/material";
import { useRef } from "react";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { StyledTableCell, StyledTableRow } from "components/Tables/TableCellandRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PurchaseOrderPdf from "./purchaseOrderPdf";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";

const PurchaseOrderViewForm = (props) => {

  const user = useSelector((state) => state.user);
  const { viewData } = props;
  const componentRef = useRef();

  const purchase_order_number = viewData.id;
  const detail = viewData.detail;
  const fob= viewData.fob;
  const terms =  viewData.terms
  const ship_via =  viewData.ship_via
  const grand_total =  viewData.grand_total
  const delivery_date =  viewData.delivery_date
  const related_supplier =  viewData.related_supplier
  const products_ordered = viewData.products_ordered
  const requisition_by = viewData.added_by
  const date_created = viewData.date_added


  return (
    <>
      <Paper sx={{ margin: "20px" }} elevation={3}>
        <Box
          sx={{ padding: "20px", margin: "20px", width: "95%" }}
          ref={componentRef}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h1>PURCHASE ORDER</h1>
            <h2># {purchase_order_number}</h2>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Purchase Order From
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Name of Entity
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Address:
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Phone:
              </p>
              <br />

              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Date Created:
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {moment(date_created).format("LL")}
              </p>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Purchase Order To
              </h4>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                {related_supplier.name}
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Address: {related_supplier.address}
              </p>
              <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                Phone: {related_supplier.mobile}
              </p>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Table>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: "lightgrey" }}>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Delivery Date
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    F.O.B
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Terms
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Requisitioned By
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Ship Via
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    {moment(delivery_date).format("LL")}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    {fob}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    {terms}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    {requisition_by}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    {ship_via}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
            <p></p>
            <Table>
              <TableHead>
                <StyledTableRow sx={{ backgroundColor: "lightgrey" }}>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    #
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Description
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Unit
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    Amount
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!products_ordered ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} style={{ textAlign: "center" }}>
                      <h3>No Data found</h3>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  products_ordered.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          style={{
                            padding: "6px 6px",
                            border: "1px solid",
                          }}
                        >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            padding: "6px 6px",
                            border: "1px solid",
                          }}
                        >
                          {row.related_product.product_name}{" "}
                          {row.related_product.description}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            padding: "6px 6px",
                            border: "1px solid",
                          }}
                        >
                          {row.quantity}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            padding: "6px 6px",
                            border: "1px solid",
                          }}
                        >
                          {
                            row.related_product_unit
                              .related_business_product_unit.name
                          }
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            padding: "6px 6px",
                            border: "1px solid",
                          }}
                        >
                          {withoutSymbolFormatter(parseFloat(row.amount))}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>TOTAL</b>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    <b>{currencyFormatter(parseFloat(grand_total))}</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
              {/* <TableFooter>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    TOTAL
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      padding: "6px 6px",
                      border: "1px solid",
                    }}
                  >
                    {grand_total}
                  </StyledTableCell>
                </StyledTableRow>
              </TableFooter> */}
            </Table>
          </Box>
          <br />
          <Box>
            <h4 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              Notes
            </h4>
            <p style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
              {detail}
            </p>
          </Box>
        </Box>
      </Paper>
      {/* <ReactToPrint
        documentTitle={`Purchase Order #${purchase_order_number}`}
        trigger={() => (
          <Button
            size="small"
            variant="contained"
            sx={{
              width: "20%",
              my: "15px",
            }}
          >
            Print
          </Button>
        )}
        content={() => componentRef.current}
      /> */}
      <PDFDownloadLink document={<PurchaseOrderPdf viewData={viewData}  printedBy={user.first_name + ' ' + user.last_name}/>} fileName={`Purchase Order #${purchase_order_number}`}>
        <Button
          size="small"
          variant="contained"
          sx={{
            width: "20%",
            my: "15px",
            mx: "5px",
          }}
        >
          Download
        </Button>
      </PDFDownloadLink>
    </>
  );

}

export default PurchaseOrderViewForm;