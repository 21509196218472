import {Box,Button,TextField,useMediaQuery,MenuItem,Autocomplete} from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { requester } from "api/pesabackend";

const StockTransferDetailForm = (props) => {
    const {index, 
        data,
        handleRemoveProductDetailRow, 
        handleItemAmountChange,
        name, 
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue
        } = props;
    const isNonMobile = useMediaQuery("(min-width:600px)");


    const [batchesForGivenItemList, setBatchesForGivenItemList] =React.useState([]);
    const [isLoadingBatchesForGivenItemList, setLoadingBatchesForGivenItemList] = React.useState(true);
	const [batchesForGivenItemError, setBatchesForGivenItemError] = React.useState(false);
    const [batchQuantity, setBatchQuantity] =React.useState(data.available_quantity);
    const [batchExpiry, setBatchExpiry] = React.useState(data.expiry_date);
    const [batchUnitCost, setBatchUnitCost] = React.useState(0);
    const [batchSmallestUnit, setBatchSmallestUnit] = React.useState(data.smallest_unit);
    const [hasSelectedBatch, setHasSelectedBatch] = React.useState(true);
    const [totalAmount, setTotalAmount] = React.useState(data.amount);
    const [transferQuantity, setTransferQuantity] = React.useState(data.quantity);

    
	const [error, setError] = React.useState(false);
    const [productList, setProductList] = React.useState(data.product_info ? [data.product_info] : []);
    const business_branch_id = useSelector((state) => state.business_branch_id);

    const handleAmountCalculation = (transferQuantity, unitCost)=>{
        const calculatedAmount = Number(transferQuantity)*Number(unitCost);
        setTotalAmount(calculatedAmount.toFixed(2));
        handleItemAmountChange(calculatedAmount.toFixed(2), index);
    }
    
    const handleBatchSelected = (e) =>{
        var specificBatch = batchesForGivenItemList.find(x=>x.batch===e.target.value);
        console.log(specificBatch)
        setBatchExpiry(specificBatch.expiry_date);
        setBatchQuantity(specificBatch.available_stock);
        setBatchUnitCost(specificBatch.smallest_unit_unit_cost);
        setBatchSmallestUnit(specificBatch.smallest_unit_detail.related_business_product_unit.name);
        data.related_product_unit = specificBatch.smallest_unit_detail.id;
        handleAmountCalculation(transferQuantity,specificBatch.smallest_unit_unit_cost);
    }
    
    const fetchBatchesForGivenItemList = async (productId) => {
        try {
            const response = await requester.get(`/inventory/products/batch_list/${productId}`);
            setBatchesForGivenItemList(response.data.data);
            setLoadingBatchesForGivenItemList(false);
        } catch (e) {
            setBatchesForGivenItemError(true);
            setLoadingBatchesForGivenItemList(false);
        }
    };
     
    const fetchItemList = async (search) => {
        try {
            if (search){
                const response = await requester.get(
                  `/inventory/products/list?business_branch=${business_branch_id}&search=${search}&typeFilter=all`
                );
                setProductList(response.data.data);
            } else {
                const response = await requester.get(
                  `/inventory/products/list?business_branch=${business_branch_id}&typeFilter=all`
                );
                setProductList(response.data.data);
            }
            
        } catch (e) {
            setError(true);
        }
    };

    
    
    return (

        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            <Box >
                <p >#{index+1}</p>
            </Box>
            <Box
                display="grid"
                gap="5px"
                p="0.5rem"
                gridTemplateColumns="repeat(19, minmax(0, 1fr))"
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                }}
            >
                <>  
                    <Autocomplete
                        size="small"
                        onOpen={ ()=>{
                            fetchItemList(null);
                        }}
                        name={`${name}.related_product`}
                        onChange={(e, value)=>{
                            if (value) {fetchBatchesForGivenItemList(value.id);};
                            setFieldValue(
                                `${name}.related_product`,
                                value ? value.id : ''
                              );
                            setBatchExpiry(0);
                            setBatchQuantity(0);
                            setBatchSmallestUnit('');
                            setFieldValue(`${name}.batch`, '');
                            data.related_product_unit = ''
                        }}
                        sx={{ gridColumn: "span 4" }}
                        options={productList}
                        defaultValue = {productList[0]}
                        getOptionLabel={(option) => `${option.product_name}  ${option.description}`}
                        isOptionEqualToValue={(option, value)=>
                            option.id === value.id
                        }
                        renderOption={(props, productList) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={productList.id}>
                            {productList.product_name} {productList.description} 
                            </Box>
                        )}
                        renderInput={(params) => 
                            <TextField 
                                {...params} 
                                inputProps={{
                                    ...params.inputProps
                                }}
                                label="Item Name"
                                value={data.related_product ? data.related_product ?? null : ''}
                                error={Boolean( touched?.products_transferred && touched?.products_transferred[index]?.related_product) && Boolean(errors?.products_transferred && errors?.products_transferred[index]?.related_product)}
                                helperText={ touched?.products_transferred && touched?.products_transferred[index]?.related_product && errors?.products_transferred && errors?.products_transferred[index]?.related_product}
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                    fetchItemList(e.currentTarget.value);
                                }}
                            />
                        }
                    />
                    
                    <TextField
                        label="Batch"
                        size="small"
                        select
                        onBlur={handleBlur}
                        onChange={(e,value)=>{
                                    handleChange(e);
                                    handleBatchSelected(e);
                                    setHasSelectedBatch(false);
                                }}
                        value={data.batch}
                        name={`${name}.batch`}
                        error={Boolean( touched?.products_transferred && touched?.products_transferred[index]?.batch) && Boolean(errors?.products_transferred && errors?.products_transferred[index]?.batch)}
                        helperText={ touched?.products_transferred && touched?.products_transferred[index]?.batch && errors?.products_transferred && errors?.products_transferred[index]?.batch}
                        sx={{ gridColumn: "span 2" }}
                    >
                        {batchesForGivenItemList.map((oneRecord, index)=>{
                            return(
                                <MenuItem value= {oneRecord.batch}>{oneRecord.batch}</MenuItem>
                            );
                        })}
                    </TextField>

                    <TextField
                        label="Item Unit"
                        size="small"
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.product_unit=batchSmallestUnit}
                        name={`${name}.product_unit`}
                        error={Boolean( touched?.products_transferred && touched?.products_transferred[index]?.product_unit) && Boolean(errors?.products_transferred && errors?.products_transferred[index]?.product_unit)}
                        helperText={ touched?.products_transferred && touched?.products_transferred[index]?.product_unit && errors?.products_transferred && errors?.products_transferred[index]?.product_unit}
                        sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                        label="Available Quantity"
                        size="small"
                        type="number"
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.available_quantity=batchQuantity}
                        name={`${name}.available_quantity`}
                        error={Boolean( touched?.products_transferred && touched?.products_transferred[index]?.available_quantity) && Boolean(errors?.products_transferred && errors?.products_transferred[index]?.available_quantity)}
                        helperText={ touched?.products_transferred && touched?.products_transferred[index]?.available_quantity && errors?.products_transferred && errors?.products_transferred[index]?.available_quantity}
                        sx={{ gridColumn: "span 2" }}
                    />
                    
                    <TextField
                        label="Expiry Date"
                        size="small"
                        type="date"
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.expiry_date=batchExpiry}
                        name={`${name}.expiry_date`}
                        error={Boolean( touched?.products_transferred && touched?.products_transferred[index]?.expiry_date) && Boolean(errors?.products_transferred && errors?.products_transferred[index]?.expiry_date)}
                        helperText={ touched?.products_transferred && touched?.products_transferred[index]?.expiry_date && errors?.products_transferred && errors?.products_transferred[index]?.expiry_date}
                        sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                        label="Transfer Quantity"
                        size="small"
                        type="number"
                        inputProps={{
                            min: 0,
                            max: batchQuantity
                        }}
                        onBlur={handleBlur}
                        onChange={(e)=>{
                            handleChange(e);
                            setTransferQuantity(e.target.value)
                            handleAmountCalculation(e.target.value, batchUnitCost);
                        }}
                        value={data.quantity}
                        name={`${name}.quantity`}
                        error={Boolean( touched?.products_transferred && touched?.products_transferred[index]?.quantity) && Boolean(errors?.products_transferred && errors?.products_transferred[index]?.quantity)}
                        helperText={ touched?.products_transferred && touched?.products_transferred[index]?.quantity && errors?.products_transferred && errors?.products_transferred[index]?.quantity}
                        sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                        label="Unit Cost"
                        size="small"
                        type="number"
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.unit_cost=batchUnitCost}
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.unit_cost`}
                        error={Boolean( touched?.products_transferred && touched?.products_transferred[index]?.amount) && Boolean(errors?.products_transferred && errors?.products_transferred[index]?.amount)}
                        helperText={ touched?.products_transferred && touched?.products_transferred[index]?.amount && errors?.products_transferred && errors?.products_transferred[index]?.amount}
                        sx={{ gridColumn: "span 2" }}
                    /> 
                    <TextField
                        label="Amount"
                        size="small"
                        type="number"
                        disabled
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.amount=totalAmount}
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.amount`}
                        error={Boolean( touched?.products_transferred && touched?.products_transferred[index]?.amount) && Boolean(errors?.products_transferred && errors?.products_transferred[index]?.amount)}
                        helperText={ touched?.products_transferred && touched?.products_transferred[index]?.amount && errors?.products_transferred && errors?.products_transferred[index]?.amount}
                        sx={{ gridColumn: "span 2" }}
                    />                                      
                    
                    {index > 0 ? 
                        <Button
                            value={index}
                            onClick={() => {
                                handleRemoveProductDetailRow(index);
                            }}                                                     
                            size="small"
                            variant="contained"
                            sx={{ 
                                width: "20%",
                            }}
                        >
                            Remove
                        </Button> : null
                    }
                </>
            </Box>
        </Box>               
                    
    );
  };
  
  export default StockTransferDetailForm;
  