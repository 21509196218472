import React from "react";
import {Box,Breadcrumbs,Link,Typography,Paper,} from "@mui/material";
import { useSelector } from "react-redux";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {FlexBetween,FlexEnd,} from "components/FlexBox";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {StyledTableCell,StyledTableRow,} from "components/Tables/TableCellandRow";
import { useGetProfitAndLoss } from "api/hooks/accounting";
import { currencyOneSignficantDigitFormatter, withoutSymbolOneSignificantDigitFormatter } from "utilities/currencyFormatter"; 

const ProfitAndLossView = () => {
  const navigate = useNavigate();
  const business_branch_id = useSelector((state) => state.business_branch_id);

  const {profitReport, isLoadingProfitReport} = useGetProfitAndLoss(business_branch_id);
  
  const incomeData = profitReport ? profitReport.Sales : {child_ledgers:[], ledger_total: 0};
  const costOfSalesData = profitReport ? profitReport.COGS: {child_ledgers:[], ledger_total: 0};
  const expenseData = profitReport ? profitReport.Expenses : {child_ledgers:[], ledger_total: 0};
  const profitOrLossBeforeTax = profitReport ? profitReport.profit_loss_before_tax : 0;
  const grossProfit = profitReport ? profitReport.gross_profit : 0;
  

  return (
    <Box>
        <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => navigate("/accounting")}
          >
            <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Accounting
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <b>Profit & Loss Statement</b>
          </Typography>
        </Breadcrumbs>
        <Box sx={{display: "flex", width:"100%", justifyContent:"center" }}>
          <Paper sx={{ p: "10px", display: "flex", flexDirection:"column", margin:"10px 0", width:"210mm", }}>
          <Typography variant="h1" sx={{ justifyContent:"flex-start", padding:"6px" }}>Profit and Loss Statement</Typography>
          <FlexEnd> <Typography variant="h3"> </Typography>Period of  </FlexEnd>
          

          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableBody>
                {/* income row */}
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      backgroundColor: "white",
                      padding: "6px 6px",
                      margin:"5px",
                      borderBottom: "2px solid",
                    }}
                    colSpan={12}
                  >
                    <FlexBetween><Typography variant="h4">INCOME</Typography></FlexBetween>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={12}>
                    <Box width={"100%"} padding={"5px"} margin={"5px"}>
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          maxHeight="100vh"
                        >
                          <TableBody>
                            {incomeData.child_ledgers.length === 0 ? (
                              <></>
                            ) : (
                            incomeData.child_ledgers.map((row, index)=>{
                              return(
                                <StyledTableRow>
                                  <StyledTableCell style={{padding: "1px 1px",border: "1px",}} colSpan={12}>
                                    <FlexBetween><Typography variant="h6">{row.name}</Typography> {withoutSymbolOneSignificantDigitFormatter(parseFloat(row.amount))}</FlexBetween>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            }))}
                            <StyledTableRow>
                              <StyledTableCell style={{padding: "1px 1px",borderBottom: "1px solid",}} colSpan={12}>
                                <FlexBetween> <Typography variant="h5 ">TOTAL INCOME</Typography>{currencyOneSignficantDigitFormatter(parseFloat(incomeData.ledger_total))}</FlexBetween>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>

                {/* cost of sales row */}
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      backgroundColor: "white",
                      padding: "6px 6px",
                      margin:"5px",
                      borderBottom: "2px solid",
                    }}
                    colSpan={12}
                  >
                    <FlexBetween> <Typography variant="h4">COST OF SALES</Typography></FlexBetween>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={12}>
                    <Box width={"100%"} padding={"5px"} margin={"5px"}>
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          maxHeight="100vh"
                          size="small"
                        >
                          <TableBody>
                            {costOfSalesData.child_ledgers.length === 0 ? (
                              <></>
                            ) : (
                            costOfSalesData.child_ledgers.map((row, index)=>{
                              return(
                                <StyledTableRow>
                                  <StyledTableCell style={{padding: "1px 0px", margin:"1px" ,border: "1px",}} colSpan={12}>
                                    <FlexBetween><Typography variant="h6">{row.name}</Typography>{withoutSymbolOneSignificantDigitFormatter(parseFloat(row.amount))}</FlexBetween>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            }))}
                            <StyledTableRow>
                              <StyledTableCell style={{padding: "1px 1px",borderBottom: "1px solid",}} colSpan={12}>
                                <FlexBetween> <Typography variant="h5 ">TOTAL COST OF SALES</Typography> {currencyOneSignficantDigitFormatter(parseFloat(costOfSalesData.ledger_total))}</FlexBetween>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>

                {/* GROSS PROFIT row */}
                <StyledTableRow sx={{  marginBottom:"10px" }}>
                  <StyledTableCell style={{padding: "1px 1px",borderBottom:"1px solid", borderTop:"1px solid", marginBottom:"10px"}} colSpan={12}>
                    <FlexBetween> <Typography variant="h5">GROSS PROFIT</Typography> {currencyOneSignficantDigitFormatter(parseFloat(grossProfit))}</FlexBetween>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow></StyledTableRow>

                {/* EXPENSES row */}
                <StyledTableRow>
                  <StyledTableCell
                    style={{
                      backgroundColor: "white",
                      padding: "6px 6px",
                      margin:"5px",
                      borderBottom: "2px solid",
                    }}
                    colSpan={12}
                  >
                    <FlexBetween> <Typography variant="h4">EXPENSES</Typography></FlexBetween>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell colSpan={12}>
                    <Box width={"100%"} padding={"5px"} margin={"5px"}>
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          maxHeight="100vh"
                          size="small"
                        >
                          <TableBody>
                            {expenseData.child_ledgers.length === 0 ? (
                              <></>
                            ) : (
                            expenseData.child_ledgers.map((row, index)=>{
                              return(
                                <StyledTableRow>
                                  <StyledTableCell style={{padding: "1px 0px", margin:"1px" ,border: "1px",}} colSpan={12}>
                                    <FlexBetween><Typography variant="h6">{row.name}</Typography>{withoutSymbolOneSignificantDigitFormatter(parseFloat(row.amount))}</FlexBetween>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            }))}
                            <StyledTableRow>
                              <StyledTableCell style={{padding: "1px 1px",borderBottom: "1px solid",}} colSpan={12}>
                                <FlexBetween> <Typography variant="h5 ">TOTAL EXPENSES</Typography> {currencyOneSignficantDigitFormatter(parseFloat(expenseData.ledger_total))}</FlexBetween>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
                
                {/* NET PROFIT row */}
                <StyledTableRow>
                  <StyledTableCell style={{padding: "1px 1px",borderBottom: "1px solid", borderTop:"1px solid"}} colSpan={12}>
                    <FlexBetween> <Typography variant="h4">PROFIT BEFORE TAXES</Typography> {currencyOneSignficantDigitFormatter(parseFloat(profitOrLossBeforeTax))}</FlexBetween>
                  </StyledTableCell>
                </StyledTableRow>
                

              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        </Box>
      </Box>
  );
};

export default ProfitAndLossView;
