import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';
import { FlexRow } from "./FlexBox";
import { useTheme } from '@mui/material/styles';


export default function NewDialog(props) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<div>
			<Dialog open={props.open} onClose={props.handleClose} fullScreen={fullScreen} maxWidth="md">
				<DialogTitle sx={{ m: 0, p: "1 3", fontSize: 22, fontWeight: 700, borderBottom: "solid 1px #e0e0e0" }}>
					{props.title}{" "}
					<IconButton
						aria-label="close"
						onClick={props.handleClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent sx={{ marginTop: 2, p: 3, minWidth: { xs: "256px", sm: "700px" } }}>
					{props.textStatus ? (
						<FlexRow>
							<WarningIcon sx={{ color:"#ed6b1a" }} />
							<DialogContentText sx={{fontSize:"16px", marginBottom: 2, color:"#ed6b1a" }}>{props.text}</DialogContentText>
						</FlexRow>
					) : (
						<DialogContentText>{props.text}</DialogContentText>
					)}
					{props.content}
				</DialogContent>
				<DialogActions sx={{ m: 0, p: "1rem", borderTop: "solid 1px #e0e0e0" }}>
                    {props.isValidated && (
                        <>
                        {props.isSubmitting ? (
                            <Button loading disabled className="add-button" variant="contained" onClick={props.handleSubmit}>
                                {props.isSubmittingText}
                            </Button>
                        ) : (
                            <>
                                <Button variant="text"  onClick={props.handleClose}>
                                    Cancel
                                </Button>	
                                <Button className="add-button" variant="contained" onClick={props.handleSubmit}>
                                    {props.buttonText}
                                </Button>
                            </>
                        )}
                        </>
                    )}
				</DialogActions>
			</Dialog>
		</div>
	);
}